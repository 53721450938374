<template>
<div v-if="customForm" class="w-100 mt-3 p-3 d-flex flex-column justify-content-center">
    <h5 v-if="title != ''">{{title}}</h5>
                        <select-view  v-model="country" ref="select" :choices="[
                        {value : 'FR', text : 'France'},
                        {value : 'BE', text : 'Belgique'},
                        {value : 'SE', text : 'Suisse'},
                        {value : 'DE', text : 'Allemagne'},
                        {value : 'AT', text : 'Autriche'},
                        ]" :required="true" />
                        <input-view ref="addresse_name" v-model="fullname" pattern="([a-zA-Z]+[ ]*)+" input_title="Nom complet" :required="true" placeholder="" />
                        <input-view ref="addresse_num" v-model="phone" input_type="tel" input_title="Numéro de téléphone" placeholder="" />
                        <input-view ref="addresse_loc" v-model="line1" input_title="Adresse" :required="true" placeholder="" />
                        <input-view ref="addresse_comp" v-model="line2" input_title="Complément d'adresse" placeholder="Apt, étage, nom d'entreprise" />
                        <div class="adresse-content">
                            <input-view ref="addresse_city_num" v-model="zip_code" input_type="number" input_title="Code postal" :required="true" placeholder="" />
                            <div/>
                            <input-view ref="addresse_city" v-model="city" input_title="Ville" pattern="([a-zA-Z]+[ ]*)+" :required="true" placeholder="" />
                        </div>
                        <div v-if="save" class="my-2">
                            <switch-view v-model="saveAdresse" label="Sauvegarder l'adresse" />
                        </div>

                    <span style="font-size:13px">Les champs étoilés sont obligatoires.</span>
                        <button v-if="button" type="submit" :class="{'button-disabled' : !activateButton}" class="address-button">Ajouter l'adresse</button>

</div>
    <form v-else @submit="submitAdresse" action="" ref="form" class="w-100 mt-3 p-3 d-flex flex-column justify-content-center">
                        <h5 v-if="title != ''">{{title}}</h5>
                        <select-view  v-model="country" ref="select" :choices="[
                        {value : 'FR', text : 'France'},
                        {value : 'BE', text : 'Belgique'},
                        {value : 'SE', text : 'Suisse'},
                        {value : 'DE', text : 'Allemagne'},
                        {value : 'AT', text : 'Autriche'},
                        ]" :required="true" />
                        <input-view ref="addresse_name" v-model="fullname" input_title="Nom complet" :required="true" placeholder="" />
                        <input-view ref="addresse_num" v-model="phone" input_type="tel" input_title="Numéro de téléphone" placeholder="" />
                        <input-view ref="addresse_loc" v-model="line1" input_title="Adresse" :required="true" placeholder="" />
                        <input-view ref="addresse_comp" v-model="line2" input_title="Complément d'adresse" placeholder="Apt, étage, nom d'entreprise" />
                        <div class="adresse-content">
                            <input-view ref="addresse_city_num" v-model="zip_code" input_type="number" input_title="Code postal" :required="true" placeholder="" />
                            <div/>
                            <input-view ref="addresse_city" v-model="city" input_title="Ville" :required="true" placeholder="" />
                        </div>
                        <div v-if="save" class="my-2">
                            <switch-view v-model="saveAdresse" label="Sauvegarder l'adresse" />
                        </div>

                    <span style="font-size:13px">Les champs étoilés sont obligatoires.</span>
                        <button v-if="button" type="submit" :class="{'button-disabled' : !activateButton}" class="address-button">Ajouter l'adresse</button>
                        <button ref="hidden_button" v-if="hidden_submit" type="submit" style="display:none"></button>
                    </form>
</template>


<script>
import InputView from '../components/InputView.vue';
import SelectView from '../components/SelectView.vue';
import SwitchView from './SwitchView.vue';
import {Adresse} from '../components/renderless/CheckoutHandler'
export default {

    props : {
        save : {type : Boolean, default : true},
        customForm : {type : Boolean, default : false},
        button : {type : Boolean, default : true},
        hidden_submit : {type : Boolean, default : false},
        title : {type : String, default : ""},
    },
/**
 *  constructor(country="", city="", adresse="", zip_code="",name="", num = "", options = "")
    {
        this.country = country
        this.city = city;
        this.adresse = adresse;
        this.zip_code = zip_code;
        this.name = name ;
        this.num = num;
        this.options = options;
    }
    const ad = new Adresse(this.country, this.city, this.line1, this.zip_code, this.fullname, this.phone, this.line2)
 */
    components : {
        InputView,
        SelectView,
        SwitchView
    },

    computed : {
        activateButton()
        {
            return(this.country && this.fullname && this.line1 && this.zip_code && this.city)
        }
    },

    data()
    {
        return {
            country : "",
            fullname : "",
            line1 : "",
            line2 : "",
            zip_code : "",
            city : "",
            phone : "",
            saveAdresse : false,
        };
    },
    methods: {

        validateCountry()
        {
            this.$refs.select.select.valid = true;
            if(this.country == "")
            {
                this.$refs.select.select.valid = false;
            }
            return this.$refs.select.select.valid;
        },

        submitAdresse(event)
        {
            event.preventDefault();
            if(!this.validateCountry()) {
              const select = this.$refs.select.$el;
              window.scrollTo(select.offsetLeft,select.offsetTop);
              return;
            }
            const ad = new Adresse(this.country, this.city, this.line1, this.zip_code, this.fullname, this.phone, this.line2)
            this.$emit('newAdresse', ad, this.saveAdresse);

        },

        getAdresse()
        {
            return  new Adresse(this.country, this.city, this.line1, this.zip_code, this.fullname, this.phone, this.line2)
        },

        PreventDefault(e)
        {
            e.preventDefault();
        },
        Clear()
        {
            this.country = "";
            this.fullname = "";
            this.line1 = "";
            this.line2 = "";
            this.zip_code = "";
            this.city = "";
            this.phone = "";
        }
    },

}

        </script>


<style scoped>



.adresse-content {
    display : flex;
    width : 100%;
    justify-content: space-between;
}

.adresse-content > label:first-child {
    width : 48%;
}

.adresse-content > label:last-child {
    width:50%
    }

.address-button {
    min-height: 48px;
    border: none;
    background-color: var(--pretty-green);
    color: white;
    border-radius: 4px;
    padding: 0 1rem;
    outline: none!important;
    transition-duration: 0.4s;
}

.button-disabled {
    pointer-events: none;
    background-color : gray;
    color : lightgray;
}
</style>
