<template>
    <div  class="blur-background"  :style="{ 'top' : top + 'px', 'left' : left + 'px'}">
        <div class="card-container" :style="{'width' : width + 'px!important'}">
        <div class="card-title-container">{{title}} <div @click="Clicked" class="quit-button"></div></div>
        <div class="content-container">
            <slot></slot>
            
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name : 'AccountModal',
    props : {
        callBack : Function
    },
    data () {
        return {
            title : "",
            text : "",
            top : 0,
            left : 0,
            width : 'auto',
            container_width : 0,
            container_height : 0,
        }
    },
    methods: { 
        ResizeHandler()
        {
        const body_rect = document.documentElement.getBoundingClientRect();
        this.container_width = body_rect.width;
        this.container_height = body_rect.height; 
        },

        Clicked: function (event)
        {
            if(this.callBack)this.callBack(event, this.index);
            this.$emit('modalquit');
            this.Hide();
            
        },
        Hide : function ()
        {
            const el = this.$el;
            const card = el.children[0];
            this.$el.style.display = 'none';
            card.className= "card-container";
        },
        Reveal(title = "Title", width="auto")
        {
      
        this.title = title;
        const el = this.$el;
        this.width = width;
        const card = el.children[0];
        el.style.display = "flex";
        card.className= "active card-container"
        }
     }

     ,created() {
        window.addEventListener("resize", this.ResizeHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.ResizeHandler);
    },
   
    
}
</script>


<style scoped>

@media(max-width:600px)
{
    .card-container {
        width:100%!important;
    }
}

@media(max-width:450px){
    .card-message {
        font-size: 1rem!important;
    }
    .card-title-container {
        font-size: 1rem!important;

    }

    .card-container {
            padding: 1rem 0.5rem!important;
    }

    .quit-button {
        right: 9px!important;
        top : 8%!important;
        width: 12px!important;
        height: 12px!important;
        
    }
}

@media(max-width:800px)
{
    .blur-background {
        align-items: baseline!important;
        
    }

    .card-container {
        margin-top : 3rem;
    }
}


.card-container {
   
    max-width: 1000px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    display: flex;
    max-width: 95%;
    opacity: 0;
    z-index: 11;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    padding : 1rem 2rem;
    background-color: white; 
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.51);
}

.content-container {
    background-color: white;
    width: 100%;
    color: black;
    justify-content: center;
    min-height: 200px;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.active {
    animation: enter 0.4s forwards;
}

.quit {
    animation: leave 0.4s forwards;
}



.card-message {
    text-align: center;
    font-size: 1.25rem;
    color: black;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: 70%;
}

.blur-background {
    position: fixed;
    display:none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
    align-items: center;
    justify-content : center;
    background-color: rgba(0, 0, 0, 0.5);
}

.card-title-container {
    font-size: 1.75rem;
    width: 100%;
    text-align: center;
    color : black;
    background-color :white;
    border-top-left-radius: 4px;
    font-weight: bold;
    border-top-right-radius: 4px;
    position: relative;
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.quit-button {
   
    background-size: contain;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url('../assets/imgs/croix.svg');
    cursor: pointer;
    margin-left: 1rem;
}



.card-button {
    text-transform: uppercase;
    background-color: var(--amaranth);
    padding: 0.5rem 1rem;
    font-weight: bold;
    border-radius: 10px;
    display: inline;
    margin-bottom: 1rem;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.31);
    cursor: pointer;
    transition-duration: 0.2s;
    user-select: none;
}
.card-button:hover {
    transform: scale(1.1);
}

@keyframes enter {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
    
}
@keyframes leave {

    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
    
}

</style>