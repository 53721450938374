import { render, staticRenderFns } from "./Panier_new.vue?vue&type=template&id=158315aa&scoped=true&"
import script from "./Panier_new.vue?vue&type=script&lang=js&"
export * from "./Panier_new.vue?vue&type=script&lang=js&"
import style0 from "./Panier_new.vue?vue&type=style&index=0&id=158315aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158315aa",
  null
  
)

export default component.exports