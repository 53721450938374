import firebase from 'firebase/app'

import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'


// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyBPfkxrZcY-zC4o6ffAS6MqmXWnBp517ms",
  authDomain: "neon-bloom.firebaseapp.com",
  databaseURL: "https://neon-bloom.firebaseio.com",
  projectId: "neon-bloom",
  storageBucket: "neon-bloom.appspot.com",
  messagingSenderId: "928310551244",
  appId: "1:928310551244:web:4fdb44d37456db17285869",
  measurementId: "G-96T203LW88"
};
const app = firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore();
firebase.firestore().settings({experimentalForceLongPolling : true});
const storage = firebase.storage();
const auth = firebase.auth();
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const fieldValue = firebase.firestore.FieldValue;
const functions = app.functions('europe-west1');
const analytics =  firebase.analytics();
console.log(location.hostname);
if (location.hostname === "localhost") {
  
  //firestore.useEmulator("localhost", 8081);
  //auth.useEmulator('http://localhost:9099/');
  
  functions.useEmulator("localhost", 5011);
}


export {
  firestore,
  functions,
  analytics,
    storage,
    auth,
    fieldValue,
    EmailAuthProvider
}