<template>
    <div class="installation-container">
        <img src="../assets/imgs/instal.jpg" style="max-height:30vw" alt="" srcset="">
        <h4 class="ask-title mt-3 d-flex">Installation <h4 class="ask-title ml-2" style="color:var(--amaranth)">d'enseignes</h4></h4>
        <p class="text-container">
         

Néon Bloom a pris le parti de revaloriser les enseignes traditionnelles grâce à la technologie de la LED flexible (flexled). Nous créons des enseignes plus épurées avec moins de contraintes d’installation et une durée de vie plus importante. <br><br>

Pour des enseignes LED extérieures, une attention toute particulière est portée à l'étanchéification des extrémités du néon LED lors de la fabrication. Nos enseignes lumineuses sont garanties IP64 et IP65. Elles résisteront aux intempéries. <br><br>

Nous pouvons également nous charger de l’installation sur demande.<br><br>

Lors de l’installation, si c’est un remplacement d’une enseigne en néons traditionnels, avec des tubes de verre, par une nouvelle enseigne néon LED, nous effectuons la modification de votre système d’alimentation haute tension par une basse tension 12V. (Consommation électrique d’environ 6 à 7 watts/mètre pour une facture annuelle de 35€ contre près de 170€ avec tubes néons verre).

<br><br>Les avantages : très basse consommation électrique, écologique et économique, basse tension de sécurité 12 ou 24V, résistance aux chocs, longue durée de vie, installation simplifiée, coûts plus faibles…

        </p>

        <div class="pro-item-ask">
                   <h5 class="ask-title">ça vous tente ?</h5>

                   <span style="letter-spacing:-0.03rem">Discutons ensemble de votre projet</span>

                   <button class="article-panier-button" @click="GoTo('/Contact')">Contactez nous</button>
                </div>
    </div>
</template>

<script>
export default {
    
    methods : {
        GoTo(path)
        {
            this.$router.push(path);
        }
    },
    mounted () {
        window.scrollTo(0,0);
    }

}
</script>

<style scoped>
.installation-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top : 5rem;
    align-items: center;
    font-family : 'Montserrat', sans-serif;
}
.pro-item-ask {
    background-color: white;
    width: 100%;
    padding : 2rem 0;
    display: flex;
    flex-direction: column;
    background: var(--white-break);
    align-items: center;
}
@media (max-width:725px)

{
    img {
        object-fit : contain;
        min-height : 0!important;
        max-height : 100vh!important;
        height:auto!important;
    }
}

@media(max-width:500px)
{
    .title {
        font-size: 2rem!important;
    }
}
.article-panier-button {
    color : white;
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color : var(--amaranth);
    border : 1px solid var(--amaranth);
    text-transform : uppercase;
    transition-duration: 0.2s;
    padding : 0.5rem 0.75rem;
    
    margin-top: 1rem;
}

.article-panier-button:hover {
    background-color: white;
    color : var(--amaranth);

}

.ask-title {
    font-family : 'DIN', sans-serif;
    text-transform : uppercase;
    font-size : 1.75rem;
    margin-bottom: 0.25rem;
    white-space: nowrap;
}

.title {
    font-weight : bold;
     font-family: 'DIN', sans-serif;
     text-transform: uppercase;
    margin : 0;
    color : #2a2a2a;
    display: flex;
}

.installation-container > img {
    width: 100%;
    min-height: 400px;
    height: auto;
    object-fit: cover;
    background-color: beige;
}

.text-container {
    width: 100%;
    padding : 1rem;
    max-width : 1200px;
    text-align: justify;
    color : #2a2a2a;
}
</style>