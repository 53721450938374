<template>
    <div class="cgv">
        <div class="cgv-container">
            <span  @click="PreviousGo" class="prec">&#x2039;- Revenir a la page précedente</span>
        <h1>Conditions générales de vente </h1>

          <p class="font-weight-bold d-flex flex-column mb-4 align-items-center">La présente page (ainsi que tout document auquel elle fait référence) définit les conditions générales (« Conditions ») en vertu desquelles vous pouvez (i) acheter des produits (que ce soit en qualité de professionnel ou de consommateur) que nous vendons sur notre site web neonbloom.fr pour les clients en France.

            Veuillez lire attentivement les présentes Conditions avant de passer une commande sur notre site web. Les présentes Conditions établissent les modalités de fourniture des produits, les modalités sous lesquelles chaque partie peut modifier ou résilier le contrat, les modalités en cas de problèmes et autres informations importantes.
          </p>
        <div class="cgv-article" style="text-align: justify">
            <h5>1. Notre contrat avec vous</h5>
        <ul>
          <li>
            Vous passez une Commande chez nous pour les Marchandises lorsque vous saisissez les détails requis sur la page de paiement et que vous cliquez sur le bouton de paiement pour passer votre Commande. Nous vous invitons à vérifier les détails de votre commande et le prix total, et à corriger les erreurs potentielles avant de passer votre Commande.
          </li>
          <li>
            Le Contrat prend effet lorsque nous envoyons un courriel à l’adresse électronique que vous nous avez fournie lors de votre Commande pour confirmer notre acceptation de votre Commande, et pas avant de recevoir le paiement des Marchandises et des frais de livraison. Nous ne vous facturons pas les Marchandises ou frais de livraison si votre Commande n’est pas acceptée.
          </li>
          <li>
            Vous êtes propriétaire des Marchandises dès que nous recevons le paiement complet des Marchandises et des frais de livraison.
          </li>
          <li>
            Nous attribuons un numéro de commande à votre Commande que nous vous communiquons à l’acceptation de votre Commande. Il est préférable de nous transmettre le numéro de commande lorsque vous nous contactez au sujet de votre Commande.
          </li>
        </ul>


        </div>
        <div class="cgv-article" style="text-align: justify">
            <h5>2. Les marchandises générales</h5>
            <p>
              Vous reconnaissez que les images des produits ne sont fournies qu’à titre illustratif et que bien que Neon Bloom essaie de représenter fidèlement nos produits, nous ne pouvons garantir que les couleurs affichées sur votre appareil reproduisent précisément les couleurs représentées sur les images des produits. Si vous souhaitez acheter une couleur spécifique mais que vous n’êtes pas certain de ses spécifications précises, veuillez nous contacter avant de passer une Commande.
              <br><br>
              Nous nous réservons le droit d’apporter des modifications aux Marchandises afin de reproduire les changements apportées aux lois et obligations réglementaires pertinentes.
              <br><br>
              Tous les Droits de propriété intellectuelle concernant les Marchandises sont réservés à Neon Bloom ou ses concédants de licence.

            </p>
        </div>
        <div class="cgv-article" style="text-align: justify">
            <h5>3. Les marchandises sur mesure</h5>
            <ul>
              <li>
                <b>Lorsque vous achetez des Marchandises sur mesure, vous : </b>
                <ul>
                  <li>accordez à Neon Bloom une licence mondiale non-exclusive, irrévocable, pouvant faire l’objet d’une sous-licence et libre de droits de redevance pour utiliser le Contenu généré par l’utilisateur pour produire les Marchandises sur mesure et utiliser les Marchandises sur mesure dans des supports marketing pour des études de cas</li>
                  <li class="mt-2">garantissez à Neon Bloom que :
                    vous êtes le seul propriétaire légal et bénéficiaire effectif, <br> et que vous possédez tous les Droits de propriété intellectuelle relatifs au Contenu généré par l’utilisateur,
                    <br>
                    le Contenu généré par l’utilisateur est votre propre oeuvre originale et n’a pas été recopié entièrement ou substantiellement de toute autre source,
                    <br>
                    la fabrication et la fourniture par Neon Bloom ou ses partenaires, agents ou fournisseurs, des Marchandises sur mesure ne portent pas atteinte aux droits (y compris les Droits de propriété intellectuelle) de tiers.
                  </li>

                  <li class="mt-2">
                    Vous consentez par la présente à indemniser Neon Bloom pour tout Préjudice subi par Neon Bloom en lien direct ou indirect avec votre manquement aux garanties énoncées
                  </li>
                  <li class="mt-2">
                    Neon Bloom a le droit de rejeter toute commande pour des Marchandises sur mesure si, à la seule discrétion de Neon Bloom, la Commande viole les Conditions générales d’utilisation (voir la Partie B des présentes Conditions) de Neon Bloom ou toute autre partie des présentes Conditions.
                  </li>
                </ul>
              </li>
              <li>

              </li>
            </ul>
        </div>
        <div class="cgv-article" style="text-align: justify">
            <h5>4. Prix et paiement</h5>
            <ul>
              <li>Vous devez payer le Prix complet des Marchandises et des frais de livraison au moment de la Commande.</li>
              <li class="mt-2">
                Il est possible que certaines Marchandises que nous vendons n’affichent pas le Prix correct en raison d’une erreur logicielle ou administrative. Nous vérifions normalement le Prix avant d’accepter votre Commande. Si le Prix correct des Marchandises est inférieur au Prix affiché, nous vous facturerons le montant le plus faible. Si le Prix correct des Marchandises est supérieur au Prix affiché, nous vous contacterons et vous pourrez alors soit payer le Prix correct, soit annuler la Commande et nous renvoyer les Marchandises.

              </li>
              <li class="mt-2">
                Si vous êtes exempt de taxe, vous devez contacter Neon Bloom avant de passer une Commande pour les Marchandises. Neon Bloom n’est pas en mesure de rembourser les taxes applicables une fois que la Commande a été traitée.
              </li>
            </ul>
        </div>
        <div class="cgv-article" style="text-align: justify">
            <h5>5. Livraison</h5>
            <ul>
              <li>La livraison est réputée effectuée lorsque les Marchandises sont livrées à l’adresse que vous avez indiquée dans votre Commande ou, en cas d’échec de la livraison à cette adresse, au dépôt désigné par le fournisseur du service de livraison.</li>
              <li class="mt-2">
                Vous êtes responsable des Marchandises à partir du moment où elles sont livrées à l’adresse que vous avez désignée dans la Commande ou au dépôt désigné. Vous êtes susceptible d’être redevable de frais de livraison supplémentaires si vous ne retirez pas les Marchandises au dépôt, ou si vous ne prévoyez pas une nouvelle livraison avec le dépôt.
              </li>
              <li class="mt-2">
                Les délais de livraison estimés pour une livraison standard sont de 3-4 semaines à partir de la date de votre commande. Si vous avez opté pour la livraison express, nous nous efforcerons de livrer les Marchandises dans les 10-15 jours suivant la date de validation de votre commande.
              </li>

              <li class="mt-2">
                Toutes les dates de livraison des Marchandises indiquées dans les présentes Conditions ne sont qu’approximatives et le délai de livraison n’est pas une obligation essentielle du contrat. Nous vous informerons si la livraison est retardée en raison d’un cas de Force majeure. Neon Bloom ne peut être tenue responsable des retards de livraison qui échappent à son contrôle, notamment les délais postaux.
              </li>

              <li class="mt-2">
                Si vous êtes un consommateur résidant en France, les clauses 4.1, 4.2 et 4.4 ne vous sont pas applicables. La livraison est réputée effectuée et vous êtes responsable des Marchandises à partir du moment où vous, ou un tiers que vous avez désigné, prend physiquement possession des Marchandises. Conformément à l’article L.216-2 du Code de la consommation français, si la livraison n’est pas effectuée dans le délai stipulé au moment de la passation de votre Commande, vous pouvez résoudre le contrat par lettre recommandée avec demande d’avis de réception ou par écrit sur un autre support durable si, après nous avoir enjoints, selon les mêmes modalités, d’effectuer la livraison dans un délai supplémentaire raisonnable, nous ne nous sommes pas exécutés dans ce délai. Toutes les sommes versées vous sont alors remboursées.
              </li>
            </ul>
        </div>
        <div class="cgv-article" style="text-align: justify">
            <h5>6. Garantie des Marchandises</h5>
            <ul>
              <li>
                Neon Bloom veille à ce que les Marchandises soient exemptes de tout vice de conception, matériel et de fabrication et cela pour une durée de :
                <br>
                12 mois pour les Marchandises dont l’utilisation est prévue en intérieur et qui ont été utilisées en intérieur ; et
                6 mois pour les Marchandises dont l’utilisation est prévue en extérieur et qui ont été utilisées en extérieur, après la date réputée de livraison
              </li>

              <li class="mt-2">
                Vous ne pouvez bénéficier de la garantie énoncée à la clause 6 en ce qui concerne :
                <ul>
                  <li class="mt-2">
                    tout vice, défaut ou endommagement des Marchandises ayant été causé (directement ou indirectement) par :
                    <ul>
                      <li class="mt-2">
                        tout usage inadapté ou impropre (tel qu’il est déterminé par Neon Bloom, à sa seule discrétion) des Marchandises (tel qu’un impact, un choc, une fragmentation, etc.), manque d’entretien ou tout autre acte non conforme à nos instructions ;
                      </li>
                      <li>tout vol, perte, accident, incendie ou autre désastre naturel ;</li>
                      <li>toute manipulation, négligence ou endommagement délibéré ;</li>
                      <li>toute réparation, modification ou ajout apporté aux Marchandises sans notre concours ;</li>
                      <li>toute usure et vieillissement normal.</li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>Si nous violons la garantie énoncée à la clause 6.1, la seule responsabilité de Neon Bloom et votre seule réparation est le remplacement des Marchandises sans frais supplémentaires à votre charge. Pour tout vice de conception, matériel et de fabrication après (i) 12 mois concernant des Marchandises dont l’utilisation est prévue en extérieur et qui ont été utilisées en extérieur ou (ii) 6 mois concernant des Marchandises dont l’utilisation est prévue en extérieur et qui ont été utilisées en extérieur, à partir de la date réputée de livraison, vous n’avez droit à aucune réparation ou retour.</li>
              <li>
                Si vous êtes un consommateur résidant en France, Neon Bloom garantit que les Marchandises sont conformes au Contrat conformément aux articles L217-4 et suivants du Code de la consommation français et que les Marchandises sont exemptes de défauts cachés conformément aux articles 1641 et suivants du Code civil français.
              </li>
            </ul>

          <p style="text-align: justify" class="mt-3">
            Si les Marchandises que vous avez achetées ne sont pas conformes au Contrat, vous avez un délai de deux ans à partir de la livraison desdites Marchandises pour mettre en œuvre la garantie de conformité en vertu du droit de la consommation français et cela sans avoir à démontrer l’existence d’une non-conformité.
            Vous pouvez choisir entre la réparation ou le remplacement des Marchandises, sauf si l’une de ces options engendre un coût manifestement supérieur à l’autre, auquel cas nous sommes susceptible de procéder selon l’option la moins onéreuse en vertu de l’article L.217-9 du Code de la consommation français.
            Cette garantie légale de conformité s’applique quelles que soient la ou les garanties commerciales qui vous ont été octroyées.
            De plus, vous bénéficiez également d’une garantie contre les vices cachés des Marchandises au sens de l’article 1641 du Code civil français. Dans le cadre de cette garantie, vous pouvez choisir entre l’annulation de la vente ou la réduction du prix de vente conformément à l’article 1644 du Code civil français.

          </p>
        </div>
        <div class="cgv-article" style="text-align: justify">
            <h5>7. Vos droits de retourner les Marchandises ou d’obtenir un remboursement</h5>
            <ul>
              <li>Le droit de changer d’avis : le droit de rétraction. En tant que consommateur, vous avez un droit légal de vous rétracter pour la majorité des articles achetés en ligne dans les 14 jours et de retourner les produits pour obtenir un remboursement. Vous disposez donc de 14 jours à compter de la réception des Marchandises conformément à la clause 4.1 des présentes Conditions pour changer d’avis et nous retourner les Marchandises pour obtenir un remboursement.</li>
              <li>
                Le droit de rétraction de la clause 6.1 ne s’applique pas :
                <ul>
                  <li>si vous agissez à des fins qui entrent dans le cadre de votre activité professionnelle et que vous ne procédez pas à l’achat des Marchandises en tant que consommateur pour une utilisation domestique privée ; et
                  </li>
                  <li>
                    en ce qui concerne les Marchandises sur mesure.
                  </li>
                </ul>
              </li>
              <li>
                Vous disposez également du droit de résilier le Contrat (et de retourner les Marchandises pour obtenir un remboursement le cas échéant) :
                <ul>
                  <li>
                    si les Marchandises que vous avez achetées sont endommagées à la livraison ;
                  </li>
                  <li>
                    si nous vous avons informé d’une erreur dans le Prix des Marchandises que vous avez commandé et que vous ne souhaitez pas procéder à la commande.
                  </li>
                </ul>
              </li>

            </ul>

    </div>

      <div class="cgv-article" style="text-align: justify">
        <h5>8. Remboursement</h5>
        <ul>
          <li>
            Si vous avez le droit de résilier le Contrat en vertu de la clause 6, vous devez :
  <ul>
    <li>
      nous en notifier par courrier électronique (les coordonnées sont précisées à la clause 13). Veuillez fournir votre nom, adresse, numéro de commande et, le cas échéant, numéro de téléphone ; si vous êtes un consommateur résidant dans l’Union européenne, vous pouvez utiliser le bordereau de rétractation joint, mais ce n’est pas obligatoire. Pour respecter le délai de rétractation, il vous suffit de nous notifier avant l’expiration du délai de rétraction.

    </li>
    <li>
      retourner les Marchandises dans les 14 jours suivants la notification, en les adressant à notre entrepôt (veuillez contacter un membre de l’équipe pour connaître l’adresse). Le délai est respecté si vous renvoyez les Marchandises avant l’expiration du délai de 14 jours ; et

    </li>
    <li>
      payer les frais de retour des Marchandises, sauf si les Marchandises étaient endommagées à la livraison.
    </li>
    <li>
      Sous réserve de la clause 7.1, si vous avez le droit de résilier le contrat en vertu de la clause 6, nous vous rembourserons le prix que vous avez payé pour les Marchandises (et les frais de livraison dans le cas où les Marchandises étaient endommagées à la livraison ou si vous êtes un consommateur résidant dans l’Union européenne, à l’exception des coûts supplémentaires engendrés si vous avez choisi une méthode de livraison autre que la méthode de livraison standard la moins onéreuse que nous proposons) dans les meilleurs délais et, en tout état de cause, pas plus tard que 14 jours suivant la réception de votre notification. Nous procéderons au remboursement en utilisant le même moyen de paiement que celui que vous avez utilisé pour passer la Commande, sauf indication contraire expresse de votre part ; en tout état de cause, vous ne serez redevable d’aucun frais relatif à ce remboursement.

    </li>
    <li>
      Nous sommes susceptibles de réduire le remboursement énoncé à la clause 7.2 (tout ou en partie et à l’exclusion des frais de livraison) pour correspondre à toute réduction de la valeur des Marchandises, si cette réduction de valeur est due à votre manipulation des Marchandises autre que ce qui est nécessaire pour établir la nature, les caractéristiques et le fonctionnement des Marchandises. Si nous vous remboursons le prix avant d’avoir pu inspecter les Marchandises et que nous découvrons ultérieurement que vous les avez manipulées de manière inacceptable, vous devez nous verser un montant approprié.

    </li>
    <li>
      Le remboursement maximum des frais de livraison est à hauteur des frais de livraison engendrés par la méthode de livraison la moins onéreuse que nous proposons.

    </li>
  </ul>
          </li>
        </ul>
      </div>


      <div class="cgv-article" style="text-align: justify" >
      <h5>9. Nos droits de mettre fin au Contrat</h5>
        <ul>
          <li>
            Nous pouvons mettre fin au Contrat à tout moment en vous écrivant dans les cas suivants :
            <ul>
              <li>
                en cas de Force majeure ;
              </li>
              <li>
                en cas de violation des garanties énoncées à la clause 2.5.2 ; ou
              </li>
              <li>
                si vous ne nous fournissez pas, dans un délai raisonnable, les informations nécessaires pour livrer les Marchandises (par exemple, une adresse de livraison correcte).
              </li>
              <li>
                Si nous mettons fin au Contrat dans l’une des situations énoncées à la clause 1 (sauf dans le cas de la clause 8.1.2), nous vous rembourserons toutes les sommes que vous avez versées en avance pour des Marchandises que nous ne vous avons pas fournies.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="cgv-article" style="text-align: justify">
      <h5>10. Notre responsabilité</h5>
        <ul>
          <li>
            Si nous manquons à nos obligations en vertu des présentes Conditions, nous pouvons être tenus responsables des préjudices ou dommages que vous subissez qui sont une conséquence prévisible de notre manquement contractuel ou de notre incapacité à faire preuve d’une diligence et de compétences raisonnables, mais nous ne pouvons être tenus responsables des préjudices ou dommages qui ne sont pas prévisibles. Les préjudices ou dommages sont prévisibles soit si leur apparition est évidente, soit si, au moment de la conclusion du Contrat, les deux parties savaient que leur apparition était possible, par exemple, si vous en avez discuté avec nous pendant le processus de vente.

          </li>
          <li>
            Dans les limites permises par la loi, Neon Bloom ne peut être tenue responsable de :
            <ul>
              <li>
                tout préjudice commercial, tel que la perte de bénéfices, de revenus, de recettes, d’économies escomptées, d’activités commerciales, de contrats, de fonds de commerce ou de toutes autres opportunités commerciales ;

              </li>
              <li>
                tout préjudice ou corruption de toute donnée, base de données ou logiciel ; et

              </li>
              <li>
                tout préjudice ou dommage spécial, indirect ou de nature secondaire.

              </li>
            </ul>
          </li>

          <li>
            Neon Bloom ne limite ni n’exclut en rien sa responsabilité envers vous dans les cas où la loi ne le permettrait pas. Cela concerne notamment notre responsabilité en cas de mort ou de dommage corporel causés par notre négligence ou la négligence de nos employés, agents ou sous-traitants et en cas de fraude ou de fausses représentations frauduleuses. Dans les limites permises par la loi, et sauf disposition contraire des présentes Conditions, nous excluons toutes conditions, garanties, représentations ou autres modalités susceptibles de s’appliquer à votre utilisation des Marchandises, qu’elles soient expresses ou implicites. Rien dans cette section ou dans ces modalités n’est susceptible d’affecter vos droits légaux en tant que consommateur.

          </li>
        </ul>
      </div>

      <div class="cgv-article" style="text-align: justify">
        <h5>11. La directive européenne relative aux déchets d’équipements électriques et électroniques (« Directive DEEE »)</h5>
        <ul>
          <li>
            La directive DEEE impose aux entreprises de prendre un rôle actif dans le traitement des déchets d’équipements électriques et électroniques (« DEEE») Les distributeurs, y compris les détaillants, ont l’obligation de fournir un système permettant à tous les clients achetant de nouveaux équipements électriques de recycler gratuitement leurs anciens articles.
          </li>
          <li>
            Si vous êtes un consommateur et que vous achetez des Marchandises que nous fournissons à un ménage, vous avoir le droit de retourner gratuitement tout DEEE ménager à Neon Bloom dans les 28 jours suivant l’achat, à condition que :
            <ul>
              <li>
                le DEEE soit de type équivalent ; et
              </li>
              <li>
                qu’il ait rempli les mêmes fonctions que les Marchandises.
              </li>
            </ul>
          </li>
          <li>
            Une autre possibilité est de déposer votre DEEE dans des installations de recyclage existant dans votre entourage qui sont adaptées pour l’élimination de ces déchets.
          </li>
          <li>
            Pour éviter toute ambiguïté, la clause 2 s’applique uniquement aux DEEE provenant de ménages. Si vous êtes un client professionnel, vous reconnaissez être responsable de la collecte, la valorisation et l’élimination de tous les DEEE non-ménagers.
          </li>
          <li>
            De nombreux articles électriques peuvent être réparés ou recyclés, ce qui permet de préserver les ressources naturelles et l’environnement. Si vous ne les recyclez pas, les équipements électroniques finissent dans des décharges où les substances dangereuses s’écoulent et contaminent la terre et l’eau ; ce qui nuit à la faune et à la santé humaine. Pour vous rappeler que les anciens équipements électronique peuvent être recyclés, les équipements électriques sont désormais marqués du symbole d’une poubelle sur roues barrée d’une croix. Veuillez ne pas jeter d’équipement électrique (y compris ceux marqués du symbole d’une poubelle sur roues barrée d’une croix) dans votre poubelle mais recyclez-les à la place.
          </li>
          <li>
            Si vous souhaitez discuter davantage de cette problématique ou obtenir plus d’informations, veuillez nous contacter en utilisant les coordonnées fournies à la clause 1 ci-dessous.
          </li>

        </ul>

    </div>

      <div class="cgv-article" style="text-align: justify">
        <h5>12. La manière dont nous sommes susceptibles d’utiliser vos données personnelles</h5>
        <ul>
          <li>
            Nous traitons les données personnelles vous concernant conformément aux obligations qui nous incombent au titre des lois de protection des données applicables, y compris le RGPD, le « UK GDPR » (tel qu’il est défini à la section 3(10) de la loi britannique sur la protection des données de 2018) et notre politique de confidentialité
          </li>
        </ul>

      </div> <div class="cgv-article" style="text-align: justify">
        <h5>13. Conservation du Contrat</h5>
      <ul>
        <li>
          Conformément à l’article L213-1 du Code de consommation français, Neon Bloom conservera et archivera, pendant 10 ans, les Contrats conclus avec vous portant sur une somme égale ou supérieure à 120 EUR et vous en garantira l’accès à tout moment.

        </li>
        <li>
          Vous pouvez exercer votre droit d’accès en nous contactant (voir la clause 12 ci-dessous).
        </li>
      </ul>

      </div> <div class="cgv-article" style="text-align: justify">
        <h5>14. Contact</h5>
      <ul>
        <li>
          Si vous avez des questions ou des préoccupations concernant les présentes Conditions, vous pouvez nous contacter par courrier électronique à <b>neonbloom.paris@gmail.com</b> ou aux adresses électroniques précisées sur notre site web.
        </li>
        <li>
          Si nous devons vous contacter, nous le ferons par courrier électronique à l’adresse électronique que vous avez fournie dans votre Commande ou aux coordonnées que vous avez fournies sur notre site web.
        </li>
      </ul>

      </div>

      <h1 class="my-5">Conditions générales d'utilisation</h1>
      <div class="cgv-article" style="text-align: justify">
        <h5>15. Utilisation du Site web</h5>
        <ul>
          <li>
            Vous n’êtes pas autorisé à utiliser le Site web aux fins de :
            <ul>
              <li>
                transmettre tout contenu, information ou tout autre document qui soit, ou considéré comme tel par Neon Bloom à sa seule discrétion, illégal, nuisible, menaçant, abusif, constitutif de harcèlement, diffamatoire, vulgaire, injurieux, obscène, pornographique, haineux ou menaçant envers tout groupe défini par référence à la race, la religion, le sexe, l’origine nationale ou l’orientation sexuelle, obscène , lascif, violent, constitutif de harcèlement ou autrement répréhensible, y compris, mais sans s’y limiter, les expressions d’intolérance, de préjugés, de racisme, de haine ou d'obscénités ;
              </li>
              <li>
                introduire de virus, de vers, de chevaux de Troie et/ou tout code nuisible sur le Site web ;
              </li>
              <li>
                violer toute législation ou réglementation locale, étatique, nationale ou internationale.
              </li>
            </ul>
          </li>
          <li>
            Vous vous engagez à ne pas (et à ne pas tenter ni permettre à un tiers de) : procéder à une ingénierie inverse, décompiler, désassembler, traduire, trouver le code source, interférer, louer, vendre ou louer à bail le Site web, toute partie du Site web ou l’accès au Site web.
          </li>
        </ul>
      </div>

          <div class="cgv-article" style="text-align: justify">
        <h5>16. Liens et contenu de tiers</h5>
            <ul>
              <li>
                Le Site web est susceptible de contenir des liens et références à des sites web, produits, services ou tout autre support ou contenu de tiers (« Contenu de tiers»)
              </li>
              <li>
                En accédant et/ou en utilisant le Site web, vous acceptez que Neon Bloom ne soit pas tenu responsable de tout Contenu de tiers ou des actions de ceux qui fournissent ou utilisent tout Contenu de tiers. Neon Bloom n’a pas de contrôle sur le Contenu de tiers. Tout lien vers du Contenu de tiers ne signifie pas que Neon Bloom fait la promotion, approuve ou accepte une quelconque responsabilité pour le Contenu de tiers ou son fournisseur, ou vice versa.
              </li>
              <li>
                Neon Bloom ne surveille, ni ne vérifie, ni ne censure, ni ne modifie le Contenu de tiers.
              </li>
              <li>
                Lorsque vous accédez ou utilisez du Contenu de tiers, vous acceptez les risques qui en découlent. Neon Bloom vous encourage à faire attention lorsque vous quittez le Site web de lire les conditions générales et la politique de confidentialité applicables à tout Contenu de tiers auquel vous accédez ou que vous utilisez.
              </li>
            </ul>

      </div><div class="cgv-article" style="text-align: justify">
        <h5>17. Disponibilité du Site web et garanties</h5>
          <ul>
            <li>
              Toute installation, outil, service ou information en ligne que Neon Bloom rend disponible par l’intermédiaire du Site Web (le « Service») est fourni tel quel et selon la disponibilité.  Nous ne garantissons pas que le Service soit exempt de défaut et/ou d’erreur.
            </li>
            <li>
              Dans les limites prévues par la loi, nous ne fournissons aucun garantie, expresse ou implicite, concernant l’adéquation à un usage particulier, l’exactitude des informations, la compatibilité et une qualité satisfaisante des Services. Neon Bloom n’a pas d’obligation de mettre à jour les informations sur le Site web.
            </li>
            <li>
              Bien que Neon Bloom prenne des mesures raisonnables pour s’assurer que le Site web est sans danger et exempt d’erreurs, de virus et d’autres logiciels malveillants, nous ne fournissons aucun garantie à ce sujet et il est de votre responsabilité de vous assurer de votre propre sécurité, de celle de vos informations personnelles et de celle de vos ordinateurs.
            </li>
            <li>
              Neon Bloom n’accepte pas de responsabilité pour une quelconque défaillance ou non-disponibilité du Site web.
            </li>
            <li>
              Neon Bloom se réserve le droit de modifier, suspendre ou de mettre hors service tout ou partie des Services. Les présentes Conditions régissent tous les Services modifiés sauf indication contraire expresse.
            </li>
          </ul>

      </div><div class="cgv-article" style="text-align: justify">
        <h5>18. Loi applicable aux présentes Conditions</h5>
          <p>
            Les présentes Conditions (et toute réclamation ou litige non-contractuel découlant de celles-ci ou en lien avec celles-ci) sont régies par la loi anglaise, sauf dans les cas où la loi de votre pays de résidence impose un niveau supérieur de protection qui ne peut pas être dérogé conventionnellement, auquel cas cette loi-là s’applique.
            <br><br>
            Vous acceptez que les tribunaux anglais aient une compétence non-exclusive pour connaître des différends nés ou à naître relatifs aux présentes Conditions et au Contrat. Si vous êtes un consommateur résidant dans l’Union européenne, les tribunaux de votre pays de résidence peuvent être compétents pour connaître des différents à naître relatifs à ce Contrat.
            <br><br>
            Dernière mise à jour :                 	2021
            <br><br>
            <b>PIÈCES JOINTES : BORDEREAU DE RÉTRACTATION</b><br>
            À Neon Bloom <b>neonbloom.paris@gmail.com</b>:<br>

            Je vous notifie par la présente ma rétractation du contrat portant sur la vente des biens ci-dessous :<br>
            Commandé le :<br>
            Reçu le :<br>
            Nom :<br>
            Adresse :<br>
            Date :<br>
            Signature

          </p>

      </div>

      </div>
    </div>
</template>
<script>
export default {

    metaInfo : {
        title : 'CGV'
    },

    data()
    {
        return {
            previous : null
        }
    },
  
    methods: {
        PreviousGo()
        {
            this.$router.push(this.previous.path);
        }
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.previous = from;
        
        // access to component instance via `vm`
      })
    }
}
</script>
<style scoped>
.cgv {
    display: flex;
    align-items: center;
  flex-direction: column;
    justify-content: center;
    width: 100%;
  
}

li {
  margin-top: 0.5rem ;
}
.prec {
    margin-bottom: 1rem;
    color: #0d6efd;
    cursor: pointer;
}

.prec:hover {
    text-decoration : underline;
}

.cgv-container {
    width : 100%;
    max-width: 1000px;
    font-family: 'Montserrat', sans-serif;
    padding-top : 8rem;
    display: flex;
    flex-direction: column;
    align-items : flex-start;
    justify-content : flex-start;
}

.cgv-article {
    width : 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.cgv-article  > h5 {
    font-weight: bold;
}

.cgv-article  > p {
    text-align: left;
    font-size : 16px;
}

.cgv-container > h1 {
    font-weight: bold;
    margin-bottom : 2rem;
}

.cgv-container > p {
    text-align : left;
}

</style>