import {Neon, Options} from '../renderless/NeonStuff'
import {History, PropertyType} from '../renderless/History'
import {Calculateur} from '../renderless/Calculateur'
import {Background, BackgroundColor} from  '../ChooseBackground'
export class NeonHandler {
    constructor(storageHandler)
    {
        this.calculateur = new Calculateur(this.SetDefaultNeon.bind(this));
        this.history = new History(this);
        this.neons = [];
        this.index = 0;
        this.neon = null;
        this.lastNeon = null;
        this.defaultNeon = null;
        this.backgrounds = [new BackgroundColor()];
        this.current_background = new Background()
        this.storageHandler = storageHandler;
    }

/*neon_id, dimension_id, font_id,font, font_ratio, support_id, text, text_aligment, color, support_color, unit_price */

    SetDefaultNeon(font_id,text, color)
    {
        this.defaultNeon = new Neon(0,0, font_id,this.GetPoliceFromId(font_id), this.GetPoliceRatioFromId(font_id), -1, text, "center", color.hex, "transparent", 0);
    
        if(this.CheckForSavedNeon()){this.calculateur.defaultNeonLoaded = true;return;}
        this.AddDefaultNeon();
        this.calculateur.defaultNeonLoaded = true;
    }

    UpdateDisplay(neon)
    {
        neon.color_display = this.calculateur.GetColorDisplay(neon.color);
        neon.support_display = this.calculateur.GetSupportDisplay(neon.support_id);
        neon.font_display = this.calculateur.GetFontDisplay(neon.font_id);
        neon.support_color_display = this.calculateur.GetSupportColorDisplay(neon.support_color_id);
    }

    SetBackground(index)
    {
        this.current_background = this.backgrounds[index];
    }

    CheckNeon()
    {
        if(this.neon.text == "")return false;
        return true;
    }

    CheckForSavedNeon()
    {
        let neons = this.storageHandler.GetNeonsJSON();

        if(neons.length == 0)return false;
        for(let i = 0; i < neons.length; i++)
        {
            this.AddNeonFromJSON(neons[i]);
        }
        return true;
        
    }

    SetUnitPrice(neon_id)
    {
        this.neons[neon_id].unit_price = this.calculateur.UpdatePrice(this.neons[neon_id])
    }

    GetTotalPrice()
    {
        let p = 0;
        for(let n in this.neons)
        {
            p += n.unit_price;
        }
        return p.toFixed(2);
        
    }

    SetColor(color)
    {
   
        this.Save(PropertyType.color, this.neon.color);
        this.neon.color = color;
        this.neon.color_display = this.calculateur.GetColorDisplay(this.neon.color);
        this.SetUnitPrice(this.neon.neon_id)
        this.SaveToSession();
    }
    SetAlignment(aligment)
    {
        this.neon.text_aligment = aligment;
        this.SaveToSession();
    }

    GetPoliceFromId(font_id)
    {
        return this.calculateur.GetPoliceFromId(font_id);
    }

    GetPoliceRatioFromId(font_id)
    {
        return this.calculateur.GetPoliceRatioFromId(font_id);
    }
    GetPoliceHratioFromId(font_id)
    {
        return this.calculateur.GetPoliceHratioFromId(font_id);
    }

    GetDisplayFontSize(index)
  {
      
    if(this.calculateur.policeRatios[index])return this.calculateur.policeRatios[index];
    
    let fsize = 1.25;
    let ratio = this.GetPoliceRatioFromId(index);
    fsize = Math.min(2.5, 20*ratio/16);
    this.calculateur.policeRatios[index] = fsize + 'rem';
    return fsize + 'rem';
  }

    GetDimensionFromId(dimension_id,font_id)
    {
        return parseInt(this.calculateur.polices[font_id].props.sizes[dimension_id].cm);
    }

    SetText(text)
    {
        this.Save(PropertyType.text, this.neon.text);
        this.neon.text = text;
        this.SetUnitPrice(this.neon.neon_id);
        this.SaveToSession();
    }

    SetFont(font_id)
    {
        this.Save(PropertyType.font, this.neon.font_id);
        this.neon.font_id = parseInt(font_id);
        this.neon.font = this.GetPoliceFromId(font_id);
        this.neon.font_ratio = this.GetPoliceRatioFromId(font_id);
        this.neon.font_h_ratio = this.GetPoliceHratioFromId(font_id);
        this.neon.font_display = this.calculateur.GetFontDisplay(this.neon.font_id);
        if(!this.DimensionAvailable(this.neon.dimension_id))this.SetDimension(0);
        this.SetUnitPrice(this.neon.neon_id);
        this.SaveToSession();
    }

    SetDimension(dimension_id)
    {
        this.Save(PropertyType.dimension, this.neon.dimension_id);
        this.neon.dimension_id = parseInt(dimension_id);
        this.SetUnitPrice(this.neon.neon_id);
        this.SaveToSession();
    }

    SetSupport(support_id)
    {
        this.Save(PropertyType.support, this.neon.support_id);
        this.neon.support_id = parseInt(support_id);
        this.neon.support_display = this.calculateur.GetSupportDisplay(this.neon.support_id)
        this.SetUnitPrice(this.neon.neon_id);
        this.SaveToSession();

    }

    SetSupportColor(support_color_id)
    {
        this.Save(PropertyType.support_color, this.neon.support_color_id);
        this.neon.support_color_id = support_color_id;
        this.neon.support_color_display = this.calculateur.GetSupportColorDisplay(this.neon.support_color_id)
        this.SaveToSession();
        this.SetUnitPrice(this.neon.neon_id);

    }
    SetMessage(message)
    {
        this.neon.message = message;
    
        this.SaveToSession();
    }

    GetSupportColorFromId(support_color_id)
    {
        return this.calculateur.GetSupportColorFromId(support_color_id)
    }
    SetPrise(prise_id)
    {
        this.neon.options.prise_id = parseInt(prise_id);
        this.SaveToSession();
    }
    SetTelecommande(val)
    {
        this.neon.options.telecommande = val;
        this.SetUnitPrice(this.neon.neon_id);
        this.SaveToSession();
    }
    SetFil(fil_id)
    {
        this.neon.options.fil_id = parseInt(fil_id);
        this.SaveToSession();
    }
    SetNeonType(neon_type_id)
    {
        this.neon.options.neon_type_id = parseInt(neon_type_id);
        this.SetUnitPrice(this.neon.neon_id);
        this.SaveToSession();
    }

    SetSize(size)
    {
        this.neon.width = size[0];
        //this.neon.height = size[1];
    }

    DimensionAvailable(dimension_id)
    {
        return this.calculateur.DimensionAvailable(dimension_id, this.neon.font_id);
    }

    AddDefaultNeon()
    {
        this.AddNeon(0, this.defaultNeon.font_id,-1, this.defaultNeon.text, "center", this.defaultNeon.color, 0 ,0);
    }

    AddNeon(dimension_id = 0, font_id, support_id = -1, text, text_aligment = "center", color, support_color_id =  'transparent', unit_price)
    {
        let n = new Neon(this.index, dimension_id, font_id,this.calculateur.GetPoliceFromId(font_id), this.calculateur.GetPoliceRatioFromId(font_id), support_id, text, text_aligment, color, support_color_id, unit_price);
        n.font_h_ratio = this.GetPoliceHratioFromId(font_id);
        this.UpdateDisplay(n);
        this.neons.push(n);
        this.index++;
        this.SetNeon(n.neon_id);
        this.history.CreateHistory(this.neon.neon_id);
        this.SetUnitPrice(this.neon.neon_id);

    }

    AddNeonFromJSON(neonJSON)
    {
        let font_id = this.calculateur.GetIdFromPolice(neonJSON.font)
        let n = new Neon(
            this.index, 
            neonJSON.dimension_id, 
            font_id,
            neonJSON.font, 
            this.calculateur.GetPoliceRatioFromId(font_id), 
            neonJSON.support_id, 
            neonJSON.text, 
            neonJSON.text_aligment, 
            neonJSON.color, 
            neonJSON.support_color_id, 
            0);
            n.options = new Options(
                neonJSON.options.prise_id,
                neonJSON.options.telecommande,
                neonJSON.options.neon_type_id,
                neonJSON.options.fil_id,
                );
            n.neon_id_storage = neonJSON.neon_id_storage
            n.message = neonJSON.message;
            n.font_h_ratio = this.GetPoliceHratioFromId(font_id);
          
            this.UpdateDisplay(n);
            this.neons.push(n);
        this.index++;
        this.SetNeon(n.neon_id);
        this.history.CreateHistory(this.neon.neon_id);
        this.SetUnitPrice(this.neon.neon_id);
        return this.neon;
    }

    RemoveNeon(neon_id)
    {
        if(this.neons.length == 1)return;
        this.storageHandler.RemoveNeon(this.neons[neon_id].neon_id_storage);
        this.neons.splice(neon_id, 1);
        this.PurifyTab();
        
        this.SetNeon(null);
    }
    PurifyTab()
    {
        for(let i = 0; i < this.neons.length;i++)
        {
            this.neons[i].neon_id = i;
        }
        this.index = Math.max(0,this.neons.length);
    }
    SetNeon(neon_id)
    {
        this.lastNeon = this.neon;
        this.neon = this.neons[neon_id];
    }

    SaveToSession()
    {
        this.storageHandler.SaveNeon(this.neon);
    }

    Save(propertyType,property)
    {
        
        if(this.history.revert_mode)return;
        this.history.Save(this.neon.neon_id, propertyType, property);
    }
    EnterTmpMode()
    {
        this.history.EnterTmpMode(this.neon.neon_id);
    }
    QuitTmpMode(validate)
    {
        this.history.QuitTmpMode(this.neon.neon_id, validate);
    }
}