import Vue from "vue";
import App from "./App";
import VueMeta from "vue-meta";
import router from "./index";
import StripeHandler from "./components/renderless/StripeHandler";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  firestore,
  storage,
  auth,
  functions,
  fieldValue,
  EmailAuthProvider,
} from "./firebase";
import {
  PanierHandler,
  StorageHandlerv2,
} from "./components/renderless/PanierHandler.vue";
import CheckoutHandler from "./components/renderless/CheckoutHandler";
import { NeonHandler } from "./components/renderless/NeonHandler";
import { ArticlesHandler } from "./components/renderless/ArticlesHandler";
import {
  FirebaseStorageHandler,
  AccountsHandler,
} from "./components/renderless/FirebaseStorageHandler";
import SearchHandler from "./components/renderless/SearchHandler";
Vue.config.productionTip = false;
Vue.prototype.$firestore = firestore;
Vue.prototype.$storage = storage;
Vue.prototype.$functions = functions;
Vue.prototype.$auth = auth;
Vue.prototype.$EmailAuthProvider = EmailAuthProvider;
Math.GenerateHash = function (data) {
  if (data.quantity != undefined) data.quantity = 1;
  let str = JSON.stringify(data);
  let seed = 0;
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

Vue.use(VueMeta);
let p = Vue.extend(PanierHandler);
Vue.prototype.$storageHandler = new StorageHandlerv2();
Vue.prototype.$neonHandler = new NeonHandler(Vue.prototype.$storageHandler);
Vue.prototype.$firebaseStorageHandler = new FirebaseStorageHandler(storage);
Vue.prototype.$articlesHandler = new ArticlesHandler(
  firestore,
  fieldValue,
  Vue.prototype.$firebaseStorageHandler
);

Vue.prototype.$accountsHandler = new AccountsHandler(
  firestore,
  auth,
  functions,
  EmailAuthProvider
);
Vue.prototype.$panierHandler = new p();
Vue.prototype.$searchHandler = new SearchHandler(
  Vue.prototype.$articlesHandler
);
Vue.prototype.$stripeHandler = new StripeHandler(functions);
Vue.prototype.$checkoutHandler = new CheckoutHandler();
Math.HexToRgb = function (hex) {
  var c = hex.substring(1); // strip #
  var rgb = parseInt(c, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma;
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
