<template>
    <div class="wrapper">
        <div class="sidebar-container">
        <div @click="ToggleMenu" id="menu"  class="menu">
                <img  src="../assets/imgs/menu.png" width="40">
            </div>
        <img class="logo" src="../assets/imgs/logo_new.png" v-on:click="goTo('/')" width="120" height="auto">

        <div  ref="links" class="sidebar-text">
            <a class="underline" :class="{'underline-constant' : this.$route.path == '/Simulateur'}" v-on:click="goTo('/Simulateur')">néon sur mesure</a>
            <a class="underline" :class="{'underline-constant' : this.$route.path == '/Shop'}" v-on:click="goTo('/Shop')">shop</a>
            <a class="underline" :class="{'underline-constant' : this.$route.path == '/Galerie'}" v-on:click="goTo('/Galerie')">galerie</a>
            <a class="underline" :class="{'underline-constant' : this.$route.path == '/Pros'}" v-on:click="goTo('/Pros')">Côté pro</a>
            <a class="underline" :class="{'underline-constant' : this.$route.path == '/Contact'}" v-on:click="goTo('/Contact')">contact</a>
        </div>

        <div  ref="icons" class="sidebar-icons">

            <div class="underline ml-3"  :class="{ 'icon-disabled' : accountsHandler.pending,'underline-constant' : this.$route.path == '/Login' || this.$route.path == '/Account'}"><img data-active="1" class="search" src="../assets/imgs/account.svg" v-on:click="goTo('/Login')" width="30" height="30"></div>
            <div class=" underline position-relative" :class="{'underline-constant' : this.$route.path == '/Panier'}"><img data-active="1"  v-on:click="goTo('/Panier')" src="../assets/imgs/shop.png" width="22" height="22"><div v-if="panierHandler.panier_items.length != 0" class="pastille"><span>{{panierHandler.panier_items.length}}</span></div></div>
        </div>

    </div>
    <div id="dropdown" :style="{'max-height' : Dropdown}" class="dropdown-menu-custom">
            <a v-on:click="goTo('/Simulateur')">néon sur mesure</a>
            <a v-on:click="goTo('/Shop')">shop</a>
            <a v-on:click="goTo('/Galerie')">galerie</a>
            <a v-on:click="goTo('/Pros')">Côté pro</a>
            <a v-on:click="goTo('/Contact')">contact</a>
            <a v-on:click="goTo('/Login')">Mon compte</a>
    </div>
    </div>
</template>


<script>
export default {
    name:'Sidebar',
    data()
    {
    return {
    show : false,
    dropdown : null,
    accountsHandler : this.$accountsHandler,
    panierHandler : this.$panierHandler,
    }
    },
    computed:{

    Dropdown()
    {
        if(this.dropdown == null)return "0px";
        else if(this.show == false) return "0px";
        else return this.dropdown.scrollHeight + "px";
    }

    },

    methods : {

        goTo : function(path)
        {


            if(path == "/Login" && this.accountsHandler.currentUser.logged)path = "/Account";
            if(path == this.$route.path){this.show = false;return;}
            window.scrollTo(0,0);
            this.$router.push(path);
            this.show = false;
        },



        ToggleMenu()
        {
            this.show = !this.show;
        }
    },

    mounted()
    {
        this.dropdown = document.getElementById("dropdown");


    }

}
</script>

<style scoped>



.underline{
  position: relative;
  cursor: pointer;
}

.pastille {
    background-color : black;
    position: absolute;
    top : -25%;
    right : -70%;
    font-family: "Montserrat", sans-serif;
    font-size : 12px;
    font-weight : bold;
    border-radius: 20px;
    width : 25px;
    height : 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color : white;
}

.underline-constant::after {
    left: 0;
    right: auto;
    width: 100%;
    height: 2px;
  background-color: white;
  content: '';
  position: absolute;
  transition-duration: 0.4s;
  bottom: 0;
}

.underline::before{
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (hover: hover) and (pointer: fine) {
  .underline:hover::before{
    left: 0;
    right: auto;
    width: 100%;
  }
}

.icon-disabled {
    filter: invert(0.4);
    pointer-events: none;
}

.logo {
    cursor: pointer;
    transition-timing-function: cubic-bezier(.12,.75,.44,-0.78);
    transition-duration: 0.2s;
}

.logo:hover {
    filter : brightness(1.2)
}

a {
    cursor:pointer;
}

.wrapper {

    background-color: var(--white-break);
    position: fixed;
    top: 0;
    width: 100%;
    height : 5rem;
    z-index: 10;
}

.sidebar-container {


    height : 5rem;
    padding: 1rem;
    justify-content: space-between;
    display: flex;
    background-color: 	#404040;
    align-items: center;
    position: relative;
}

.dropdown-menu-custom {
    font-family: 'DIN', sans-serif;
    background-color: #404040;

    font-weight: 300;
    font-size: 1rem;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;

    transition-duration: 0.2s;
}
.dropdown-menu-custom > a {

    font-weight: 300;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 0.75rem 0.5rem;
    margin: 0.3rem 0rem;
    text-transform: uppercase;
    color: white!important;
}

@media(max-width: 450px)
{
    .search {
        display: none;
    }

    .sidebar-icons {
        width : unset!important;
    }
}

@media(max-width: 900px)
{
    .sidebar-text {
        display: none!important;

    }

    .wrapper {
        height : unset!important;
    }

    .sidebar-container {
        height: 5rem!important;
    }



    .sidebar-icons {
        margin-right: 1rem;
    }

    .logo {
        position: absolute;
        width: 110px!important;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }



    .menu {
        filter: invert(1);
        display: block!important;
        padding: 0.1rem 0.4rem;

        border-radius: 4px;
        cursor: pointer;
    }

    .big {
        display: none;
    }
}

.menu {
    display: none;

}

.sidebar-text {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'DIN', sans-serif;
    pointer-events: none;
    font-weight: 300;

    font-size: 1.25rem;
    text-transform: uppercase;
}

.sidebar-text > a:hover {
    text-decoration: none!important;
}

.sidebar-text > a {
    pointer-events : all;
}

.sidebar-text > * {
    margin: 4%;
    color: white!important;
}

.sidebar-icons > div {
    padding: 0.1rem 0;
}

.sidebar-icons {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}




</style>
