<template>
    <div  class="galerie-item">
                 <h3 class="galerie-item-title">{{galerieItem.title}}</h3>
                <div v-for="(bandeau, index) in Bandeaux" :key="index" class="galerie-item-bandeau">
                    <img :src="bandeau.firebase_url" alt="IMAGE" srcset="">
                </div>
                
                <div v-for="index in TwoRect" :key="index" class="galerie-item-rect-container">
                    <div class="galerie-item-rect"><img :src="OnlyRect[index-1 + index-1].firebase_url" :alt="index-1 + index -1" srcset=""></div>
                    <div class="galerie-item-rect"><img :src="OnlyRect[index + index -1].firebase_url" :alt="index + index -1" srcset=""></div>
                </div>
                <div v-if="IsAloneRect" class="galerie-item-rect-solo"><img :src="OnlyRect[OnlyRect.length-1]" alt="IMAGE" srcset=""></div>
            
          
               
                <span class="galerie-item-content">{{galerieItem.content}}</span>
           
        </div>
</template>

<script>
import { GalerieItem } from './renderless/GalerieHandler'
export default {

    props : {
        galerieItem : GalerieItem
    },

    computed : {
        Bandeaux()
        {
            return this.galerieItem.galerie_images.filter((elem) => {return elem.bandeau})
        },
        TwoRect()
        {
            
            let c = this.galerieItem.galerie_images.filter((elem) => {return !elem.bandeau});
            if(c.length %2 == 0)return c.length/2;
            return (c.splice(c.length-1,1).length-1)/2;
        },
        OnlyRect()
        {
            return this.galerieItem.galerie_images.filter((elem) => {return !elem.bandeau});
        },
        IsAloneRect()
        {
            let c = this.galerieItem.galerie_images.filter((elem) => {return !elem.bandeau});
            if(c.length %2 == 0)return false;
            return true;
        }
    }
    
}
</script>

<style scoped>


@media(max-width : 500px)
{
    .galerie-item-rect-container {
        flex-direction: column;
        align-items: center;
        justify-content : center!important;
    }

    .galerie-item-rect {
        width: 100%!important;
        height : auto!important;
        margin : 0.25rem 0!important;
    }
}


.galerie-item-rect-solo {
    margin : 0.25rem 0;
    width : calc(50% - 0.25rem);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.galerie-item-rect-solo  > img {
    width : 100%;
    height : auto;
    object-fit: cover;
  
    background-color: chocolate;
}

.galerie-item-rect-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin : 0.25rem 0;
    justify-content : space-between;

}

.galerie-item-rect {
    width : calc(50% - 0.25rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.galerie-item-rect > img {
    width : 100%;
    background-color: burlywood;
    height : auto;
    object-fit: cover;
  
}


.galerie-item-title {
    margin-top: 0.75rem;
    margin-bottom : 1rem;
    font-family: 'DIN', sans-serif;
    text-transform: uppercase;
}

.galerie-item-content {
    margin-top : 1rem;
    font-size : 13px;
    font-family : 'Montserrat', sans-serif;
}

.galerie-item {
    width: 100%;
    display: flex;
    padding : 1rem 0;
    margin : 1rem 0;
    flex-direction :column;
    align-items: center;
    border-bottom: 1px solid black;
}

.galerie-item-bandeau {
    width: 100%;
    margin : 0.25rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.galerie-item-bandeau > img {
    background-color: aliceblue;
    width: 100%;
    object-fit: contain;
    height : auto;
}

</style>