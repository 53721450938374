


export class ArticleImage {
    constructor(url,file)
    {
       
        this.local_url = url;
        this.file = file;
        if(file == null)
        {
            this.name="image";
        }
        else {
            this.name = file.name;
        }
        
        this.main = false;
        this.firebase_url = "";
        this.x = 0;
        this.y = 0;
        this.zoom = 1;
    }

    UpdateFromJson(imageJSON)
    {
        this.main = imageJSON.main;
        this.name = imageJSON.name;
        this.firebase_url = imageJSON.url;

        this.x = imageJSON.x;
        this.y = imageJSON.y;
        this.zoom = imageJSON.zoom;
    }

    

    Main(value)
    {
        this.main = value;
    }

    SetPosition(x,y,zoom)
    {
        this.x = x, this.y = y; this.zoom = zoom;
    }
    
    Clone()
    {
        let i = new ArticleImage(this.local_url, this.file);
        i.firebase_url = this.firebase_url;
        i.main = this.main;
        i.name = this.name;
        i.x = this.x;
        i.y = this.y;
        i.zoom = this.zoom;
        return i;
    }

    ExportToJSON()
    {
        return {
            url : this.firebase_url,
            main : this.main,
            name : this.name,
            x : this.x,
            y : this.y,
            zoom : this.zoom
        }
    }
    
}

export class ArticleColor {
    constructor(color_id = "default", color_name = "Default", colors = [])
    {
        this.color_id = color_id;
        this.color_name = color_name;
        this.colors = colors;
        this.errors = {color_name : false, colors : false}
    }


    ExportToJSON()
    {
        return {
            color_id : this.color_id,
            color_name : this.color_name,
            colors : this.colors
        }
        /*let images = []; 
        let main = 0;
        for(let i= 0; i< this.article_images.length; i++)
        {
            if(this.article_images[i].main)main = i;
            images.push(this.article_images[i].firebase_url);
        }
        let a = {color_id : this.color_id,color_specification : this.color_specification, color_images : images,thumbnail : this.article_images[main].firebase_url, thumbnail_pos : 
            {x : this.article_images[main].x, y : this.article_images[main].y, zoom : this.article_images[main].zoom }};
        return a;*/
    }
 

    Clone()
    {
        return new ArticleColor(this.color_id, this.color_name, this.colors);
        /*let c = new ArticleColor();
        c.color_id = this.color_id;
        c.color_specification = this.color_specification;
        for(let i= 0; i< this.article_images.length; i++)
        {  
            c.article_images[i] =  this.article_images[i].Clone();
        }
        return c;*/
    }

 

    async CreateThumbnail(functions, storage)
    {
        
        const ref = await storage.refFromURL(this.article_images[0].firebase_url);
        const metadata = await ref.getMetadata();
        const bucket = ref.bucket;
        const filePath = ref.fullPath;
        const name = ref.name; 
        const data = {bucket : bucket, filePath : filePath, name : name, metadata : metadata};
       data;
        
    }
    
}
export class ArticleSize {
    constructor(size_name,active = true, width = 0, height = 0, unit_price = 0, stock = 0)
    {
        this.size_name = size_name;
        this.width = width;
        this.height = height;
        this.unit_price = unit_price;
        this.active = active;
        this.stock = stock;
        this.errors = {width : false, height : false, price : false}
    }

  
    Clone()
    {
        return new ArticleSize(this.size_name,this.active, this.width, this.height, this.unit_price, this.stock);
    }
    ExportToJSON()
    {
        return {size_name : this.size_name, width : parseInt(this.width), height : parseInt(this.height), active : this.active, unit_price :parseInt(this.unit_price), stock :parseInt(this.stock)};
    }
}

export class Article {
    constructor( article_name = "", article_description = "",article_sizes = [new ArticleSize("XS"),new ArticleSize("S",false),new ArticleSize("M",false),new ArticleSize("L",false)],article_colors = [], article_images = [])
    {
        this.article_id = ""; 
        this.article_name = article_name;
        this.article_description = article_description;
        this.article_sizes = article_sizes;
        this.article_colors = article_colors;
        this.article_images = article_images;
        this.article_tags = [];
        this.express = false;

       
    }

    GetMinPrice()
    {
        let min_price = 200000;
        for(let i = 0; i < this.article_sizes.length; i++)
        {
            if(this.article_sizes[i].active && this.article_sizes[i].unit_price < min_price)min_price = this.article_sizes[i].unit_price;
        }
        return min_price;
    }

  

    Clone()
    {
        let a = new Article(this.article_name, this.article_description);
        for(let i = 0; i < this.article_sizes.length;i++)
        {
            a.article_sizes[i] = this.article_sizes[i].Clone();
        }
        for(let i = 0; i < this.article_colors.length;i++)
        {
            a.article_colors[i] = this.article_colors[i].Clone();
        }
        for(let i = 0; i < this.article_images.length;i++)
        {
            a.article_images[i] = this.article_images[i].Clone();
        }
        a.article_id = this.article_id;
        a.article_tags = this.article_tags;
        a.express = this.express;
        return a;
    }






    ExportToJSON()
    {
        
        let sizes = [];
        let colors = [];
        let images = []; 
     
        for(let i= 0; i< this.article_images.length; i++)
        {
            
            images.push(this.article_images[i].ExportToJSON());
        }
        for (let i = 0; i < this.article_sizes.length; i++)
        {
           
            sizes.push(this.article_sizes[i].ExportToJSON());
        }
        for (let i = 0; i < this.article_colors.length; i++)
        {
            colors.push(this.article_colors[i].ExportToJSON());
        }
        return {article_id : this.article_id,article_name : this.article_name, article_description : this.article_description, article_colors : colors, article_sizes : sizes,article_images : images, article_tags : this.article_tags == undefined ? [] : this.article_tags,express : this.express}
    }
}


export class ArticleThumbnail {
    constructor(article_id, article_name, article_price, thumbnail, thumbnail_pos)
    {
        this.article_id = article_id;
        this.article_name = article_name;
        this.article_price = article_price;
        this.thumbnail = thumbnail;
        this.thumbnail_pos = thumbnail_pos;
    }

    Clone()
    {
        return new ArticleThumbnail(this.article_id, this.article_name, this.article_price, this.thumbnail, this.thumbnail_pos);
    }
}

export const ArticleConverter = {
    toFirestore : function(article)
    {
        return article.ExportToJSON();
    },

    fromFirestore : function(snapshot, options)
    {
        const data = snapshot.data(options);
        let colors = [];
        let sizes = [];
        let images = [];
        let article_id = data.article_id;
        let article_name = data.article_name;
        let article_description = data.article_description
        let article_tags = data.article_tags;
        let express = data.express;
        if(express == null)express = false;
        for(let i = 0; i < data.article_colors.length; i++)
        {
            colors.push(new ArticleColor(data.article_colors[i].color_id,data.article_colors[i].color_name,data.article_colors[i].colors))
        }
        for(let i = 0; i < data.article_sizes.length; i++)
        {
            const size = data.article_sizes[i];       
            sizes.push(new ArticleSize(size.size_name, size.active, size.width, size.height, size.unit_price, size.stock));
        }
        for(let i = 0; i < data.article_images.length; i++)
        {
            const image = data.article_images[i];
            const obj = new ArticleImage();
            obj.UpdateFromJson(image);       
            images.push(obj);

        }
        const article = new Article(article_name, article_description, sizes, colors, images);
        article.article_tags = article_tags;
        article.article_id = article_id;
        article.express = express;
        return article
        
        
        /**
         *  let articles_colors = [];
                    for(let  i = 0; i < data.article_colors.length; i++)
                    {
                        const color = data.article_colors[i];
                        let article_images = []
                        let thumbnail = color.thumbnail;
                        let thumbnail_pos = color.thumbnail_pos;
                        for(let j = 0; j < color.color_images.length; j++)
                        {
                          
                            const img = color.color_images[j];
                            let tmp = new ArticleImage();
                            tmp.firebase_url = img;
                            const article_image_ref = this.storageHandler.getRefFromUrl(img);
                            tmp.name = article_image_ref.name;
                            if(thumbnail == img){
                            tmp.main = true;
                            if(thumbnail_pos != undefined)
                            {
                                tmp.x = thumbnail_pos.x;
                                tmp.y = thumbnail_pos.y;
                                tmp.zoom = thumbnail_pos.zoom;
                            }
                        }
                            article_images.push(tmp)
                        }
                        let b = new ArticleColor();
                        b.article_images = article_images;
                        b.color_specification = color.color_specification;
                        b.color_id = color.color_id;
                        articles_colors.push(b);
                    }
                    let article_sizes = [];
                    for (let  i = 0; i < data.article_sizes.length; i++)
                    {
                        const size = data.article_sizes[i];
                        
                        article_sizes.push(new ArticleSize(size.size_name, size.active, size.width, size.height, size.unit_price, size.stock));
                    }
                    let a = new Article(data.article_name, data.article_description, article_sizes, articles_colors);
                    a.article_id = doc.id;
                    a.article_tags = data.article_tags;
         */
        

    }
}


export class ArticlesHandler
{
    constructor(firestore, fieldvalue, storageHandler)
    {
        this.firestore = firestore;

        this.fieldvalue = fieldvalue;
        this.articles = [];
        this.articles_thumbnails = [];
        this.SUBMIT_MODE = {NEW : 0, MODIF : 1}
        this.storageHandler = storageHandler;
        this.gotTendances = false;
        this.options = {telecommande : 26, bande3M : 10};
        
    }

    async GetArticles(callback)
    {
        this.articles = await this._GetArticles();
        callback();
        
    }

    LoadPrices()
    {
        return new Promise((resolve) => {
            this._GetOptionsPrices().then(() => {
                resolve();
            });
        })
    }

        CheckArticle(article, params)
    {
        const article_size_index = params.size;
        
        if(article.article_sizes[article_size_index] == undefined)return true
        else if(article.article_sizes[article_size_index].active == false)return true
        else if(article.article_sizes[article_size_index].stock == 0)return true
        return false;
    }

    async GetStockOfArticle(article_id, size)
    {
        let article = await this.GetArticleFromId(article_id);
        return article.article_sizes[size].stock;
    }

    _GetOptionsPrices()
    {
       return new Promise((resolve, reject) => {
        this.firestore.collection("Admin").doc("Articles").get().then((doc) => {
            const data = doc.data();
            
            this.options.telecommande = parseFloat(data.telecommande);
            this.options.bande3M = parseFloat(data.bande3M);
            resolve();
        }).catch((err) => {
            reject(err);
        })
       })
    }

    async GetTendancesNeons()
    {
        if(this.gotTendances)return;
        try {
            await this.GetArticlesFromTag("Tendance");
            this.gotTendances = true;
        }   
        catch (err)
        {
            console.log(err);
        }
    }

    GetArticlesFromTag(tag)
    {
       return new Promise((resolve, reject) => {
        this.firestore.collection('Admin').doc('Articles').collection('SimpleNeons')
        .where('article_tags', 'array-contains', tag).withConverter(ArticleConverter).get().then((docs) => {
            
            let count = 0;
            docs.forEach((doc) => {
               const article = doc.data();
               let exist = false;
               for(let i = 0; i < this.articles.length; i++)
               {
                   if(this.articles[i].article_id == article.article_id){exist = true;break}
               }
               if(!exist)
               {
                this.AddThumbnailToArticle(article).then((url) => {
               
                    article.thumbnail = url;
                    this.articles.push(article);
                    count ++;
                    if(count == docs.size)
                    {
                        resolve();
                    }
                    
                })
                   
               }
               count ++;
               
               
            })
            
        } ).catch((err) => {
            reject(err);
        })
       })

    }

    ConvertArticleThumbnail(doc, data)
    {
    
        return new ArticleThumbnail(doc.id,data.article_name, data.article_price, data.thumbnail, data.thumbnail_pos);
    }

    _GetArticles()
    {
        const firestore = this.firestore;
        return new Promise((resolve, reject) => {
            
            firestore.collection('Admin').doc('Articles').collection('SimpleNeons').withConverter(ArticleConverter).get().then((docs) => {
                let articles_tmp = [];
                docs.forEach((doc) => {
                   
                    const data = doc.data();
                   
                    articles_tmp.push(data);
                    
                })
               
                resolve(articles_tmp);
            }).catch((err) => {
                reject(err);
            })
            
        })
    }

    AddThumbnailToArticle(article)
    {
        for(let i = 0; i < article.article_images.length; i++)
        {
            if(article.article_images[i].main)
            {
                return this.storageHandler.GetThumbnailFromUrl(article.article_images[i].firebase_url);
            }
            
        }
        return "";
    }

    GetArticleFromId(id)
    {
       return new Promise((resolve, reject) => {
        for(let i = 0; i < this.articles.length; i++)
        {
            if(this.articles[i].article_id == id)resolve(this.articles[i]);
        }
        this.firestore.collection('Admin').doc('Articles').collection('SimpleNeons').doc(id).withConverter(ArticleConverter).get().then((doc) => {
            let a = doc.data();
            if(a == undefined){reject();return;}
            this.AddThumbnailToArticle(a).then((url) => {
               
                a.thumbnail = url;
                this.articles.push(a);
                resolve(a);
            })
           
        }).catch((err) => {
            reject(err);
        })
       })
    }

     GetArticlesFromId(ids)
    {
        
        return new Promise((resolve, reject) => {
            if(ids.length == 0)resolve([]);
            let count = 0;
            let local_articles = [];
            for(let i = 0; i < ids.length; i++)
            {
                this.GetArticleFromId(ids[i]).then((article) => {
                    local_articles[i] = article;
                    count++;
                    if(count == ids.length)
                    {
                        
                        resolve(local_articles);
                    }
                    
                }).catch((err) => {reject(err)});
            }
        })
       
    }

    GetArticleThumbnail(id)
    {
        return new Promise((resolve, reject) => {
            for(let i = 0; i < this.articles_thumbnails.length; i++)
            {
                if(this.articles_thumbnails[i].article_id == id)resolve(this.articles[i]);
            }
            this.firestore.collection('Admin').doc('Articles').collection('Thumbnails').doc(id).get().then((doc) => {
              
                let a = this.ConvertArticleThumbnail(doc, doc.data());
                this.articles_thumbnails.push(a);
                resolve(a);
            }).catch((err) => {
                reject(err);
            })
           })
    }

    SortCroissant()
    {
        let permutation = 1;
        while(permutation != 0)
        {
            permutation = 0;
            for(let i = 0; i < this.articles.length-1;i++)
            {
                let next = (i+1)
                let price1 = 0;
                let price2 = 0;
                for(let j = 0; j < this.articles[i].article_sizes.length; j++)
                {
                    if(this.articles[i].article_sizes[j].active){price1 = this.articles[i].article_sizes[j].unit_price;break;}
                }
                for(let j = 0; j < this.articles[next].article_sizes.length; j++)
                {
                    if(this.articles[next].article_sizes[j].active){price2 = this.articles[next].article_sizes[j].unit_price;break;}
                }
                if(price1 > price2)
                {   
                    permutation = 1;
                    const tmp = this.articles[i].Clone();
                    this.articles[i] = this.articles[next];
                    this.articles[next] = tmp;
                }
            }
        }
    }

    SortDecroissant()
    {
        this.SortCroissant();
        this.articles_thumbnails.reverse();
    }

    GetArticleThumbnails(ids)
    {
        return new Promise((resolve, reject) => {
            if(ids == undefined)
        {
            this.firestore.collection('Admin').doc('Articles').collection('Thumbnails').get()
            .then((docs) => {
                docs.forEach((doc) => {
                let ok = true;
                for(let i = 0; i < this.articles_thumbnails.length; i++)
                {
                    if(this.articles_thumbnails[i].article_id == doc.id)
                    {
                        ok = false;
                    }
                }
                if(ok)
                {
                    let a = this.ConvertArticleThumbnail(doc, doc.data());
                    this.articles_thumbnails.push(a);    
                }
                })
                this.SortCroissant();
                resolve();
            }).catch((err) => {reject(err)})
            return;
        }
        let count = 0;
        for(let i = 0; i < ids.length; i++)
        {
            this.GetArticleThumbnail(ids[i]).then(() => {
                count ++;
                if(count == ids.length-1)
                resolve();
            }).catch((err) => {
                resolve(err);
            });
        }
        })
    }

}