<template>
    <div class="galerie-wrapper">
        <div class="galerie-container">
        <span class="loading-span" v-if="!galerie_items_loaded">Chargement des articles</span>
        <transition v-for="(galerieitem, index) in galerieHandler.galerie_items" :key="index" name="slide-fade" appear>
        <galerie-item :galerieItem="galerieitem" v-if="galerie_items_loaded" />
        </transition>
        
    </div>
    <mini-modal ref="logger"/>
    </div>
</template>

<script>
import GalerieItem from '../components/GalerieItem.vue'
import MiniModal from '../components/MiniModal.vue'
import ScenographieHandler from '../components/renderless/ScenographieHandler'
export default {
    name: 'Galerie',
    data()
    {
        return {
            galerieHandler : new ScenographieHandler(this.$firestore),
            galerie_items_loaded : false,
        }
    },
    metaInfo : {
        title : 'Scénographie',
        meta : [
            {name:"description" , content : "Les créations NEON BLOOM pour professionnels et particliers"},
             { property: 'og:title', content: 'Galerie'},
                { property: 'og:site_name', content: 'Neon Bloom Paris'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'},
        ]
    },
    components: {
        MiniModal,
        GalerieItem
  
        
    },
    mounted () {
        window.scrollTo(0,0);
    },
    created()
    {
        this.galerieHandler.GetGalerieItems().then(() => {
            this.galerie_items_loaded = true;
        }).catch(() => {
            this.$refs.logger.Reveal("Erreur", "impossible de charger les articles", 480, "var(--amaranth)")
        })
    }
}
</script>

<style scoped>


.loading-span:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}
@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #2c3e50;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 #2c3e50;}}


.loading-span {
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    text-align: center;
    font-size : 1.2rem;
    font-weight: bold;
}



.galerie-wrapper {
    margin-top: 5rem;
    margin-bottom : 3rem;
    padding: 0 0.75rem;
    
    position: relative;
    width: 100%;
    display : flex;
    justify-content: center;
    align-items: center;
}

.galerie-container {
    max-width: 900px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content : center;
}


</style>