<template>
    <div class="cgv">
        <div class="cgv-container">
            <span  @click="PreviousGo" class="prec">&#x2039;- Revenir a la page précedente</span>
        <h1>Mentions légales</h1>

          <p>
            Date de dernière mise à jour: 24/11/2021
          </p>
          <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site https://neonbloom.fr/ l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
          </p>
        <div class="cgv-article">
            <h5>Neon Bloom™ </h5>
            <p>
              Marque déposée: Numéro National 4501890
            </p>
        </div>
        <div class="cgv-article">
            <h5>Edition du site </h5>
          <p>
            Le présent site, accessible à l’URL https://neonbloom.fr (le « Site »), est édité par Antoine Poirrier
          </p>
        </div>
        <div class="cgv-article">
            <h5>Hebergement </h5>
            <p>
              Le Site est hébergé par la société 1&1 IONOS SARL 7, place de la Gare BP 70109 57201 Sarreguemines Cedex
              Numéro de téléphone : 0970 808 911
            </p>
        </div>
        <div class="cgv-article">
            <h5>Directeur de publication</h5>
            <p>
              Le Directeur de la publication du Site est Pierrick Noir.
            </p>
        </div>
        <div class="cgv-article">
            <h5>Nous contacter </h5>
            <p>
              L’Exploitant peut être joint à l’adresse mail suivante neonbloom.paris@gmail.com
            </p>
        </div>
        <div class="cgv-article">
            <h5>Données personnelles </h5>
            <p>
              Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD »).
            </p>
        </div>
      
    </div>
    </div>
</template>
<script>
export default {
    name : 'FAQ',
    data()
    {
        return {
            previous : null
        }
    },

    metaInfo : {
        title : 'FAQ',
        meta : [
            {name:"description" , content : "Une question ? Un doute ? cette page répondra à la moindre de vos questions quant à nos néons made in france"}
        ]
    },
  
    methods: {
        PreviousGo()
        {
            this.$router.push(this.previous.path);
        }
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.previous = from;
        
        // access to component instance via `vm`
      })
    }
}
</script>

<style scoped>
.cgv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
}

.cgv-container {
    width : 100%;
    max-width: 1000px;
    font-family: 'Montserrat', sans-serif;
    padding-top : 8rem;
    display: flex;
    flex-direction: column;
    align-items : flex-start;
    justify-content : flex-start;
}

.prec {
    margin-bottom: 1rem;
    color: #0d6efd;
    cursor: pointer;
}

.prec:hover {
    text-decoration : underline;
}

.cgv-article {
    width : 100%;
    display: flex;
  text-align: justify;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.cgv-article  > h5 {
    font-weight: bold;
}

.cgv-article  > p {
    text-align: justify;
    font-size : 16px;
  margin-top: 0.5rem;
}

.cgv-container > h1 {
    font-weight: bold;
    margin-bottom : 2rem;
}

.cgv-container > p {
    text-align : left;
}

</style>