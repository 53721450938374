<template>
<div class="article">
  <div class="image-container">
    <img :src="imageUrl" alt="">
    <span class="keyword" :style="{'background-color' : keywordColor}">{{keyword}}</span>
  </div>
  <div class="content">
    <div class="title-container">
      <h6>{{title}}</h6>
      <span>{{subtitle}}</span>
    </div>
    <div class="info-container">
      <span>Pierrick Noir</span>
      <span>Mars 2021</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "RecentArticle",
  props : {
    title : String,
    subtitle : String,
    link : String,
    keyword : String,
    imageUrl : String,
    keywordColor : String,
  }
}
</script>

<style scoped>


.info-container {
  background-color: white;
  width: 100%;
  font-size: 12px;
  padding: 0.25rem;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.75rem;
  font-family: Montserrat, sans-serif;
}

.title-container {
  display: flex;
  padding: 0 0.75rem 0.5rem;
  flex-direction: column;
  align-items: flex-start;

  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.title-container > h6 {
  font-size: 16px;
  margin-bottom: 0.1rem;
  text-align: left;
}

.title-container > span {
  font-size: 12px;
  text-align: left;
}

.keyword {
  padding: 0.25rem 1rem;
  color: white;
  font-size: 12px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.article {
  max-width: 270px;
  width: 95%;
  background-color: var(--white-break);
  height: 300px;
}

.image-container {
  position: relative;
  width: 100%;
  display: flex;

  height: 200px;
}

.image-container > img {
  height: auto;
  width: 100%;
  object-fit: cover;
}
</style>
