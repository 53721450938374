<template>
    <div>
        <h1><b>404  :(</b></h1>
        <span>La page que vous avez demandé n'existe pas </span>
        <router-link class="mt-3" :to="'/'"> -> Retourner sur le site </router-link>
    </div>
</template>

<script>
export default {
    metaInfo : {
        title : '404',
        meta : [
            {name:"robots" , content : "noindex"}
        ]
    },
}
</script>

<style scoped>
div {
    width : 100vw;
    height : 100vh;
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;
    font-family : 'Montserrat', sans-serif
}
</style>