import Vue from 'vue'
import VueRouter from 'vue-router'
const Accueil = () => import(/* webpackChunkName: "accueil-bundle" */'./Pages/Accueil');
import Galerie from './Pages/Galerie'
import Scenographie from './Pages/Scenographie'
import Contact from './Pages/Contact'
import Login from './Pages/Login_new'
import Journal from "./Pages/Journal";
import Account from './Pages/Account'
import NotFound from './Pages/NotFound'
import ProsInstallation from './Pages/ProsInstallation'
import ProsGraphique from './Pages/ProsGraphique'
import CGV from './Pages/CGV'
import Mentions from './Pages/Mentions'
import FAQ from './Pages/FAQ'
import IntentPaymentVue from "./Pages/IntentPaymentVue"
import ValidateCommande from "./Pages/ValidateCommande"
import Politic from './Pages/Politic'
import Pros from './Pages/Pros'
import ResetPassword from './Pages/ResetPassword'
import Paiement from './Pages/Paiement'
import Sidebar from './components/Sidebar'
import Footer from './components/Footer'
import {auth} from './firebase'
import Panier_new from './Pages/Panier_new'
import Shop from './Pages/Shop'
import Article from './Pages/Article'
import TopBar from './components/TopBar'
const Simulateur = () => import(/* webpackChunkName: "simulateur-bundle" */'./Pages/Simulateur_new');
Vue.use(VueRouter)


const CheckLogin = function()
{
  return new Promise((resolve) => {
    auth.onAuthStateChanged((user) => {
      if(user)resolve(true);
      else resolve(false);
    })
  })
}


  const routes = [
  {
    path: '/',
    name: 'home',
    components : {
      topbar : TopBar,
        sidebar : Sidebar,
      main : Accueil,
      footer : Footer,
    }
  },
  {
    path: '/Simulateur',
    name: 'simulateur',
    components : {

      main : Simulateur,
    }
  },
  {
    path: '/Pros',
    name: 'pros',
    components : {
      sidebar : Sidebar,
      main : Pros,
      footer : Footer,
    }
  },
  {
    path: '/Scenographie',
    name: 'scenographie',
    components : {
      sidebar : Sidebar,
      main : Scenographie,
      footer : Footer,
    }
  },
  {
    path: '/Validate',
    name: 'validate',
    components : {
      sidebar : Sidebar,
      main : ValidateCommande,
      footer : Footer,
    }
  },
  {
    path: '/ResetPassword',
    name: 'resetpassword',
    components : {

      sidebar : Sidebar,
      main : ResetPassword,
      footer : Footer,
    }
  },
  {
    path: '/Installation',
    name: 'installation',
    components : {

      sidebar : Sidebar,
      main : ProsInstallation,
      footer : Footer,
    }
  },
  {
    path: '/Creation',
    name: 'creation',
    components : {

      sidebar : Sidebar,
      main : ProsGraphique,
      footer : Footer,
    }
  },
  {
    path: '/Informations',
    name: 'informations',
    components : {

      sidebar : Sidebar,
      main : IntentPaymentVue,
      footer : Footer,
    }
  },
  {
    path: '/Mentions',
    name: 'mentions',
    components : {

      sidebar : Sidebar,
      main : Mentions,
      footer : Footer,
    }
  },
  {
    path: '/Panier',
    name: 'panier',
    components : {

        sidebar : Sidebar,
      main : Panier_new,
      footer : Footer,
    }
  },
  {
    path: '/Pay',
    name: 'pay',
    components : {


      main : Paiement,

    },
    props : true
  },
  {
    path: '/Login',
    name: 'login',
    components : {

        sidebar : Sidebar,
      main : Login,
      footer : Footer,
    },
    beforeEnter(to,from,next)
    {
      CheckLogin().then((result) => {
        if(result)next({name : 'account'});
        else next()
      })
    }
  },
  {
    path: '/Contact',
    name: 'contact',
    components : {

      sidebar : Sidebar,
      main : Contact,

    }
  }, {
    path: '/Journal',
    name: 'journal',
    components : {

      sidebar : Sidebar,
      main : Journal,
      footer : Footer,

    }
  },
  {
    path: '/CGV',
    name: 'cgv',
    components : {

      sidebar : Sidebar,
      main : CGV,
      footer : Footer,

    }
  },
  {
    path: '/Politique',
    name: 'politique',
    components : {

      sidebar : Sidebar,
      main : Politic,
      footer : Footer,

    },

  },
  {
    path: '/FAQ',
    name: 'faq',
    components : {

      sidebar : Sidebar,
      main : FAQ,
      footer : Footer,

    }
  },
  {
    path: '/Galerie',
    name: 'galerie',
    components : {

        sidebar : Sidebar,
      main : Galerie,
      footer : Footer,
    }
  },
  {
    path: '/Shop',
    name: 'shop',
    components : {

        sidebar : Sidebar,
      main : Shop,
      footer : Footer,
    }
  },
  {
    path: '/Account',
    name: 'account',
    components : {

        sidebar : Sidebar,
      main : Account,
      footer : Footer,
    },
    beforeEnter(to,from,next)
    {
      CheckLogin().then((result) => {
        if(result)next();
        else next({name : 'login'})
      })
    }
  },
  {
    path: '/article/:article_id',
    name: 'article',
    components : {

        sidebar : Sidebar,
      main : Article,
      footer : Footer,
    }
  },
  { path: '/404', components: {main : NotFound} },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
