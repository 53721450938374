<template>
     <div class="footer">

      <div class="footer-container">
           <div class="footer-content">
           <div class="footer-item">
               <span>NEON BLOOM</span>
               <ul>
                   <li @click="goTo('/Journal')">Journal</li>
                   <li @click="goTo('/Galerie')">Galerie</li>
                   <li @click="goTo('/Galerie')">Qui sommes-nous ?</li>

               </ul>
           </div>
           <div class="footer-item mt-5">
               <span>INFOS</span>
                <ul>
                    <li @click="goTo('/Contact')">Contactez-nous</li>
                    <li @click="goTo('/Mentions')">Mentions légales</li>
                    <li>Offres d'emploi</li>
                </ul>
           </div>
       </div>
       <div class="footer-item">
           <span>SHOP</span>
           <ul>
               <li @click="goTo('/Shop')">La bloomtique</li>
               <li @click="goTo('/Account')">Mon compte client</li>
               <li @click="goTo('/CGV')">CGV</li>
               <li @click="goTo('/Politique')">Politique de confidentialité</li>
               <li @click="goTo('/FAQ')">FAQ</li>
           </ul>
       </div>
       <div class="footer-content">
           <div class="footer-item" style="min-height:91px">
           <span>DISTRIBUTEURS</span>
           <ul>
               <li>Renseignements</li>
           </ul>
       </div>
           <div class="footer-item mt-3">
           <span>FOLLOW US</span>
           <ul class="footer-icons">
               <li><img @click="SeeSocialNetwork('https://www.instagram.com/__neonbloom__/')" class="scaleanim" src="../assets/imgs/insta.png" alt="" srcset=""></li>
               <li><img  @click="SeeSocialNetwork('https://www.facebook.com/profile.php?id=100063626074426')" class="scaleanim" src="../assets/imgs/facebook.png" alt="" srcset=""></li>
               <li><img  @click="SeeSocialNetwork('https://www.linkedin.com/company/neon-bloom')" class="scaleanim" src="../assets/imgs/linkedin.png" alt="" srcset=""></li>
           </ul>
       </div>
       </div>
      </div>

      <div ref="small_container" class="footer-small-container">
          <div class="footer-small-item" style="max-height:45px">
              <span>NEON BLOOM</span>
                <ul>
                   <li @click="goTo('/Journal')">Journal</li>
                   <li @click="goTo('/Galerie')">Galerie</li>
                   <li @click="goTo('/Galerie')">Qui sommes-nous</li>

               </ul>

              <span class="more-button"  @click="ShowFooterSection">+</span>
          </div>
          <div class="footer-small-item" style="max-height:45px">
              <span>INFO</span>
              <ul>
                    <li @click="goTo('/Contact')">Contactez-nous</li>
                    <li @click="goTo('/Mention Légales')">Mentions légales</li>
                    <li>Offres d'emploi</li>
                </ul>
              <span class="more-button" @click="ShowFooterSection">+</span>
          </div>
          <div class="footer-small-item" style="max-height:45px">
              <span>SHOP</span>
              <ul>
               <li @click="goTo('/Shop')">La bloomtique</li>
               <li @click="goTo('/Account')">Mon compte client</li>
               <li @click="goTo('/CGV')">CGV</li>
               <li @click="goTo('/Politique')">Politique de confidentialité</li>
               <li @click="goTo('/FAQ')">FAQ</li>
           </ul>
              <span class="more-button"  @click="ShowFooterSection">+</span>
          </div>
          <div class="footer-small-item" style="max-height:45px">
              <span>DISTRIBUTEURS</span>
              <ul>
               <li>Renseignements</li>
           </ul>
              <span class="more-button"  @click="ShowFooterSection">+</span>
          </div>
          <div class="footer-small-bottom">
              <span>FOLLOW US</span>
              <ul class="footer-icons">
               <li><img @click="SeeSocialNetwork('https://www.instagram.com/__neonbloom__/')" class="scaleanim" src="../assets/imgs/insta.png" alt="" srcset=""></li>
               <li><img  @click="SeeSocialNetwork('https://www.facebook.com/profile.php?id=100063626074426')" class="scaleanim" src="../assets/imgs/facebook.png" alt="" srcset=""></li>
               <li><img  @click="SeeSocialNetwork('https://www.linkedin.com/company/neon-bloom')"  class="scaleanim" src="../assets/imgs/linkedin.png" alt="" srcset=""></li>
           </ul>
          </div>
      </div>

      <span class="copyright">Copyright © Antoine Poirrier, tous droits réservés.</span>
   </div>
</template>

<script>

export default {

    methods: {

        SeeSocialNetwork(url)
        {
            window.open(url, '_blank').focus();
        },

           ResetFooterSections()
        {
            const children = this.$refs.small_container.children;
            for(let i = 0; i <4; i++)
            {
                const child = children[i];
                child.style.maxHeight = "45px";
                child.children[2].classList.remove("more-button-active");
            }
        },

        ShowFooterSection(event)
        {

            if(event.target.parentNode.style.maxHeight != "45px")
            {
                event.target.classList.remove("more-button-active");
                event.target.parentNode.style.maxHeight = "45px";
                return;
            }
            this.ResetFooterSections();
            event.target.parentNode.style.maxHeight = event.target.parentNode.scrollHeight + "px";
            event.target.classList.add("more-button-active");
        },

         goTo : function(path)
        {


            if(path == this.$route.path)return;
            window.scrollTo(0,0);
            this.$router.push(path);
            this.show = false;
        },


        }
}
</script>


<style scoped>

@media(max-width:745px)
{
    .footer-top {
        display: none!important;

    }

    .footer-container {
        display: none!important;
    }

    .footer-small-container
    {
        display: flex!important;
    }
}


.footer-small-container {
    display: none;
    padding-top : 1rem;
    width: 100%;
    height: 100%;
    flex-direction: column;
    font-family : "DIN", sans-serif;
    align-items: center;
}

.copyright {
    font-size : 12px;
    position : absolute;
    bottom : 0.25rem;
    left : 0.25rem;
}

.footer-small-item {
    padding : 0.6rem 1rem;
    display: flex;
    align-items: flex-start;
    max-height: 45px;
    overflow: hidden;
    flex-direction: column;
    width:100%;
    font-size: 1.1rem;
    transition-duration: 0.4s;
    position : relative;

}

.footer-small-item > ul {
    margin-top : 0.5rem;
    padding: 0;
    font-size : 14px;
    list-style: none;
    font-family : "Montserrat", sans-serif;
    text-align : left;
    font-size : 14px;

}

.footer-small-item > ul > li {
    cursor: pointer;
    transition-duration: 0.2s;
}

.footer-small-item > ul > li:hover {
    text-decoration : underline;
}



.footer-small-bottom {
    margin-top : 0.5rem;
    display: flex;
    font-size: 1.2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-small-bottom > ul {
    list-style: none;
    padding: 0;
}

.more-button {
    position: absolute;
    top : 1rem;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    right : 1rem;
    transform: translateY(-50%);
    font-size : 1.5rem;
    cursor: pointer;
    transition-duration: 0.4s
}

.more-button-active {
    transform: rotateZ(45deg);
}


.footer-icons {

display : flex;

}

.footer-icons > li > img {
    width : 45px;
    height:45px;
    margin : 0 0.25rem;
    cursor: pointer;
}

.footer-container {
    display : flex;
    margin-top: auto;
    padding : 1rem 0;
    width:100%;
    height:100%;
    margin-top: auto;
    align-items: flex-start;
    justify-content: space-around;


}


.footer{
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-top: auto;
    width: 100%;
    position: relative;
    align-items: center;
    background-color: #EEEEEE;

}


.footer-content {
    display : flex;
    flex-direction: column;

}

.footer-item {
    font-family : 'Montserrat', sans-serif;
    margin: 0 3rem;
    display : flex;
    flex-direction: column;
    align-items: flex-start;

}

.footer-item > span {
    font-family : "DIN", sans-serif;
    font-size: 1.2rem;
}

.footer-item > ul {
    margin : 0;
    padding: 0;
    list-style: none;
    text-align : left;
    font-size : 14px;

}

.footer-item > ul > li {
    cursor: pointer;
    transition-duration: 0.2s;
}

.footer-item > ul > li:hover {
    text-decoration : underline;
}
</style>
