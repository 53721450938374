<template>
<div class="main-container">
  <div class="top-container">
    <h3>Journal de bord</h3>
    <h6>des articles sur l'actualité du néon</h6>
  </div>
  <div class="topbar">
    <a href="">Catégorie 1</a>
    <a href="">Catégorie 1</a>
    <a href="">Catégorie 1</a>
    <a href="">Catégorie 1</a>
    <a href="">Catégorie 1</a>
  </div>

  <h6 class="title">
      Featured Posts
  </h6>

  <div class="featured-container">
    <featured-article keyword-color="#C6049B" keyword="Bonjour" title="Lorem Ipsum dolor sit amet" subtitle="le petit sous-titre des famille" :image-url="require('../assets/imgs/bricks.jpg')" />
    <featured-article keyword-color="#C6049B" keyword="Bonjour" title="Lorem Ipsum dolor sit amet" subtitle="le petit sous-titre des famille" :image-url="require('../assets/imgs/bricks.jpg')" />

  </div>

  <h6 class="title">
    Most recent
  </h6>

  <div class="recent-container">
    <recent-article keyword-color="#C6049B" keyword="Bonjour" title="Lorem Ipsum dolor sit amet" subtitle="le petit sous-titre des famille avec une petite description" :image-url="require('../assets/imgs/bricks.jpg')"  />
  </div>

</div>

</template>

<script>
import FeaturedArticle from "../components/FeaturedArticle";
import RecentArticle from "../components/RecentArticle";
export default {
  name: "Journal",
  components: {RecentArticle, FeaturedArticle}
}
</script>

<style scoped>

.recent-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.featured-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

h3 {
  font-weight: bold;
}

.main-container {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 2rem 0;
  padding: 0.5rem 2rem;
  width: auto;
  color: #2F2F2F;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 1px solid rgba(0,0,0,0.25);
}


.top-container {
  background-color: var(--white-break);
  width: 100%;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}

.topbar {
  width: 100%;
  padding: 0.5rem;
  justify-content: center;
  background-color: #DEDEDE;
}

.topbar > a {
  appearance: none;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: #2e2e2e;
  padding: 0 1rem;
}

</style>
