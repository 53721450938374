<template>
 <transition name="slide-fade" mode="out-in" appear>
     <div  @click="GoTo('article/' + article.article_id)" class="shop-item">
           <div class="shop-item-img-container">
               <img ref="image_shop" :style="Style" class="shop-item-img lazy" :data-src="Thumbnail" />
           </div>
           <span class="mt-2">{{article.article_name}}</span>
           <span class="shop-item-price">{{Price}}€</span>
        </div>
        </transition>
</template>

<script>
export default {
    name : 'ShopItem',
    props : {
        article : Object,
        observer : IntersectionObserver,
    },
    computed : {

        Thumbnail()
        {
            return this.article.thumbnail;
            
        },

        Price()
        {
            for(let i = 0; i < this.article.article_sizes.length; i++)
            {
                if(this.article.article_sizes[i].active)return this.article.article_sizes[i].unit_price.toFixed(2);
            }
            return 0;
        },

        Style() {
            let style = "";
            let index = 0
             for(let i = 0; i < this.article.article_images.length; i++)
            {
                if(this.article.article_images[i].main){index = i; break;}
            }
            const thumbnail_pos = this.article.article_images[index];
            if((thumbnail_pos.x != 0 && thumbnail_pos.y != 0))
            {
                style+="position:absolute;";
                style+="left:" + thumbnail_pos.x + 'px;';
                style+="top:" + thumbnail_pos.y + 'px;';
                style+="width:" + thumbnail_pos.zoom * 250 + 'px;';
                style+="height:" + thumbnail_pos.zoom * 250 + 'px;';

            }
            return style;
        }
    
    },
     methods: {

    GoTo(path)
    {
        this.$router.push(path);
    }

    },
    mounted () {
        if(this.observer != null)
        {
            this.observer.observe(this.$refs.image_shop);
        }
        else {
            this.$refs.image_shop.src = this.$refs.image_shop.dataset.src;
            this.$refs.image_shop.classList.remove("lazy");
        }
    }
    
}
</script>



<style scoped>



.shop-item {
    font-family: 'DIN', sans-serif;
    display: flex;
    cursor: pointer;
    margin : 2.5rem 2rem;
    font-size : 1.2rem;
    flex-direction: column;
    align-items: center!important;
    width : 250px;
    
}
.shop-item-img-container
{
    height : 250px;
    position: relative;
    overflow : hidden;
    border-radius: 300px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    width: 250px;
}

.shop-item-img-container:hover {
      border-radius: 200px;
      overflow : hidden;
}

.shop-item-img {
    
    object-fit: cover;
    height : 250px;
    width: 250px;
    border-radius:200px;
    margin-bottom: 0.25rem;
    transition-duration: 0.2s;
}

.shop-item-img:hover {
    filter : brightness(1.3);
    border-radius:200px;
}

.shop-item-price {
    font-weight: 500;
    color : var(--amaranth)
}

</style>