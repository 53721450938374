var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv"},[_c('div',{staticClass:"cgv-container"},[_c('span',{staticClass:"prec",on:{"click":_vm.PreviousGo}},[_vm._v("‹- Revenir a la page précedente")]),_c('h1',[_vm._v("F.A.Q")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("COMMENT NOS NÉONS SONT-ILS FABRIQUÉS ? ")]),_c('p',[_vm._v(" Tous nos panneaux sont fabriqués à la main suivant au plus près les designs numérique que avez crée avec le simulateur. Sachez que de petites quantités de colle sont parfois visibles sur votre panneau. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("QUE VAIS-JE RECEVOIR AVEC MON SIGNE NÉON ? ")]),_c('p',[_vm._v(" Nos enseignes sont conçues et livrées avec une alimentation 12 volts homologuée pour l'UE. Prêt à l'emploi, il se branche directement à une prise murale classique. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("EST-IL FRAGILE ? ")]),_c('p',[_vm._v(" Le néon LED est très robuste - pratiquement incassable. La partie la plus fragile de votre néon est l'acrylique sur lequel il est posé. Pendant la phase de conception, nous devons simplement nous assurer qu'il n'y a pas de points faibles dans la forme du support qui pourraient exercer une pression sur l'acrylique, ce qui entraînerait des ruptures. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("LES SIGNES FONT-ILS DU BRUIT ?")]),_c('p',[_vm._v(" Non, ils sont silencieux. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("COMBIEN DE TEMPS DURENT-ILS ? ")]),_c('p',[_vm._v(" Les LED ont grande durabilité : 40-50,000 heures, ou 2-3 ans si elles sont laissées allumées 24/7. Vous pouvez prolonger la vie de votre néon LED si vous l'éteignez quotidiennement. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("COMBIEN CELA PÈSE-T-IL ? ")]),_c('p',[_vm._v(" Tout dépend de la taille du design, mais pour un format poster, un néon LED pèsera autour de 1.2 kg. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("EN QUOI LA FLEX LED EST-ELLE DIFFÉRENTE DU NÉON TRADITIONNEL ?")]),_c('p',[_vm._v(" Avec une chaîne de LED enfermée dans une gaine en PVC, le néon LED ressemble beaucoup au néon traditionnel soufflé mais sans les inconvénients du gaz, du mercure ou la fragilité du verre. L'absence de structures métalliques garantit un aspect plus épuré à votre design. Les LED ont également une durée de vie plus longue et consomment très peu d'énergie. Alors que le néon traditionnel est à la fois plus coûteux et plus fragile, le néon LED présente une solution durable, moderne et économe en énergie. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("COMMENT MON NÉON EST ALIMENTÉ ?")]),_c('p',[_vm._v(" Votre design est connecté à une prise murale. Chaque pièce est livrée avec un transformateur 12V. Contrairement aux néons traditionnels, nos néons LED ne nécessitent pas de câblage par un électricien et sont fournis avec des alimentations aux normes UE. ")])])
}]

export { render, staticRenderFns }