import {ArticlePanierItem, NeonPanierItem} from './PanierHandler.vue'
export class Converter {
    constructor()
    {

    }
    //constructor(item_id, item_name, item_size ="",unit_price, quantity = 1,icon = "",item_stock = 1, item_variante = "default", item_bande3M = false, item_telecommande = false, articlesHandler = null)
    async ArticleToPanierItem(article,params, articlesHandler)
    {
        const size = params.size;
        const disabled = articlesHandler.CheckArticle(article, params)
        let index1 = 0;
        for(let i = 0; i < article.article_images.length; i++)
        {
            if(article.article_images[i].main){index1 = i; break;}
        }
        const thumbnail = article.article_images[index1].firebase_url;
        let options_price = 0;
        if(params.bande3M)options_price += articlesHandler.options.bande3M;
        if(params.telecommande)options_price += articlesHandler.options.telecommande;
        
        let x =  new ArticlePanierItem(article.article_id, article.article_name, size,article.article_sizes[size].unit_price + options_price,params.quantity == undefined ? 1 : params.quantity, 
            thumbnail, article.article_sizes[size].stock, params.variante, params.bande3M, params.telecommande, articlesHandler);
        x.item_disabled = disabled;
        return x;
    }



    // constructor(item_id, item_name, item_size ="",unit_price, quantity = 1,icon = "", jsonNeon = null,calculateur = null)
    async NeonToPanierItem(neon,params = {}, calculateur)
    {
        let neonJSON = {};
        let price = 0;
        let disabled = false;
        if(neon.font_ratio == undefined){
            
            neonJSON = neon;
            try {
                price = await calculateur.CheckPriceForNeon(neonJSON);
                
            }
            catch {
                price = 1;
            }
        }
        else {neonJSON = neon.ExportToJSON();
            price = calculateur.UpdatePrice(neon)
        }
        let x =  new NeonPanierItem(neonJSON.neon_id_storage, neonJSON.text, neonJSON.dimension_id,price,params.quantity == null ? 1 : params.quantity, null, neonJSON, calculateur)
        x.item_disabled = disabled;
        return x;
    }
    /*NeonJSONToPanierItem(neonJSON,price)
    {
        return new PanierItem(neonJSON.neon_id_storage, neonJSON.text, neonJSON.dimension_id,1,price, null, neonJSON)

    }*/

}
/**
 *  neon_id_storage : this.neon_id_storage,
            dimension_id : this.dimension_id,
            font : this.font,
            support_id :this.support_id,
            text : this.text,
            alignment : this.text_aligment,
            color : this.color,
            support_color_id : this.support_color_id,
            options : this.options.ExportToJSON(),
 */