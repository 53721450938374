<template>
    <div  class="contact-container">
        <div id="scene" class="contact-background">
            <img data-depth="0.2" style="object-fit : cover" src="../assets/imgs/fond_contact_2.jpg" alt="" srcset="">
        </div>
        
        <div class="contact-content">
            <div  class="card-container">
                <form @submit="SubmitMessage" class="contact-card">
                    <h3>Contactez-nous</h3>
                    <span>pour tout renseignements</span>
                    <input-view input_title="Nom Complet" :required="true" v-model="name" :dark="true" pattern="([a-zA-Z]+[ ]*)+"/>
                    <input-view input_title="Email" :required="true" v-model="email" :dark="true"/>
                    <input-view input_title="Sujet" :required="true" v-model="subject" :dark="true"/>
                    <textarea :class="{'empty-input' : errors.message}" @input="RemoveError" required id="messsage" v-model="message" class="form-control mt-3" placeholder="Message"/>
                    <small style="color:#404040!important;text-align:left" class="mt-3">{{Fichiers}}</small>
                    <div>
                        <button  :class="{'button-disabled' : download}" class="article-panier-button mt-0 " >Envoyer</button>
                        <button @click="ShowImageAdder" style="white-space:nowrap"  class="article-panier-button mt-0  ml-2 button-black" >Joindre des images</button>
                    </div>
                </form>
                <div class="coord-card">
                    <h3 style="text-align:left">Nos coordonnées</h3>
                    <div class="coord-item">
                        <img src="../assets/imgs/phone.svg" width="40" height="40" alt="" srcset="">
                        <div>
                            <span class="coord-item-title">Numéro de téléphone :</span>
                            <span class="coord-item-content">06 99 88 43 21</span>
                        </div>
                    </div>
                    <div class="coord-item">
                        <img src="../assets/imgs/email.svg" width="40" height="40" alt="" srcset="">
                        <div>
                            <span class="coord-item-title">Email :</span>
                            <span class="coord-item-content">neonbloom.paris@gmail.com</span>
                        </div>
                    </div>
                    <div class="coord-item">
                        <img src="../assets/imgs/website.svg" width="40" height="40" alt="" srcset="">
                        <div>
                            <span class="coord-item-title">Site web :</span>
                            <span class="coord-item-content">www.neonbloom.fr</span>
                        </div>
                    </div>
                    
                    </div>
            </div>
        </div>
        <mini-modal ref="addImage">
            <images-adder :justfile="true" :maxsize="2500000" @newurlsajout="HandleUrls" validate_text="Confirmer" :persistant="true"/>
        </mini-modal>
        <mini-modal ref="logger_modal" />
    </div>
</template>
<script>
import ImagesAdder from '../components/ImagesAdder.vue'
import MiniModal from '../components/MiniModal.vue'
import Parallax from 'parallax-js'
import InputView from '../components/InputView.vue'
export default {
    name: 'Contact', 
    components: {
        MiniModal,
        ImagesAdder,
        InputView
    },

     metaInfo : {
        title : 'Contact',
        meta : [
            {name:"description" , content : "L'équipe Néon Bloom sera ravis de vous accompagner dans votre démarche de création de néon. Contactez nous !"}
        ]
    },
    data()
    {
        return {
            urls : [],
            name : "",
            errors : {name : false, email : false, subject : false, message : false},
            email : "",
            subject : "",
            message : "",
            download : false,
            parallaxInstance : null,
        }
    },
    computed: {
        Fichiers()
        {
            if(this.urls.length == 0)return "Aucun fichier joint";
            if(this.urls.length == 1)return ("1 fichier joint")
            return (this.urls.length + " fichiers joints")
        }
    },
    methods: {

    RemoveError(event)
    {
        const id = event.target.id;
        switch (id)
        {
            case "name" : this.errors.name = false;
            break;
            
            case "email" : this.errors.email = false;
            break;

            case "subject" : this.errors.subject = false;
            break;

            case "message" : this.errors.message = false;
            break;

        }
    },
    
    SubmitMessage(e)
    {
        e.preventDefault();
        this.download = true;
        const instance = this;
        this.$firebaseStorageHandler.ConvertToBase64(this.urls).then((newurls) => {
           
            const sendMail = this.$functions.httpsCallable('sendMail');
            sendMail({name : instance.name ,mail : instance.email, subject : instance.subject,message : instance.message, attachments : newurls})
            .then((result) => {
                if(result.data == "DONE")
                {
                    instance.SubmitMessageCallback(true);
                }
            })
        }).catch(() => {
            instance.SubmitMessageCallback(false);
        });
        
        
    },

    SubmitMessageCallback(ok)
    {
        this.download = false;
        if(!ok)
            {
                this.$refs.logger_modal.Reveal("Erreur inconnue", "Veuillez reessayer dans quelques instants",450, "var(--amaranth)");
            }
            else {
                this.$refs.logger_modal.Reveal("Message envoyé", "Merci pour votre message , nous vous recontacterons au plus vite !",450, "#16a085");

            }
    },

    MessageValidator()
    {
        let ok = true;
        if(this.name == "")
        {
            this.errors.name = true;
            ok = false;
        }
        if(this.email == "" || !this.CheckEmail(this.email))
        {
            this.errors.email = true;
            ok = false;
        }

        if(this.subject == "")
        {
            this.errors.subject = true;
            ok = false;
        }
        if(this.message == "")
        {
            this.errors.message = true;
            ok = false;
        }
        return ok;
    },

    CheckEmail(email)
    {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
    },
    
    HandleUrls(urls)
        {
            this.urls = urls;
            this.HideImageAdder();
        },
    
    ShowImageAdder()
  {
    this.$refs.addImage.Reveal("Ajouter des images");
      
  },
  HideImageAdder()
  {
    this.$refs.addImage.Hide();
      
  },
    
    },
    beforeMount()
    {
        
    },
 
    mounted() {
        var scene = document.getElementById('scene');
        this.parallaxInstance = new Parallax(scene);
    }
}
</script>

<style scoped>

@media(max-width:1282px)
{
    .contact-background > img {
    width : auto!important;
    height : 120%!important;
}

@media(max-width:800px)
{
    .card-container {
        width : 100%!important;
        height : unset!important;
        flex-direction: column!important;
        align-items : center;
        padding : 2rem 0!important;
    }

    .contact-content {
        overflow-y:auto;

    }

    .coord-card {
        margin-left : 0!important;
        margin-top : 1rem;

    }

    .coord-card > h3 {
        text-align: center!important;
    }

    .coord-item {
        flex-direction: column!important;
        
    }

    .coord-item > div {
        align-items: center!important;
    }
}

}

.button-disabled {
    background-color: grey!important;
    border: 1px solid gray!important;
    color: lightgray!important;
    pointer-events: none;
}

.empty-input{
        border-color: rgba(255, 0, 0, 0.8);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(243, 58, 58, 0.6);
    }

.coord-item-title {
    color: #9F9F9F;
}

.coord-item-content {
    font-size: 15px;
}

.coord-item {
    margin-top : 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1rem;
}

.coord-item > img {
    
    margin-right: 0.5rem;
}

.coord-item > div {
    display: flex;
    flex-direction: column;
    align-items : flex-start
}

.article-panier-button {
    
    color : var(--amaranth);
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.1rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color: white;
    border : 1px solid var(--amaranth);
    width : 100px;
    transition-duration: 0.2s;
    height : 40px;
    margin-top: 2rem;
}

.article-panier-button:hover {
    background-color : var(--amaranth);
    color : white;
    

}

.card-container {
    display: flex;
    width: 860px;
    height : auto;
    font-family: 'Montserrat', sans-serif;
    justify-content: center;
    
}

input {
    font-size: 16px!important;
}

.contact-card > div {
    display: flex;
    margin-top : 0.25rem;
    width : 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.button-black {
    background-color : white!important;
    border : 1px solid #404040!important;
    color: #404040!important;
    width : 150px;
}

.button-black:hover {
    background-color : #404040!important;
    border : 1px solid #404040!important;
    color: white!important;

}

.contact-card {
    color : black;
    border-radius : 4px;
    background-color : white;
    display : flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 1.5rem;
    width : 370px;
    max-width: 95%;
}

.contact-card > span {
    font-size: 0.8rem;
    line-height : 18px;
    display: block;
    margin-top: 0.25rem;
    color : #707070;
}

.contact-card > textarea {
    min-height: 150px;
    background-color : #c9d1d9!important;
}

.coord-card {
    color: white;
    padding: 1rem;
    margin-left: 2rem;
}

h3 {
    font-size : 1.25rem;
    font-weight : bold;
    letter-spacing : -0.02rem;
    margin: 0;
}

.contact-content {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
    background-color : rgba(61,0,131,0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.contact-container {
    margin-top : 5rem;
    position: relative;
    width : 100%;
    height : 100%;
    display: flex;
    flex : 1 1 auto;
    
}

.contact-background {
    position: absolute;
    width : 100%;
    height : 100%;
    z-index : 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
}

.contact-background > img {
    width : 120%;
    height : auto;
}
</style>