<template>
    <div class="cgv">
        <div class="cgv-container">
            <span  @click="PreviousGo" class="prec">&#x2039;- Revenir a la page précedente</span>
        <h1>F.A.Q</h1>
        <div class="cgv-article">
            <h5>COMMENT NOS NÉONS SONT-ILS FABRIQUÉS ? </h5>
            <p>
                Tous nos panneaux sont fabriqués à la main suivant au plus près les designs numérique que avez crée avec le simulateur. Sachez que de petites quantités de colle sont parfois visibles sur votre panneau.
            </p>
        </div>
        <div class="cgv-article">
            <h5>QUE VAIS-JE RECEVOIR AVEC MON SIGNE NÉON ? </h5>
            <p>
            Nos enseignes sont conçues et livrées avec une alimentation 12 volts homologuée pour l'UE. Prêt à l'emploi, il se branche directement à une prise murale classique.            </p>
        </div>
        <div class="cgv-article">
            <h5>EST-IL FRAGILE ? </h5>
            <p>
            Le néon LED est très robuste - pratiquement incassable. La partie la plus fragile de votre néon est l'acrylique sur lequel il est posé. Pendant la phase de conception, nous devons simplement nous assurer qu'il n'y a pas de points faibles dans la forme du support qui pourraient exercer une pression sur l'acrylique, ce qui entraînerait des ruptures. 
            </p>
        </div>
        <div class="cgv-article">
            <h5>LES SIGNES FONT-ILS DU BRUIT ?</h5>
            <p>
                Non, ils sont silencieux. 
            </p>
        </div>
        <div class="cgv-article">
            <h5>COMBIEN DE TEMPS DURENT-ILS ? </h5>
            <p>
                Les LED ont grande durabilité : 40-50,000 heures, ou 2-3 ans si elles sont laissées allumées 24/7. Vous pouvez prolonger la vie de votre néon LED si vous l'éteignez quotidiennement. 
            </p>
        </div>
        <div class="cgv-article">
            <h5>COMBIEN CELA PÈSE-T-IL ? </h5>
            <p>
                Tout dépend de la taille du design, mais pour un format poster, un néon LED pèsera autour de 1.2 kg.
            </p>
        </div>
        <div class="cgv-article">
            <h5>EN QUOI LA FLEX LED  EST-ELLE DIFFÉRENTE DU NÉON TRADITIONNEL ?</h5>
            <p>
                Avec une chaîne de LED enfermée dans une gaine en PVC, le néon LED ressemble beaucoup au néon traditionnel soufflé mais sans les inconvénients du gaz, du mercure ou la fragilité du verre. L'absence de structures métalliques garantit un aspect plus épuré à votre design. Les LED ont également une durée de vie plus longue et consomment très peu d'énergie.
                Alors que le néon traditionnel est à la fois plus coûteux et plus fragile, le néon LED présente une solution durable, moderne et économe en énergie. 
            </p>
        </div>
        <div class="cgv-article">
            <h5>COMMENT MON NÉON EST ALIMENTÉ ?</h5>
            <p>
                Votre design est connecté à une prise murale. Chaque pièce est livrée avec un transformateur 12V. Contrairement aux néons traditionnels, nos néons LED ne nécessitent pas de câblage par un électricien et sont fournis avec des alimentations aux normes UE.
            </p>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name : 'FAQ',
    data()
    {
        return {
            previous : null
        }
    },

    metaInfo : {
        title : 'FAQ',
        meta : [
            {name:"description" , content : "Une question ? Un doute ? cette page répondra à la moindre de vos questions quant à nos néons made in france"}
        ]
    },
  
    methods: {
        PreviousGo()
        {
            this.$router.push(this.previous.path);
        }
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.previous = from;
        
        // access to component instance via `vm`
      })
    }
}
</script>

<style scoped>
.cgv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
}

.cgv-container {
    width : 100%;
    max-width: 1000px;
    font-family: 'Montserrat', sans-serif;
    padding-top : 8rem;
    display: flex;
    flex-direction: column;
    align-items : flex-start;
    justify-content : flex-start;
}

.prec {
    margin-bottom: 1rem;
    color: #0d6efd;
    cursor: pointer;
}

.prec:hover {
    text-decoration : underline;
}

.cgv-article {
    width : 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.cgv-article  > h5 {
    font-weight: bold;
}

.cgv-article  > p {
    text-align: left;
    font-size : 14px;
}

.cgv-container > h1 {
    font-weight: bold;
    margin-bottom : 2rem;
}

.cgv-container > p {
    text-align : left;
}

</style>