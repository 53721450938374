<script>

export class Item {
    constructor(item_id, item_name, item_size ="",unit_price,icon = "")
    {
        this.item_id = item_id;
        this.item_name = item_name;
        this.item_size = item_size;
        this.unit_price = unit_price;
        this.icon = icon;


    }

    
}



export class PanierItem extends Item {
    constructor(item_id, item_name, item_size =1, quantity = 1,unit_price,icon = "", neon = null,data = {}, stock = 10)
    {
        super(item_id, item_name, item_size,unit_price,icon)
        this.quantity = quantity;
        this.total_price = unit_price*this.quantity;
        this.data = data;

        this.disabled = false;
        if(neon != null) {
            if(neon.color == "rainbow")
            {
                this.icon = "red"
            }
            else {
                this.icon = neon.color;
            }
        }
        this.stock = stock;
        this.neon=neon;
        let json = this.ExportToJSON();
        json.stock = 0;
        this.panier_id = Math.GenerateHash(json);

    }

    UpdateQuantity(q)
    {
     
        this.quantity = Math.min(Math.max(1,this.quantity + q), this.stock);
        this.total_price = this.unit_price * this.quantity;
    }

    

    SetQuantity(q)
    {
        this.quantity = Math.min(Math.max(1,q), this.stock);
        this.total_price = this.unit_price * this.quantity;
    }

    ExportToJSON()
    {
        return {
            item_id : this.item_id,
            item_name : this.item_name,
            item_size : this.item_size,
            quantity : this.quantity,
            unit_price : this.unit_price,
            icon : this.icon,
            neon : this.neon,
            data : this.data,
            variante : this.variante,
            stock : this.stock
        }
    }
    
    IsEqual(panierItem)
    {
        if(this.item_id != panierItem.item_id)return false;
       
        if(this.item_name != panierItem.item_name)return false;
      
        if(this.item_size != panierItem.item_size)return false;
      
        if(this.unit_price != panierItem.unit_price)return false;

        if(this.neon != panierItem.neon)return false;
       
        if(JSON.stringify(this.data) != JSON.stringify(panierItem.data)) return false;
        
        return true;
    }


}

export class PanierItem2 {
    constructor(item_id, item_name, item_size ="",unit_price, quantity = 1,icon = "")
    {
        this.item_id = item_id;
        this.item_name = item_name;
        this.item_size = item_size;
        this.unit_price = unit_price;
        this.quantity = quantity;
        this.total_price = this.quantity * this.unit_price;
        this.icon = icon;
        this.item_disabled = false;
        this.item_loaded = false;
        this.item_storage_id = this.GenerateStorageId();

    }

     UpdateQuantity(q)
    {
       
        this.quantity = Math.max(1,this.quantity + q);
        this.quantity  =Math.min(5, this.quantity);
        this.UpdateTotalPrice();
    }

    SetQuantity(q)
    {
        this.quantity = Math.max(1,this.SanatizeQuantity(q));
        this.quantity  =Math.min(5, this.quantity);
        this.UpdateTotalPrice();
    }

    SanatizeQuantity(q)
    {
        if(q == 0 || q == "")return 1;
        try{
            return parseInt(q);
        }
        catch {
            return 1;
        }
    }

    UpdateTotalPrice()
    {
        this.total_price = this.unit_price * this.quantity;
    }

    ExportToJSON()
    {
        return {
            item_id : this.item_id,
            item_name : this.item_name,
            item_size : this.item_size,
            quantity : this.quantity,
            unit_price : this.unit_price,
            storage_id : this.item_storage_id 
        }
    }

    isEquals(panier_item)
    {
        if(this.item_storage_id != panier_item.item_storage_id)return false;
        return true;
    }

    CheckPrice()
    {
        return this.unit_price
    }

    GenerateStorageId()
    {
        let json = {item_id : this.item_id, item_size : this.item_size};
        let json2 = this.GenerateStorageIdHook();
       
         return Math.GenerateHash({j1 : json, j2 : json2});
    }

    GenerateStorageIdHook()
    {
       return {}
    }
}

export class  ArticlePanierItem extends PanierItem2
{
    constructor(item_id, item_name, item_size ="",unit_price, quantity = 1,icon = "",item_stock = 1, item_variante = "default", item_bande3M = false, item_telecommande = false, articlesHandler = null)
    {
        super(item_id, item_name, item_size,unit_price, quantity,icon);
        this.item_stock = item_stock;
        this.item_variante = item_variante;
        this.item_bande3M = item_bande3M;
        this.item_telecommande = item_telecommande;
        this.articlesHandler = articlesHandler;
        this.item_storage_id = this.GenerateStorageId();
        this.item_express = false;
    }

    ToJSONProduct()
    {
        return {
            type : 0,
            size : this.item_size,
            id : this.item_id,
            total_price : this.total_price,
            quantity : this.quantity,
            variante : this.item_variante,
            bande3M : this.item_bande3M,
            telecommande : this.item_telecommande
        }
    }

    GenerateStorageIdHook()
    {
        return {telecommande : this.item_telecommande, variante : this.item_variante, bande3M : this.item_bande3M}
    }

    UpdateQuantity(q)
    {
        
        this.quantity = Math.max(1,this.quantity + q);
        this.quantity  =Math.min(this.item_stock, this.quantity);
        this.UpdateTotalPrice();
    }

    SetQuantity(q)
    {
        this.quantity = Math.max(1,this.SanatizeQuantity(q));
        this.quantity  =Math.min(this.item_stock, this.quantity);
        this.UpdateTotalPrice();
    }

     ExportToJSON()
    {
        return {
            item_id : this.item_id,
            item_size : this.item_size,
            telecommande : this.item_telecommande,
            bande3M : this.item_bande3M,
            variante : this.item_variante,
            quantity : this.quantity,
            unit_price : this.unit_price,
            storage_id : this.item_storage_id,
        }
    }

    

    async CheckItem()
    {
        this.item_loaded = true;
    }
}

export class NeonPanierItem extends PanierItem2 {
      constructor(item_id, item_name, item_size ="",unit_price, quantity = 1,icon = "", jsonNeon = null,calculateur = null)
      {
         super(item_id, item_name, item_size,unit_price, quantity,icon);
       
        this.item_data_neon = jsonNeon;
        if(jsonNeon.color == "rainbow"){this.item_data_neon.color_display = "RGB";}

         this.icon = this.item_data_neon.color;
        this.calculateur = calculateur;
        this.item_storage_id = this.GenerateStorageId();
      }

      ToJSONProduct()
    {
        return {
            type : 1,
            id : this.item_id,
            total_price : this.total_price,
            quantity : this.quantity,
            neon_data : this.item_data_neon,
        }
    }

      GenerateStorageIdHook()
      {
        if(this.item_data_neon == undefined)return {};
         
          let copy = JSON.parse(JSON.stringify(this.item_data_neon));
          copy.neon_id_storage = 0;
          return copy;

      }

      ExportToJSON()
      {
          return {
            item_id : this.item_id,
            item_size : this.item_size,
            quantity : this.quantity,
            unit_price : this.unit_price,
            neon : this.item_data_neon,
            storage_id : this.item_storage_id,
        }
      }
}

//OK
export class StorageHandlerv2 {
     constructor()
    {

       
        this.sim_logged = false;
        this.local_storage_enabled = this.CheckLocalStorage();
        this.session_storage_enabled = this.CheckSessionStorage();
        this.panier_items_saved = [];
        this.neons_saved = [];
        
    }
    IsSimLogged()
    {
        if(this.session_storage_enabled)
        {
            let value = sessionStorage.getItem('simlogged');
            if(value == null)return false;
            return true;
        }
        return false;
    }
    LogSim()
    {
        if(this.session_storage_enabled)
        {
            sessionStorage.setItem('simlogged', 'true');
        }
    }

    GetPanier()
    {
        if(this.session_storage_enabled){
            let t =  JSON.parse(sessionStorage.getItem("items"));
            if(t == undefined)
            {
            this.panier_items_saved = [];return []
            }
          
       
            this.panier_items_saved = t;
            let tmp = [];
            for(let i = 0; i < this.panier_items_saved.length;i++)
            {
                tmp.push(this.GetPanierItemFromStorage(this.panier_items_saved[i]));
          
            }
            return tmp;
        }
    }
    SendIdsToStorage()
    {
        if(this.session_storage_enabled)
        {
            sessionStorage.setItem('items', JSON.stringify(this.panier_items_saved));
        }
    }
    GetPanierItemFromStorage(panier_id)
    {
        if(this.session_storage_enabled)
        {
            let item_data = JSON.parse(sessionStorage.getItem(panier_id));
            return item_data;
        }
    }
    SendPanierItemToStorage(panierItem)
    {
        const id = panierItem.item_storage_id;
        if(!this.panier_items_saved.includes(id))
        {
            this.panier_items_saved.push(id);
        }
        
        if(this.session_storage_enabled)
        {
            sessionStorage.setItem(id,JSON.stringify(panierItem.ExportToJSON()));
        }
    }

    SavePanier(panier_items)
    {
        for(let i = 0; i < panier_items.length;i++)
        {
            this.SendPanierItemToStorage(panier_items[i]);
            this.SendIdsToStorage();
        }
    }

    ClearPanierStorage()
    {
        for(let i = 0; i < this.panier_items_saved.length; i++)
        {
            this.ClearItemStorage(this.panier_items_saved[i])
        }
        this.panier_items_saved = [];
        this.SendIdsToStorage();
    }

    ClearItemStorage(item_storage_id)
    {
        if(this.session_storage_enabled)
        {
            sessionStorage.setItem(item_storage_id, "");
        }
    }


    RemovePanierItemFromStorage(panier_id)
    {
        
        if(this.session_storage_enabled)
        {
            sessionStorage.setItem(panier_id, "");
            this.panier_items_saved.splice(this.panier_items_saved.indexOf(panier_id), 1);
            this.SendIdsToStorage();
        }

    }

    GetSamePanierItemGeneric(item_id, size)
    {
        let occurences = [];
        for(let i = 0; i < this.panier_items.length; i++)
        {
            if(this.panier_items[i].item_id == item_id && this.panier_items[i].item_size == size)occurences.push(this.panier_items[i]);
        }
    }

    async HandleSameArticleQuantity(panier_item)
    {
        const stock = this.articlesHandler.GetStockOfArticle(panier_item.item_id, panier_item.item_size);
        const occurences = this.GetSamePanierItemGeneric(panier_item.item_id, panier_item.item_size);
        const delta = occurences.length;
        for(let i = 0; i < delta;i++ )
        {
            occurences[i].item_stock = stock - delta;
        }
        let quantities = [];
        let total_quantity = 0;
        for(let i = 0; i < delta; i++)
        {
            quantities[i] = occurences[i].quantity;
            total_quantity += occurences[i].quantity;
        }
        if(total_quantity < stock)return;
        
       

    }

    SendNeonIdsToStorage()
    {
         if(this.session_storage_enabled)
        {
            sessionStorage.setItem('neons', JSON.stringify(this.neons_saved));
        }
    }

    SaveNeon(neon)
    {
        const neonJSON = neon.ExportToJSON();
        if(!this.neons_saved.includes(neonJSON.neon_id_storage))
        {
            this.neons_saved.push(neonJSON.neon_id_storage);
            this.SendNeonIdsToStorage();
        }
        if(this.session_storage_enabled)
        {
            sessionStorage.setItem(neonJSON.neon_id_storage + '-edit', JSON.stringify(neonJSON));
        }

    }

    RemoveNeon(neon_storage_id)
    {
        this.neons_saved.splice(this.neons_saved.indexOf(neon_storage_id),1);
        if(this.session_storage_enabled)
        {
            this.SendNeonIdsToStorage();
            sessionStorage.setItem(neon_storage_id + '-edit', "");
        }
    }

    GetNeonJSON(neon_storage_id)
    {
        if(this.session_storage_enabled)
        {
            const neonJSON = JSON.parse(sessionStorage.getItem(neon_storage_id + '-edit'));
            return neonJSON;
        }
        return null;
    }

    GetNeonsJSON()
    {
        if(this.session_storage_enabled)
        {
            let str = sessionStorage.getItem("neons");
            if(str == null){
            this.neons_saved = [];
            return [];
            }
            else this.neons_saved = JSON.parse(str);
            let tmp = []
            for(let i = 0; i < this.neons_saved.length; i++)
            {
                let neon = this.GetNeonJSON(this.neons_saved[i]);
                if(neon != null)tmp.push(neon);
                
            }
            return tmp;
        }
    }




    CheckLocalStorage(){
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
    }

    CheckSessionStorage(){
    var test = 'test';
    try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
    }

}



const States = {"prepare":1, "running":2, "submited":3}
Object.freeze(States)
import {Converter} from './Converter'
export const PanierHandler = {
    name : 'PanierHandler',

    data()
    {
        return {
            storageHandler : this.$storageHandler,
            accountsHandler : this.$accountsHandler,
            articlesHandler : this.$articlesHandler,
            calculateur : this.$neonHandler.calculateur,
            converter : new Converter(),
            panier_items : [],
            panier : null,
            panier_loaded : false,
            panier_state : States.prepare,
            


        }
    },
    render()
    {
        return("");
    },
    created()
    {
        const json_items = this.storageHandler.GetPanier();
        this.ImportStoragePanier(json_items);
        
       /* this.articlesHandler.LoadPrices().then(async () => {
            this.panier_items = this.$storageHandler.GetPanier();
            this.CheckPrice().then(() => {
                this.panier_loaded = true;
            })
           

        })*/
        //this.panierItems = this.storageHandler.GetPanier();
    },
    methods : {

       async CheckPrice()
        {
            
            for(let i = 0; i < this.panier_items.length; i++)
            {
                if(this.panier_items[i].neon != null)
                {
                    try {
                        let price = await this.$neonHandler.calculateur.CheckPriceForNeon(this.panier_items[i].neon);
                   
                    this.panier_items[i].unit_price = price; 
                    
                    }
                    catch
                    {
                        this.panier_items[i].disabled = true;
                    }
               
               }
               else {
                   this.articlesHandler.CheckArticlePrice(this.panier_items[i]).then((price) => {
                       this.panier_items[i].unit_price = price; 
                       
                   }).catch(() => {
                       this.panier_items[i].disabled = true; 
                   })
               }
            }
        },

        SaveUserPanier(name)
        {
            return this.accountsHandler.SaveUserPanier(this.panier_items, name);  
        },

        ClearPanier()
        {
            this.panier_items = [];
            this.storageHandler.ClearPanierStorage();
        },

        async CheckExpress()
        {
            
            for(let i = 0; i < this.panier_items.length; i++)
            {

                if(this.panier_items[i].item_data_neon != null)return false;
                else {
                  const article = await  this.articlesHandler.GetArticleFromId(this.panier_items[i].item_id)
                  if(!article.express)return false;
                }

            }
            return true;
        },

        ImportUserPanier(data)
        {
            this.storageHandler.ClearPanierStorage();
            return this.ImportStoragePanier(data);
            /*return new Promise((resolve, reject) => {
                let panier_items_new = [];
                let counter = 0;
            for(let i = 0; i < data.length; i++)
            {
                if(data[i].neon == null)
                {
                    this.articlesHandler.GetArticleFromId(data[i].item_id).then((article) => {
                        let index = 0;
                        for(let i = 0; i < article.article_images.length;i++)
                        {
                            if(article.article_images[i].main){index=i;break;}
                        }
                   
                    let p = this.converter.ArticleToPanierItem(article, data[i].item_size, article.article_images[index].firebase_url, data[i].data, data[i].stock);
                    p.SetQuantity(data[i].quantity);
                    panier_items_new.push(p);
                    counter++;
                   
                    if(counter >= data.length)
                    {
                        this.panier_items = panier_items_new;
                        this.$storageHandler.ClearPanierStorage();
                        this.$storageHandler.SavePanier(this.panier_items);
                  
                        resolve("panier-updated");
                    }
                    }).catch((err) => {
                       
                            reject(err)
                        })
                   
                }
                else {
                    let n = this.converter.NeonJSONToPanierItem(data[i].neon,data[i].unit_price);
                    n.SetQuantity(data[i].quantity);
                    panier_items_new.push(n);
                    counter++;
                    if(counter >= data.length)
                    {
                        this.panier_items = panier_items_new;
                     
                        this.$storageHandler.ClearPanierStorage();
                        this.$storageHandler.SavePanier(this.panier_items);
                        resolve("panier-updated");
                    }
                }
                
               
                        
                   
                
            }
            })*/
    
    },

        async ImportStoragePanier(json_items) {
            this.panier_loaded = false;
            await this.articlesHandler.LoadPrices();
            let items = [];
            
            for(let i = 0; i < json_items.length; i++)
            {
                const json_item = json_items[i];
                if(json_item.telecommande != undefined)
                {
                    //ARTICLE
                    try {
                        const article  = await this.articlesHandler.GetArticleFromId(json_item.item_id);
                        const params = {quantity : json_item.quantity ,size : json_item.item_size, "telecommande" : json_item.telecommande, "bande3M" : json_item.bande3M, "variante" : json_item.variante}
                    const article_panier_item = await this.converter.ArticleToPanierItem(article, params, this.articlesHandler);
                    items.push(article_panier_item);
                    }
                    catch {
                        this.storageHandler.RemovePanierItemFromStorage(json_item.storage_id);
                    }
                    
                }
                else
                {
                    const params = {quantity: json_item.quantity};
                    const neon_panier_item = await this.converter.NeonToPanierItem(json_item.neon, params, this.calculateur);
                    items.push(neon_panier_item);
                    //NEON
                }
            }
            this.panier_items = items;
            this.storageHandler.SavePanier(this.panier_items);
            this.panier_loaded = true;
        },

        


        SetQuantity(value, item)
        {
            
            const index = this.GetIndexOfItem(item);
            this.panier_items[index].SetQuantity(value);
            this.storageHandler.SavePanier([this.panier_items[index]]);
            
        },
        UpdateQuantity(value, item)
        {
            
            let index = this.GetIndexOfItem(item);
            this.panier_items[index].UpdateQuantity(value);
            this.storageHandler.SavePanier([this.panier_items[index]]);
        },

        GetIndexOfItem(item)
        {
         
            for(let i = 0; i < this.panier_items.length;i++)
            {
                if(this.panier_items[i].isEquals(item))return i;
            }
            return null;
        },

        async AddNeonToPanier(neon,params)
        {
            
            const item = await this.converter.NeonToPanierItem(neon,params, this.calculateur);
          
           let index = this.GetIndexOfItem(item);
            if(index != null)
            {  
                const quantity = this.panier_items[index].quantity;
                this.panier_items[index].UpdateQuantity(quantity);
                this.$storageHandler.SavePanier([this.panier_items[index]]);
                return;
            }
            this.panier_items.push(item);
            this.$storageHandler.SavePanier([item]);

        },


        async AddArticleToPanier(article,params)
        {
     
            const item = await this.converter.ArticleToPanierItem(article,params, this.articlesHandler);
         
            let index = this.GetIndexOfItem(item);
            if(index != null)
            {
               
                this.panier_items[index].UpdateQuantity(1);
                this.$storageHandler.SavePanier([this.panier_items[index]]);
                return;
            }
            this.panier_items.push(item);
            this.$storageHandler.SavePanier([item]);
            
        },

        RemoveArticleFromPanier(panierItem)
        {
            this.storageHandler.RemovePanierItemFromStorage(panierItem.item_storage_id);
            this.panier_items.splice(this.GetIndexOfItem(panierItem), 1);
        }

    },

}




export default PanierHandler;
</script>
