export class Commande {

    constructor(id, livraison_mode, livraison_adresse,billing_adresse,payment_intent_id, status="waiting-pay", uid = null)
    {
      this.id = id;
      this.payment_intent_id = payment_intent_id;
      this.livraison_mode = livraison_mode;
      this.livraison_adresse = livraison_adresse;
      this.billing_adresse = billing_adresse
      this.command_date = 0;
      this.expected_due_date = 0;
      this.status = status;
      this.uid = uid;
      this.products = [];
      this.contacts = [];
    }
  
    exportToJson()
    {
      return {
        id : this.id,
        payment_intent_id : this.payment_intent_id,
        livraison_mode : this.livraison_mode,
        livraison_adresse : this.livraison_adresse,
        billing_adresse : this.billing_adresse,
        commande_date : this.command_date,
        expected_due_date : this.expected_due_date,
        status : this.status,
        uid : this.uid,
      }
    }
  
    addProduct(product)
    {
      this.products.push(product)
      
    }
  
    addContact(type, value, name = "")
    {
      this.contacts.push(new Contact(type, value, name));
    }
  }
  
  class Contact {
    constructor(type, value, name = "")
    {
      this.type = type;
      this.value = value;
      this.name = name;
    }
    exportToJson()
    {
      return {type : this.type, value : this.value, name : this.name}
    }
  }
  
const commandConverter = function(data, contacts = [], products = [])
{
    const c = new Commande(data.id, data.livraison_mode,data.livraison_adresse, data.billing_adresse,data.payment_intent_id, data.status, data.uid);
    c.command_date = data.commande_date;
    c.expected_due_date = data.expected_due_date;
    for(let i = 0; i < contacts.length;i++)
    {
        
        c.addContact(contacts[i].type, contacts[i].value, contacts[i].name);
    }
    for(let i = 0; i < products.length;i++)
    {
        c.addProduct(products[i])
    }
    return c;
}




export class FirebaseStorageHandler {

    constructor(storage)
    {
        this.storage = storage;
    }

   /* RegisterPolice(file,police_id, ProgressCallback)
    {
        const storageRef = this.storage.ref('Fonts/' + this.GenerateHash(police_id));
        return new Promise(function(resolve, reject) {
            let task = storageRef.put(file);
            task.on('state_changed',
            function progress(snapshot) {ProgressCallback(snapshot,0)},
            function error(err) { reject(err); },
            function complete(){resolve(task)}
            
            )
        })
        .then(function(task) {
            return task.task.snapshot.ref.getDownloadURL();
            
        })
    }*/

    ConvertToBase64(urls)
    {
        
        return new Promise((resolve) => {
            if(urls.length == 0)resolve([]);
            //let count = 0
            let arr = [];
            for(let i = 0; i < urls.length;i++)
            {
                const reader = new FileReader();
                reader.readAsDataURL(urls[i].file);
                reader.onload = ((event) =>{
                var base64 = event.target.result;
                arr.push({name : urls[i].file.name, base64 : base64});
                if(arr.length == urls.length)resolve(arr);
                
               
            })
        }
            

        })
    }

    GetFilesFromRef(path)
    {
      return new Promise((resolve, reject) => {
        var listRef = this.storage.ref(path);

        // Find all the prefixes and items.
        listRef.listAll()
          .then((res) => {
            
            let items_count = res.items.length;
            const array = [];
            res.items.forEach((itemRef) => {
                this.GetUrlFromRef(listRef.child(itemRef.name)).then((url) => {
                    array.push(url);
                    items_count --;
                    if(items_count == 0)
                    {
                        resolve(array);
                    }
                }).catch((err) => {reject(err)});

              // All the items under listRef.
            });
          }).catch((error) => {
            reject(error);
            // Uh-oh, an error occurred!
          });
      })
    }

    GetUrlFromRef(ref)
    {
        return new Promise((resolve, reject) => {
            ref.getDownloadURL().then((url) => {resolve(url)}).catch((err) => {reject(err)});
        })
    }

    GetRefFromUrl(url)
    {
        return this.storage.refFromURL(url);
    }

    GetThumbnailFromUrl(firebase_url)
    {
        const ref = this.GetRefFromUrl(firebase_url);
        const name = ref.name.split(".");
        const tb_name = name[0] + "_800x800.jpeg";
        const tb_ref = ref.parent.child("/thumbs").child(tb_name);
        return this.GetUrlFromRef(tb_ref);
    }


    GenerateHash(str)
    {
        let seed = 0;
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
            }
        h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
        h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1>>>0);
        
    }

    getRefFromUrl(url)
    {
        return this.storage.refFromURL(url);
    }

}


import {Adresse} from '../renderless/CheckoutHandler'

const adresseConverter = function(data, id)
{
    
    let a = new Adresse(data.country, data.city, data.adresse, data.zip_code, data.fullname, data.num, data.options, data.active);
    a.id = id;
    return a;
}
export class Account
{
    constructor(user = null, firestore, auth, EmailAuthProvider, functions)
    {
        this.user = user;
        this.functions = functions;
        this.logged = false;
        if(this.user != null)this.logged = true;
        this.firestore = firestore;
        this.auth = auth;
        this.name = "";
        this.surname = "";
        this.email = "";
        this.newsletter = false;
        this.saved_panier = [];
        this.addresses = [];
        this.error = "";
        this.adresses_loaded = false;
        this.commands_loaded  = false;
        this.commands = [];
        this.paymentMethods = null;
        this.payment_methods_loaded = false;
        this.EmailAuthProvider = EmailAuthProvider;
        this.GetInformations(); 
        this.GetSavedPanier();   
        this.GetAdresses();
        this.GetCommandes();
        //this.GetStripeData();   
    }

    /*GetStripeData()
    {
        if(!this.logged)return;
        const getStripeData = this.functions.httpsCallable('GetStripeCustomerPayments');
        getStripeData().then((paymentMethods) => {
           
            this.paymentMethods = paymentMethods;
            console.log(this.paymentMethods);
            this.payment_methods_loaded = true;
        }).catch((err) => {
            console.log(err);
            this.error = "";
            
            this.logged = false;
        })
    }

    async DeleteCard(card_id)
    {
        const deleteCard = this.functions.httpsCallable("deleteCard");
        return deleteCard({card_id : card_id})
    }*/
    
    GetInformations()
    {
        if(!this.logged)return;
        this.firestore.collection("Clients").doc("Accounts").collection("Users").doc(this.user.uid).get()
        .then((doc) => {
            const data = doc.data();
            this.name = data.name;
            this.surname = data.surname;
            this.email = this.user.email;
            this.newsletter = data.newsletter;
            
        }).catch(() => {
            this.logged = false;
        })
    }
    async GetProductsFromCommand(command_id)
    {
        if(!this.logged)return false;
        try {
            for(let i = 0; i < this.commands.length; i++)
            {
                if(this.commands[i].id == command_id && this.commands[i].products.length > 0)return true;
            }
            let p = []
            const q2 = await this.firestore.collection("commandes").doc(command_id.toString()).collection('products').get();
            q2.forEach((doc) => {
                p.push(doc.data())
            })
       
            for(let i = 0; i < this.commands.length; i++)
            {
                if(this.commands[i].id == command_id)
                {
                    for(let j = 0; j < p.length; j++)
                    {
                        this.commands[i].addProduct(p[j])
                      
                    }
                    return true;
                }
            }
           
            return true
        } catch (error) {
            console.log(error)
            return false;
        }
    }
    getCommande(command_id)
    {
        for(let i = 0; i < this.commands.length; i++)
        {
            if(command_id == this.commands[i].id)return this.commands[i]
        }
        return null;
    }

    async GetCommandes()
    {
        if(!this.logged)return;
        try {
        let ids = [];
        let commands = [];
        const q = await this.firestore.collection("Clients").doc("Accounts").collection("Users").doc(this.user.uid).collection("commandes").get();
        q.forEach((doc) => {
            ids.push(doc.id)
        })
        
        for(let i = 0; i < ids.length; i++)
        {
            const q2 = await this.firestore.collection("commandes").doc(ids[i].toString()).get();
            commands.push(commandConverter(q2.data()));
        }
        this.commands = commands;
        this.commands.sort((a,b) => {   
            return b.expected_due_date - a.expected_due_date
        });
        this.commands_loaded = true;
     
        }
        catch(err)
        {
            this.logged = false;
            alert(err);
        }
    }

    GetAdresses()
    {
        if(!this.logged){this.adresses_loaded = true;return;}
        this.firestore.collection("Clients").doc("Accounts").collection("Users").doc(this.user.uid).collection("adresses").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                
               
                this.addresses.push(adresseConverter(data, doc.id));
               
            })
            this.adresses_loaded = true;
        }).catch(() => {
            this.adresses_loaded = true;
            this.logged = false;
        })

    }

    GetSavedPanier()
    {
        if(!this.logged)return;
        this.firestore.collection("Clients").doc("Accounts").collection("Users").doc(this.user.uid).collection("saved-panier").get()
        .then((querySnapshot) => {
            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({id : doc.id, data : doc.data().data});
            })
            this.saved_panier = arr;
        }).catch(() => {
            
            this.logged = false;
        })
    }

    ResetAddresses()
    {
        for(let i = 0; i < this.addresses.length; i++)
        {
            this.addresses[i].active = false;
        }
    }

    AddAdresse(adresse)
    {
        this.ResetAddresses();
        this.addresses.push(adresse);
    }

    SelectAdresse(adresse_id)
    {
        this.ResetAddresses();
       
        for(let i = 0; i < this.addresses.length; i++)
        {
            if(this.addresses[i].id == adresse_id){this.addresses[i].active = true; break;}
        }
    }

    DeletePanier(index)
    {
        const id = this.saved_panier[index].id;
        return new Promise((resolve, reject) => {
            this.firestore.collection("Clients").doc("Accounts").collection("Users").doc(this.user.uid).collection("saved-panier").doc(id).delete()
            .then(() => {
                this.saved_panier.splice(index, 1);
                resolve();
            }).catch((err) => {reject(err)});
        })
    }

    RemoveAdresse(adresse_id)
    {
        let index = 0; 
        for(let i = 0; i < this.addresses.length; i++)
        {
            if(this.addresses[i].id == adresse_id){index = i; break;}
        }
        this.addresses.splice(index, 1);
    }

   


    SignOut(callback)
    {
        if(!this.logged)return callback();
        this.auth.signOut().then(() => {
            callback();
            
        }).catch((err) => {
            callback(err);
        })
    }


}

export class AccountsHandler {
    constructor(firestore, auth, functions, EmailAuthProvider)
    {
        this.firestore = firestore;
        this.auth = auth;
        this.functions = functions;
        this.EmailAuthProvider = EmailAuthProvider;
        this.pending = true;
        this.currentUser = new Account();
        this.sub_save = {
            name : "",
            surname : "",
            email : "",
            password : "",
            password2 : "",
            news : false,
            pol : false,
        }
        this.has_sub_save = false;
        this.auth.onAuthStateChanged(this.HandleAuth.bind(this));
            
    }

    SaveSub(name, surname, email, p1, p2, news, pol)
    {
        this.has_sub_save = true;
        this.sub_save.name = name;
        this.sub_save.surname = surname;
        this.sub_save.email = email;
        this.sub_save.password = p1;
        this.sub_save.password2 = p2;
        this.sub_save.news = news;
        this.sub_save.pol = pol;
    }

    GetSaveSub()
    {
        return this.sub_save;
    }

    RemoveSaveSub()
    {
        this.sub_save = {
            name : "",
            surname : "",
            email : "",
            password : "",
            password2 : "",
            news : false,
            pol : false,
        }
        this.has_sub_save = false;
    }

    DeletePanier(index)
    {
        return new Promise((resolve, reject) => {
            if(!this.currentUser.logged)reject({code :"auth/no-user"});
            this.currentUser.DeletePanier(index).then(() => {
                resolve("panier-deleted");
            }).catch((err) => {
                reject(err);
            })
        })
    }

    SaveUserPanier(panier_items, name)
    {
        return new Promise((resolve, reject) => {
            let items = []
            for(let i = 0; i < panier_items.length; i++)
            {
                
                items.push(panier_items[i].ExportToJSON());
            }
            this.firestore.collection("Clients").doc('Accounts').collection('Users').doc(this.currentUser.user.uid).collection("saved-panier").doc(name)
            .set({
                data : items
            }).then(() => {
                this.currentUser.saved_panier.push({id : name, data : items});
                resolve();
            }).catch((err) => {
                reject(err)
            })
        })
    }

    AuthError(err)
    {
        console.log(err);
    }

    HandleAuth(user)
    {
        if (user) {
            this.currentUser = new Account(user,this.firestore, this.auth, this.EmailAuthProvider, this.functions);
            // ...
          } else {
              this.currentUser = new Account();
            // User is signed out
            // ...
          }
          this.pending = false;
        
    }

    SignUserOut()
    {
        return new Promise((resolve, reject) => {
            if(!this.currentUser.logged)reject({code :"auth/no-user"});
            this.auth.signOut().then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
            })
        })
    }

    CreateAccount(name,surname, email,password, newsletter)
    {
        return new Promise((resolve, reject) => {
            this.auth.createUserWithEmailAndPassword(email, password).then((userCred) => {
                this.firestore.collection('Clients').doc("Accounts").collection("Users").doc(userCred.user.uid)
                .set({
                    name : name,
                    surname : surname,
                    newsletter : newsletter,
                    stripe_id : null,
                }).then(() => {
                    const CreateStripeAccount = this.functions.httpsCallable("CreateStripeAccount");
                    CreateStripeAccount({name : name + " " + surname}).then((result) => {
                        if(result.data.code == "Succès"){
                            const id = result.data.message;
                            this.firestore.collection('Clients').doc("Accounts").collection("Users").doc(userCred.user.uid).update({stripe_id : id}).then(() => {
                                resolve();
                            }).catch((err) => {
                                userCred.user.delete();reject(err);
                            })
                            }
                        else {
                            userCred.user.delete();reject(result.data);
                        }
                    })
                    
                }).catch((err) => {userCred.user.delete();reject(err)})
               
            }).catch((err) => {
                reject(err);
            })
        })
    }

    ChangeUserEmail(password, new_email)
    {
        
        return new Promise((resolve, reject) => {
            if(!this.currentUser.logged){reject({code :"auth/no-user"});return}
            this.ReauthentificateUser(this.currentUser.user.email, password).then(() => {

                this.currentUser.user.updateEmail(new_email).then(() => {
                    this.currentUser.email = new_email;
                    resolve("mail-updated");
                }).catch((err) => {reject(err)})

            }).catch((err) => reject(err))

        })
    }

    ChangeUserPassword(old_password, new_password)
    {
        return new Promise((resolve, reject) => {
            if(!this.currentUser.logged){reject({code :"auth/no-user"});return}
            this.ReauthentificateUser(this.currentUser.user.email, old_password).then(() => {

                this.currentUser.user.updatePassword(new_password).then(() => {
                    resolve("password-updated");
                }).catch((err) => reject(err))

            }).catch((err) => {reject(err)});
        })
    }


    ReauthentificateUser(email, password)
    {
        
        const credentials = this.EmailAuthProvider.credential(
            email, 
            password
        );  
        
        return this.currentUser.user.reauthenticateWithCredential(credentials);
    }

    HandleUserNewsletter(status)
    {
        return new Promise((resolve, reject) => {
        if(!this.currentUser.logged){reject({code :"auth/no-user"});return}
            this.firestore.collection("Clients").doc('Accounts').collection('Users').doc(this.currentUser.user.uid).update({
                newsletter : status
            }).then(() => {
                this.currentUser.newsletter = status;
                resolve("newsletter-updated")
            }).catch((err) => {reject(err)})
        })
    }


    AddEmailToNewsLetter(email)
    {
        return new Promise((resolve, reject) => {
            const saveMail = this.functions.httpsCallable('saveEmail');
            saveMail({email : email}).then((result)=> {
                if(result.data == "exist")reject("exist");
                else if(result.data == "ok")resolve();
                else reject("undefined");
            } ).catch(() => {reject()})
        })
    }

    RemoveEmailFromNewsLetter(email)
    {
        return new Promise((resolve, reject) => {
            const removeEmail = this.functions.httpsCallable('removeEmail');
            removeEmail({email : email}).then((result)=> {
                if(result.data == "ok")resolve();
                else reject(result.data);
            }).catch((err) => {reject(err)})
        })
    }

    SelectAdresse(adresse_id)
    {
        return new Promise((resolve, reject) => {
            if(!this.currentUser.logged){reject({code :"auth/no-user"});return}
            this.firestore.collection("Clients/Accounts/Users").doc(this.currentUser.user.uid).collection("adresses").doc(adresse_id).update({active : true}).then(() => {
                this.currentUser.SelectAdresse(adresse_id);
                resolve("addresse-selected")}).catch((err) => {reject(err)})
        })
    }

    AddLocalAdresse(adresse, active)
    {
        adresse['active'] = active;
        this.currentUser.AddAdresse(adresse);
    }

    AddAdresse(adresse)
    {
        adresse.active = true;
        const json_adresse = adresse.ExportToJSON();
        
      
        return new Promise((resolve, reject) => {
            if(!this.currentUser.logged){reject({code :"auth/no-user"});return}
            this.firestore.collection("Clients/Accounts/Users").doc(this.currentUser.user.uid).collection("adresses").doc(adresse.id).set(json_adresse).then(() => {
                this.currentUser.AddAdresse(adresse);
                resolve("addresse-added")}).catch((err) => {reject(err)})
        })
    }

    RemoveAdresse(adresse_id)
    {
        return new Promise((resolve, reject) => {
            if(!this.currentUser.logged){reject({code :"auth/no-user"});return}
            this.firestore.collection("Clients/Accounts/Users").doc(this.currentUser.user.uid).collection("adresses").doc(adresse_id).delete().then(() => {
                
                this.currentUser.RemoveAdresse(adresse_id);
                resolve("addresse-deleted");
            }).catch((err) => {
                reject(err);
            })
        })
    }

    ResetPassword(email)
    {
        return this.auth.sendPasswordResetEmail(email)
    }

    SignIn(email, password)
    {
        return new Promise((resolve, reject) => {
            this.auth.signInWithEmailAndPassword(email, password).then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
            })
        })
    }
}