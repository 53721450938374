<template>
    
       <div v-if="show_modal" class="paymodal-background">
            <div class="paymodal-container">
            <div class="paymodal-title" :class="{'error-bg' : modal_error}">{{modal_title}}</div>
            <div v-html="modal_content" class="paymodal-content"></div>
            <button class="paymodal-button"  :class="{'error-bg' : modal_error}" @click="HideModal">C'est noté</button>
        </div>
       </div>
       
</template>

<script>
export default {
    props:{
         modal_title : String,
        modal_content :String,
        show_modal : Boolean,
        modal_error : Boolean,
    },
    methods:{
        HideModal()
        {
            this.$emit("close");
        }
    }

    
   
}
</script>

<style scoped>


.paymodal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
    z-index : 30;
}

.paymodal-button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.paymodal-button:hover {
  filter: contrast(115%);
}

.paymodal-content {
    padding: 1rem;
    font-family : 'Montserrat', sans-serif;
    color: white;
    max-width : 500px;
    min-height: 100px;
    display: block;
    align-items: center;
    justify-content: center;
}

.paymodal-container {
    background-color: #003049;
    border-radius : 4px;
    overflow: hidden;
    min-width : 310px;
}

.error-bg {
    background-color: #D62828!important;
}



.paymodal-title {
    background-color: #27AE60;
    padding: 0.5rem 1rem;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size : 1.5rem;
}

.paymodal-button {
    background-color: #27AE60;
    color : white;
    margin-left: auto;
    width: auto;
    margin-right : 1rem;
    margin-bottom : 1rem;
    border-radius : 4px;
}
</style>