<template>
    <div @click="HandleFocus(false)" ref="shopContainer" class="shop-container">
        <div class="shop-top-container">
            <div class="shop-search">
                <div  class="search-bar"><input @keyup.enter="ApplySearch(searchHandler.search_value)" @keyup="HandleSearchValue" @click="HandleFocus(true)"   v-model="searchHandler.search_value" type="text" name="" id="" placeholder="Que recherchez-vous ?">
                <div class="shop-search-ok"><img @click="ApplySearch(searchHandler.search_value)" class="scaleanim" src="../assets/imgs/search.svg" alt="" srcset=""></div></div>
                <div v-show="search_focus" class="hits">
                    <div @click="HandleSearch(hit)" class="hit-item" v-for="(hit, index) in searchHandler.hits" :key="index">
                        <span><b>{{hit.property}} </b>: {{hit.value}}</span>
                    </div>
                </div>
            </div>
            <div class="shop-critere">
                <span>Vos critères :</span>
                <TagButton v-for="(tag, index) in searchHandler.current_tags" :key="index" :text="tag" @removetag="RemoveTag(tag)"/>
                
            </div>
        </div>

        <div class="shop-products-container">
            <div class="shop-order-container">
                <span>Trier par :</span>
                <select @change="HandleSortMode" v-model="sorting_type" name="" id="" style="height:48px" class="gray-select">
                            <option value="2">Néon Bloom présente</option>
                            <option value="0">Prix croissant</option>
                            <option value="1">Prix décroissant</option>
                            
            </select>
            </div>
        
        <div ref="products" class="shop-products">
            
            <span class="loading-span" v-if="!articles_loaded">Chargement de la boutique</span>
            <span class="no-article-span" v-if="NoResult">Aucun résultat</span>
            
        

            <div v-show="articles_loaded"   v-for="article in Articles" :key="article.article_id">
               
                <shop-item  :article="article" :observer="images_observer" />
                
            </div>
           
            
        </div>
        
         <div class="pagination">
                <pagination :current_page="searchHandler.page" :page_count="searchHandler.page_count" @pagechanged="ChangePage" />
            </div>
    </div>
    </div>
</template>


<script>
import {TagButton} from '../components/buttons'
import ShopItem from '../components/ShopItem.vue'
import Pagination from '../components/Pagination.vue';
export default {
    name: 'Shop',
    components: {
        TagButton,
        ShopItem,
        Pagination,
    },

      metaInfo : {
        title : 'Boutique',
        meta : [
            {name:"description" , content : "Un animal ? Un arc en ciel ? Chez nous les néons made in france, il y en a pour tout les goûts"},
            { property: 'og:title', content: 'Boutique'},
                { property: 'og:site_name', content: 'Neon Bloom Paris'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'},
        ]
    },
    data() {
        return {
            articlesHandler : this.$articlesHandler,
            sorting_type : 2,
            images_observer : null,
            articles_loaded : false,
            searchHandler : this.$searchHandler,
            search_focus : false,
        current_page : this.$searchHandler.page,
        search_value : "",
        page_count : 10,
        number_of_article : 9,
        }
    },

    computed: {

        NoResult()
        {
            return(this.articles_loaded && this.searchHandler.current_articles.length == 0);
        },

        Articles()
        {
            return this.searchHandler.current_articles
        }
        


      
    },

    watch : {
        
    },

    methods: {

    HandleFocus(value)
    {  
        event.stopPropagation();
        this.search_focus = value;
    },

    HandleSortMode()
    {
        this.searchHandler.sort_mode = this.sorting_type;
        this.ApplySearch(this.searchHandler.search_value);
    },

    HandleSearchValue(event)
    {
        this.searchHandler.search_value = event.target.value;
         this.search_focus = true;
        if(this.searchHandler.search_value.length < 3 || !this.articles_loaded){this.searchHandler.ClearHits();return;}
    this.searchHandler.HitSearch(this.searchHandler.search_value).then((result) => {
        this.searchHandler.CreatesHits(result)})
    },


    HandleSearch(hit)
    {
        event.stopPropagation();
       
        const value = hit.value;
        if(hit.property == "Article")
        {
            this.articles_loaded = false;
            this.searchHandler.search_value = value;
            this.ApplySearch(this.searchHandler.search_value, true);
        }
        else if(hit.property == "Tag")
        {
            this.searchHandler.AddTagToFilter(hit.value);
            this.searchHandler.search_value = "";
            this.ApplySearch("*", true);
        }
    },

    ApplySearch(value, change_page = false)
    {
        if(value == "")value = "*";
        this.articles_loaded = false;
        if(change_page)this.searchHandler.page = 1;
        this.searchHandler.ApplySearch(value).then(() => {
            
            this.articles_loaded = true}
            ).catch((err) => {console.log(err)});
    },

    RemoveTag(tag)
    {
        this.searchHandler.RemoveTagFromFilter(tag);
        this.searchHandler.search_value = "";
        this.ApplySearch(this.searchHandler.search_value, true);
    },

    GoTo(path)
    {
        this.$router.push(path);
    },

    ChangePage(page)
    {
        window.scrollTo(0,0);
        this.searchHandler.page = page;
        this.ApplySearch(this.searchHandler.search_value, false)
        //BUG RETOUR BOUTIQUE
    },


    HandleLazyLoad(entries)
    {
        const instance = this;
        entries.forEach(function(entry) {
            
            if (entry.isIntersecting) {

            var image = entry.target;
            image.src = image.dataset.src;
            image.classList.remove("lazy");
            instance.images_observer.unobserve(image);
            }
        });
    },

    ArticlesLoaded(err = null)
    {
        if(err === null)
        {
            
            this.articles_loaded = true;
            /*this.searchHandler.page = 1;
            this.ApplySearch("*");*/
        }
    },

    },

    created() {
        if ("IntersectionObserver" in window)
        {
            this.images_observer = new IntersectionObserver(this.HandleLazyLoad);
        }
        if(this.searchHandler.current_articles.length == 0)this.ApplySearch(this.searchHandler.search_value, true);
        else this.articles_loaded = true;
        
         
    },
    beforeDestroy()
    {
        if(this.images_observer)
        {
                    this.images_observer.disconnect();

        }
    },

    mounted() {
        window.scrollTo(0,0);

        
        
        /*let colorArray = ["#fcd1d1", "#ece2e1","#d3e0dc","#aee1e1","#cee6b4","#fbe6c2"]
        let i = Math.floor(Math.random()* 6)
        this.$refs.shopContainer.style.backgroundColor = colorArray[i];*/


    }
}
</script>

<style scoped>

.pagination {
    width: 100%;
    display: flex;
    align-items : center;
    justify-content: center;
}


.loading-span:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}
@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #2c3e50;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 #2c3e50;}}


.loading-span {
    width : 100%;
    height : 100%;
    display : flex;
    position: absolute;
    top: 0;
    left:0;
    justify-content : center;
    align-items : center;
    text-align: center;
    font-size : 1.2rem;
    font-weight: bold;
}

.no-article-span {
        width : 100%;
    height : 100%;
    display : flex;
    position: absolute;
    top: 0;
    left:0;
    justify-content : center;
    align-items : center;
    text-align: center;
    font-size : 1.2rem;
    font-weight: bold;
}

@media(max-width: 690px)
{
 .shop-order-container {
     align-self: center!important;
 }
 
 .shop-critere > span {
     display : none!important;
 }

    .shop-critere {
        justify-content : center;
        flex-wrap : wrap;
    }



 .shop-search {
     width : 290px!important;
 }
}


@media(max-width: 1000px)
{
    .shop-products {
        grid-template-columns: 50% 50%!important;
    }
}
@media(max-width: 690px)
{
    .shop-products {
        
        display : flex!important;
        justify-content : center;
        align-items: center;
        width : 100%;
        flex-wrap: wrap;
    }

    .shop-order-container {
        margin-right: 0!important;
    }
}

.gray-select {
    background-color : #C6C6C6;
  
    border: none;
    color:black;
    outline : none;
    padding : 0.75rem 1rem;
}







.shop-products {
    display: grid;
    min-height: 500px;
    grid-template-columns : 33.33% 33.33% 33.33%;
    margin-top: 1rem;
    position : relative
    
}

.shop-products > div {
    display: flex;
    width : 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.shop-wrapper:last-child {
    visibility: hidden;
    flex-grow: 0;
}

.shop-order-container {
    align-self: flex-end;
    margin-right : 40px;
}

.shop-order-container > span {
    margin-right: 0.25rem;
}

.shop-order-container > select {
    outline: none;
    padding: 0.5rem;
}

.shop-products-container {
    width: 90%;
    display: flex;
    margin-top: 2rem;
    max-width: 970px;
    flex-direction: column;
}

.shop-critere {
    margin-top : 1rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.shop-critere > span {
    margin-right: 1rem;
}

.shop-top-container {
   
    max-width: 700px;
    display: flex;
    flex-direction : column;
    align-items: center;
    justify-content: center;
}

.shop-container {

    background-color: var(--white-break);
    font-family : 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
    margin-top : 5rem;
    width : 100%;
    padding: 2rem 1rem;
}

.shop-search {
    background-color  :var(--white-break);
    display: inline-block;
    position: relative;
    flex-direction : row;
    justify-content: center;
    align-items: center;
}

.shop-search-logo {
    background-color: var(--amaranth);
    width : 70px;
    height: 100%;
    margin-left: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-search-logo > img {
    filter :invert();
}
.search-bar {
    display: flex;
    height: 50px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}
.search-bar > input {
    width:50vw;
    min-width: 240px;
    max-width:615px;
    padding : 0.25rem 0.75rem;
    background-color: white;
    border: none;
    outline : none;
    height: 100%;
    margin-top: auto;
    align-self: baseline;
}
.shop-search-ok > img {
    filter: invert();
    width : 25px;
    height:25px;
    cursor: pointer;
}

.shop-search-ok {
    background-color: black;
    
    color: white;
    height: 100%;
    width:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'DIN', sans-serif;
}

.hits {
    position: absolute;
    z-index: 10;
    width : 100%;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow-y: auto;
}

.hits::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.hits::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.hits::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.hits::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.hit-item {
    background-color : white;
    width : 100%;
    cursor: pointer;
    text-align: left;
    padding : 0.75rem;
    transition-duration: 0.2s;
}

.hit-item:hover {
     background-color: #E1F6FF;
}

</style>