<template>
    <div class="installation-container">
        <img src="../assets/imgs/sceno3.jpg" style="max-height:30vw" alt="" srcset="">
        <h4 class="ask-title mt-3 d-flex">Création <h4 class="ask-title ml-2" style="color:var(--amaranth)">graphique</h4></h4>
        <p class="text-container mb-0 pb-0">
            Nous pouvons transposer la plupart des logos en néon LED sans difficulté. Il arrive parfois que vos graphismes nécessitent d’être légèrement ajustés au médium. Nous proposons des prestations de création graphique pour soit, adapter des designs existants, soit créer de toutes pièces, selon vos envies, de nouveaux designs à votre image. 
<br><br>Riches d’une longue expérience en création graphique et communication visuelle, nous pouvons : <br><br></p>
<p class="text-container pt-0" style="text-align:justify">
    • Faire concorder votre logo, votre charte graphique avec les possibilités offertes par la flexled<br><br>
• Vous accompagner lors du développement de votre charte graphique, de votre signalétique et de l’agencement de vos espaces (conception et réalisation multimodale : néons LED, impressions sur tous supports etc...)
<br><br>• Dessiner pour vous une enseigne, un logo, un luminaire à votre image

        </p>

        <div class="pro-item-ask">
                   <h5 class="ask-title">ça vous tente ?</h5>

                   <span style="letter-spacing:-0.03rem">Discutons ensemble de votre projet</span>

                   <button class="article-panier-button" @click="GoTo('/Contact')">Contactez nous</button>
                </div>
    </div>
</template>

<script>
export default {
    
    methods : {
        GoTo(path)
        {
            this.$router.push(path);
        }
    },
    mounted () {
        window.scrollTo(0,0);
    }

}
</script>

<style scoped>
.installation-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top : 5rem;
    align-items: center;
    font-family : 'Montserrat', sans-serif;
}
.pro-item-ask {
    background-color: white;
    width: 100%;
    padding : 2rem 0;
    display: flex;
    flex-direction: column;
    background: var(--white-break);
    align-items: center;
}

.article-panier-button {
    color : white;
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color : var(--amaranth);
    border : 1px solid var(--amaranth);
    text-transform : uppercase;
    transition-duration: 0.2s;
    padding : 0.5rem 0.75rem;
    
    margin-top: 1rem;
}

.article-panier-button:hover {
    background-color: white;
    color : var(--amaranth);

}

.ask-title {
    font-family : 'DIN', sans-serif;
    text-transform : uppercase;
    font-size : 1.75rem;
    margin-bottom: 0.25rem;
    white-space: nowrap;
}

.title {
    font-weight : bold;
     font-family: 'DIN', sans-serif;
     text-transform: uppercase;
    margin : 0;
    color : #2a2a2a;
    display: flex;
}

@media(max-width:500px)
{
    .title {
        font-size: 2rem!important;
    }
}

.installation-container > img {
    width: 100%;
    min-height: 400px;
    height: auto;
    object-fit: cover;
    background-color: beige;
}
@media (max-width:725px)

{
    img {
        object-fit : contain;
        min-height : 0!important;
        max-height : 100vh!important;
        height:auto!important;
    }
}
.text-container {
    width: 100%;
    padding : 1rem;
    max-width : 1200px;
    text-align: justify;
    color : #2a2a2a;
}
</style>