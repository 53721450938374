export default class SearchHandler {
  constructor(articlesHandler) {
    this.articlesHandler = articlesHandler;
    const Typesense = require("typesense");
    this.client = new Typesense.Client({
      nodes: [
        {
          host: "jdwo1if89m0g4lnyp-1.a1.typesense.net", // where xxx is the ClusterID of your Typesense Cloud cluster
          port: "443",
          protocol: "https",
        },
      ],
      apiKey: "xoJ2d73Rh15aI6BqTGPM3s3eSooliyU4",
      connectionTimeoutSeconds: 2,
    });
    this.hits = [];
    this.current_tags = [];
    this.current_articles = [];
    this.page = 1;
    this.article_per_page = 18;
    this.page_count = 1;
    this.sort_mode = 2;
    this.search_value = "";
  }

  async GetAccueilTendance() {
    const searchParameters = {
      q: "*",
      query_by: "article_tags",
      query_by_weights: "1",
      sort_by: "article_priority:asc",
      exclude_fields: "article_description",
      drop_tokens_threshold: "2",
      per_page: 4,
      page: 1,
      num_typos: 2,
      filter_by: "article_tags:Tendance",
    };
    const result = await this.client
      .collections("article_new")
      .documents()
      .search(searchParameters);
    return await this.GetArticlesFromSearch(result);
  }

  Search(word) {
    if (word == "") word = "*";
    return new Promise((resolve, reject) => {
      let filter_by_value = "";
      let sort_value = "article_min_price:asc";
      if (this.sort_mode == 1) sort_value = "article_min_price:desc";
      if (this.sort_mode == 2) sort_value = "article_priority:asc";
      if (this.current_tags.length != 0) {
        for (let i = 0; i < this.current_tags.length; i++) {
          filter_by_value += "article_tags:" + this.current_tags[i];
          if (i != this.current_tags.length - 1) filter_by_value += " && ";
        }
      }

      console.log(this.page);

      const searchParameters = {
        q: word,
        query_by: "article_name,article_tags",
        query_by_weights: "3,1",
        sort_by: sort_value,
        exclude_fields: "article_description",
        drop_tokens_threshold: "2",
        per_page: this.article_per_page,
        page: this.page,
        num_typos: 2,
        filter_by: filter_by_value,
      };
      this.client
        .collections("article_new")
        .documents()
        .search(searchParameters)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
      // ...
    });
  }
  HitSearch(word) {
    if (word == "") word = "*";
    return new Promise((resolve, reject) => {
      let filter_by_value = "";
      let sort_value = "article_min_price:asc";
      if (this.sort_mode == 1) sort_value = "article_min_price:desc";
      if (this.sort_mode == 2) sort_value = "article_priority:asc";
      if (this.current_tags.length != 0) {
        for (let i = 0; i < this.current_tags.length; i++) {
          filter_by_value += "article_tags:" + this.current_tags[i];
          if (i != this.current_tags.length - 1) filter_by_value += " && ";
        }
      }

      const searchParameters = {
        q: word,
        query_by: "article_name,article_tags",
        query_by_weights: "3,1",
        sort_by: sort_value,
        exclude_fields: "article_description",
        drop_tokens_threshold: "2",
        per_page: 8,
        num_typos: 2,
        filter_by: filter_by_value,
      };
      this.client
        .collections("article_new")
        .documents()
        .search(searchParameters)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
      // ...
    });
  }

  CreatesHits(result_search) {
    this.HandleHits(result_search.hits);
  }

  IncludesHit(tab, hit) {
    for (let i = 0; i < tab.length; i++) {
      if (tab[i].property == hit.property && tab[i].value == hit.value)
        return true;
    }
    return false;
  }

  HandleHits(hits) {
    let local_hits = [];
    for (let i = 0; i < hits.length; i++) {
      const hit = hits[i];
      const hint = { property: "Article", value: hit.document.article_name };
      if (!this.IncludesHit(local_hits, hint)) local_hits.push(hint);

      for (let j = 0; j < hit.highlights.length; j++) {
        const hightlight = hit.highlights[j];
        if (hightlight.field == "article_tags") {
          for (let k = 0; k < hightlight.matched_tokens.length; k++) {
            const token = hightlight.matched_tokens[k];
            const h = { property: "Tag", value: token[0] };

            if (!this.IncludesHit(local_hits, h)) local_hits.push(h);
          }
        }
      }
    }

    this.hits = local_hits;
  }

  ClearHits() {
    this.hits = [];
  }

  ExtractArticleIds(result) {
    let ids = [];
    for (let i = 0; i < result.hits.length; i++) {
      ids.push(result.hits[i].document.id);
    }
    return ids;
  }

  GetArticlesFromSearch(result) {
    return this.articlesHandler.GetArticlesFromId(
      this.ExtractArticleIds(result)
    );
  }

  AddTagToFilter(tag) {
    if (this.current_tags.indexOf(tag) == -1) this.current_tags.push(tag);
  }
  ClearTags() {
    this.current_tags = [];
  }
  RemoveTagFromFilter(tag) {
    let i = this.current_tags.indexOf(tag);
    this.current_tags.splice(i, 1);
  }

  SetPage(value) {
    this.page = value;
  }

  ApplySearch(value) {
    this.ClearHits();

    return new Promise((resolve, reject) => {
      this.Search(value).then((result) => {
        this.page_count = Math.ceil(result.found / this.article_per_page);
        this.GetArticlesFromSearch(result)
          .then((articles) => {
            this.current_articles = articles;

            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }
}
