
export class State {
    constructor(neon_id, propertyType, property)
    {
        this.neon_id = neon_id;
        this.propertyType = propertyType;
        this.property = property;
    }


}

export class History {
    constructor(neonHandler)
    {
        this.neonHandler = neonHandler;
        this.Stories = new Map();
        this.tmp_index = 0;
        this.revert_mode = 0;

    }

    CreateHistory(neon_id)
    {
        this.Stories.set(neon_id, []);
    }

    Save(neon_id, propertyType, property)
    {
        
        this.Stories.get(neon_id).push(new State(neon_id, propertyType, property));
    }
    Revert(neon_id)
    {
        if(!this.Stories.get(neon_id))return;
        this.revert_mode = true;
        let state = this.Stories.get(neon_id).pop();
        switch (state.propertyType)
        {
            case PropertyType.color : {
              
                this.neonHandler.SetColor(state.property);
                break;
            }
            case PropertyType.font : {

                this.neonHandler.SetFont(parseInt(state.property));
                break;
            }
            case PropertyType.dimension : {
                this.neonHandler.SetDimension(state.property);
                break;
            }
            case PropertyType.support_color : {
                this.neonHandler.SetSupportColor(state.property);
                break;
            }
            case PropertyType.support : {
                this.neonHandler.SetSupport(state.property);
                break;
            }
            case PropertyType.alignment : {
                this.neonHandler.SetAlignment(state.property);
                break;
            }
        }
        this.revert_mode = false;
    }
    DeleteHistory(neon_id){
    this.Stories.delete(neon_id);
    }
    EnterTmpMode(neon_id)
    {
        this.tmp_index = this.Stories.get(neon_id).length -1;
    }
    QuitTmpMode(neon_id, validate)
    {
        if(!validate)
        {
            
            for(let i = this.Stories.get(neon_id).length -1; i > this.tmp_index; i--)
            {
                this.Revert(neon_id);
            }
        }
        this.tmp_index = 0;
    }
}


export const PropertyType = {font : 0, color : 1, dimension : 2, support_color : 3, support : 4, alignment : 5}