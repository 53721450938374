<script>

export const SimButton1 = {
    name : "SimButton1",
    render() {
        return(
            <div  onclick={this.OnClick} data-id={this.dataId} class={"button button-active " + this.Class}>{this.text}</div>)
    },

    props: {
        text : String,
        callBack : Function,
    },
    data()
    {
      return {
        active :false,
      }
    },
    computed : {
      Class()
      {
        if (this.active)return 'slide-diagonal-persistent';
        return'';
      }
    },

    methods: {
        
        OnClick()
        {
           this.active = !this.active;
            this.callBack(this.active, this.text);
        },
        SetValue(val)
        {
          this.active = val;
        }

        
    
    }
}
export const TagButton = {
    name : "SimButton1",
    render() {
        return(
            <div style={'background-color:' + this.c}  data-id={this.dataId} class="tag-button ">{this.text} <img onclick={this.Click} width="12" height="12" class="scaleanim" style="margin-left:0.5rem" src={require("../assets/imgs/cross.png")} /> </div>)
    },

    props: {
        text : String,
        dataId : null,
        persistant : false,
    },
    data(){
      return {
        c : '#CBCBCB',
        }
    },

    methods: {
        
        Click()
        {
          this.$emit("removetag")
        }
    
    }, 
}
export const SimButtonCTA = {
  
    render() {
        return(
            <div onclick={this.Clicked} class="button_slide slide_right" style={this.handleStyle}>{this.text}</div>
)
    },

    computed : {

      handleStyle()
      {
        let s = "white-space:nowrap;";
        if(this.rounded)s +='border-radius:60px;';
        if(this.disabled)
        {
          s+= 'background-color:gray;color:lightgray;pointer-events:none;border:none;'
          return s;
        }
        if(this.plain)s+='background-color:var(--amaranth);color:white;padding:11px 2rem;';
        return s;
      }

    },
    methods : {
      
      Clicked(event)
      {
        if(!this.callBack)return;
        this.callBack(event);
      }
    },

    props: {
        text : String,
        disabled : {type : Boolean, default : false},
        plain : {type : Boolean, default : false},
        dataset : {},
        href : String,
        callBack : Function,
        rounded : {type : Boolean, default : false},
    }
}

export const RangeSlider = {
  render()
  {
    return (
      <div class="slidecontainer">
        <input type="range" oninput={this.ValueChanged} min={this.min} max={this.max} value="{this.value}" class="slider-1" id="myRange"/>
    </div>
    )
  },
  props: {
    min : {type : Number, default : 0},
    max : {type : Number, default : 100},
    
  },
  data()
  {
    return {
      value : 50,
    }
  },
  methods : {
    ValueChanged(event)
    {
      this.value = event.target.value;
      this.$emit('valuechanged', this.value);
    }
  }
  
}
export const DotFalling = {
  render()
  {
    return (
      <div class="dot-pulse"></div>
    )
  },

  
}

export const SimButtonBlack = {
  
    render() {
        return(
            <a href={this.href} onclick={this.Clicked} ><div class="button-slide-black" style={this.handleStyle}>{this.text}<img src={this.urlImg} width="25" height="25" /></div></a>
)
    },

    computed : {

      handleStyle()
      {
        let s = "";
        if(this.rounded)s +='border-radius:60px;';
  
        
        return s;
      }

    },
    methods: {
      
      Clicked(event)
      {
        if(!this.callBack)return;
        this.callBack(event);
      }
    },

    props: {
        text : String,
        dataset : {},
        callBack : Function,
        href : String,
        urlImg : String,
        rounded : {type : Boolean, default : false},
    }
}

export const CheckBox = {
    name: 'CheckBox',
    props: {
        callBack : Function,

        
    },
    watch : {
      'neonHandler.neon' : 'UpdateValue'
    },
    data()
    {
        return{
            checked : false,
        }
    },
    methods: {

      Click(event)
      {
        if(this.callBack)this.callBack(event.target.checked);
        /*event.stopPropagation();
        this.value = event.target.value;
        if(this.callBack)this.callBack();*/
      },
      SetValue(val)
      {
        this.$refs.input.checked = val;
      }
    },

    render()
    {
        return(
            <div style="width:30px;height:30px"> 
            <div  class="cbx">
            <input ref="input" onChange={this.Click} type="checkbox"/>
            <label for="cbx"></label>
            <svg width="15" height="14" viewbox="0 0 15 14" fill="none"><path d="M2 8.36364L6.23077 12L13 2"></path></svg>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1"><defs><filter id="goo"><fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur><fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo"></fecolormatrix><feblend in="SourceGraphic" in2="goo"></feblend></filter></defs></svg>
            
            
            </div>
        )
    },
    
}



export const SimDropdown = {

    props : {
        options : Array,
        callBack : Function,
        
    },

    data () {
        return {
            selected : 0,
        }
    },

    

  

    render() {

        return (
            <div  ref="main" style={"padding-left:1rem;"} class="drop-container d-flex">
            <span ref="selected">{this.options[this.selected]}</span>
            <img src={require("../assets/imgs/arrow.svg")} alt="" width="24px" class="drop-img" />
            <div class="drop-select" ref="select" onClick={this.OnSelect}>
            {this.options.map((item, index) => {
              return(
                <div data-index={index}>{item}</div>
              )
            })}
            </div>
            
            
           
            
            </div>
            
            
            )

    },

    methods : {
        OnSelect(event)
        {
          if(!event.target.dataset.index)return;
          this.selected=parseInt(event.target.dataset.index);
          this.callBack(this.selected);
        },
        SetValue(index)
        {
          this.selected = index;
        }

      
        
    },

}

export const RoundButton = {

  props: {
    color : String,
    index : Number,
    neonHandler : null,
    callBack : Function, 
  },
  data()
  {
    return {
      hover : false,
    }
  },
  computed : {
    Style()
    {
      if(!this.neonHandler.neon)return"";
      if(!this.neonHandler.DimensionAvailable(this.index))return"background-color:lightgray;pointer-events:none;"
      if (this.hover || this.neonHandler.neon.dimension_id == this.index){
        let s = "background-color:" + this.color+";";
        let l = Math.HexToRgb(this.color);
        if (l < 120) {
          s+="color:white;";
        }
        return(s)
        
        }
      return "";
    },

    Text()
    {
    
      if(this.neonHandler.neon == null || this.neonHandler.calculateur.polices == null)return "";
      if(!this.neonHandler.DimensionAvailable(this.index))return "X"
      let value = parseInt(this.neonHandler.calculateur.polices[this.neonHandler.neon.font_id].props.sizes[this.index].cm)

      const top  = value+2
      return top + "cm"
    }
  },

  render()
  {
    return(<div  onClick={this.Click} class="button-round scaleanim" style={"font-size:14px;font-weight:bold;border: 2px solid " + this.color + ";"+ this.Style} data-index={this.index} >{this.Text}</div>)
  },

  methods : {
    Enter()
    {
      this.hover = true;
    },
    Leave()
    {
      
      this.hover = false;
    },

    Click(event)
    {
      this.callBack(event);
    },
    SetValue(val)
    {
      this.selected = val;
    }
  },
  mounted()
  {
    this.$el.addEventListener('mouseenter', this.Enter); 
    this.$el.addEventListener('mouseleave', this.Leave);
  }


}




export default SimButton1;
</script>

<style>

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}


.slidecontainer {
  pointer-events: all;
  width: 100%; /* Width of the outside container */
}

.slider-1 {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-1::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #04AA6D;
  cursor: pointer;
}

.slider-1::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

/* Mouse-over effects */
.slider-1:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.tag-button {
  background-color: rgba(0,0,0,0.2);
  padding : 0.5rem;
  margin: 0.5rem 0.5rem;
  
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center;
  
 
}

.tag-button-croix {
  width: 15px;
  height: 15px;
  margin-left: 0.5rem;
  background-position: center;
  background-image: url('../assets/imgs/cross.png');
}

.button-round {
  cursor: pointer;
    padding: 0.5rem 0;
    margin : 0.75rem auto;
    width: 90px;
    height:90px;
    box-shadow: 3px 3px 2px 0px rgba(133, 103, 103, 0.42);
    pointer-events: all;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.2s;
}

.button_slide {
    color: var(--amaranth);
    border: 2px solid var(--amaranth);
    border-radius: 0px;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    padding: 11px 18px;
    display: inline-block;
    font-weight:500;
   
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 var(--amaranth);
    transition: ease-out 0.4s;
}



.button-slide-black {
    color: var(--white-break);
    border: 2px solid #090909;
    border-radius: 0px;
    padding: 11px 11px;
    display: inline-block;
    font-family: 'Montserrat';
    font-weight:500;
    font-size: 14px;
    background-color: #090909;
    letter-spacing: 1px;
    cursor: pointer;
    transition: ease-out 0.4s;
}

.button-slide-black:hover {
  filter :invert(1);
  border: 2px solid white;
}

.button_slide > a {
  color:unset!important
}
.button_slide > a:hover {
  text-decoration:none!important
}

.slide_right:hover {
  box-shadow: inset 400px 0 0 0 var(--amaranth)!important;
  color:white!important;
}

.slide_diagonal:hover {
  box-shadow: inset 400px 50px 0 0 var(--amaranth);
  color:white
}

.slide-diagonal-persistent {
    box-shadow: inset 400px 50px 0 0  #006BFC!important;
    color:white!important;
}

.button {
    background: #E0EDFF;
    border: 2px solid #006BFC;
    border-radius: 4px;
    color: #006BFC;
    padding: 6px 18px;
    text-align: center;
    margin : 0.25rem;
    text-decoration: none;
    display: inline-block;
    user-select: none;
    -webkit-user-select: none;
    font-size: 16px;
    transition-duration: 0.4s;
}

.drop-img {
    transform:rotateZ(90deg);
}

.drop-container {
    border-radius: 30px;
    justify-content: center;
    min-width : 150px;
    border: 1px solid rgba(0,0,0,0.3);
    position:relative;
    pointer-events: all!important;
    cursor: pointer;
}

.drop-select {
    padding: 10px 2px;
    border-radius: 10px;
    background-color:snow;
    top : 1.6rem;
    
    display: none;    
    position:absolute;
    width : 100%;
    background-color:white;
    left :0;
    z-index: 1;
    box-shadow: 0 1px 1px rgba(0,0,0,0.08), 
                0 2px 2px rgba(0,0,0,0.12), 
                0 4px 4px rgba(0,0,0,0.16), 
                0 8px 8px rgba(0,0,0,0.20);

}

.drop-select > div:hover {
    background-color :  #E0EDFF!important;
}

.drop-container:hover .drop-select{
    display:block!important;
}
.button-active {
    box-shadow: inset -2px 0px 3px #EEF5FF, inset 0px 2px 3px rgba(117, 176, 255, 0.25), inset 2px 0px 3px rgba(117, 176, 255, 0.25), inset 0px -3px 3px rgba(255, 255, 255, 0.65);
}

.button1 {background-color: #4CAF50;} /* Green */
.button2 {background-color: #008CBA;} /* Blue */
.button3 {background-color: #f44336;} /* Red */
.button4 {background-color: #e7e7e7; color: black;} /* Gray */
.button5 {background-color: #555555;} /* Black */


input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}
input[type="checkbox"]:focus {
  outline: 0;
}

.cbx {
    position: relative;
    pointer-events: all;
    width: 30px;
    height: 30px;
}

.cbx input {
 
  width: 24px;
  height: 24px;
  border: 2px solid #bfbfc0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-filter: url("#goo");
  filter: url("#goo");
  transform: trasnlate3d(0, 0, 0);
  pointer-events: none;
}
.cbx svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  pointer-events: none;
}
.cbx svg path {
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
  transition-delay: 0.2s;
}
.cbx input:checked + label {
  animation: splash 0.6s ease forwards;
}
.cbx input:checked + label + svg path {
  stroke-dashoffset: 0;
}
@-moz-keyframes splash {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}
@-webkit-keyframes splash {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}
@-o-keyframes splash {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}
@keyframes splash {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }
  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}


</style>