<template>
    <div  class="blur-background"  :style="{'width' : container_width + 'px', 'height' : container_height + 'px', 'top' : top + 'px', 'left' : left + 'px'}">
        <div class="card-container" :style="{'width' : width + 'px!important'}">
        <div :style="{'background-color' : title_color}" class="card-title-container">{{title}} <div @click="Clicked" class="quit-button"></div></div>
        <div :class="{'no-min-height' :NoHeight}" class="content-container" >
            <slot></slot>
            <div v-if="text != ''" class="card-message">{{text}}</div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name : 'MiniModal',
    props : {
        callBack : Function,
        NoHeight : {type : Boolean, default : false}
    },
    data () {
        return {
            title : "",
            text : "",
            top : 0,
            left : 0,
            width : '460',
            title_color : "var(--amaranth)",
            container_width : 0,
            container_height : 0,
        }
    },
    methods: { 
        Clicked: function (event)
        {
            if(this.callBack)this.callBack(event, this.index);
            this.$emit('modalquit');
            this.Hide();
            
        },
        Hide : function ()
        {
            const el = this.$el;
            const card = el.children[0];
            this.$el.style.display = 'none';
            card.className= "card-container";
        },
        Reveal(title = "Title", text, width = '582', color = "var(--amaranth)")
        {
        this.title_color = color;
        
        const body = document.documentElement;
        this.container_width = body.offsetWidth;
        this.container_height = body.offsetHeight;
        this.top = -this.$el.parentNode.offsetTop;  
        this.title = title;
        this.text = text;
        this.width = width;
        const el = this.$el;
        const card = el.children[0];
        el.style.display = "block";
        card.className= "active card-container"
        }
     }
   
    
}
</script>


<style scoped>

.no-min-height {
    min-height : 0!important;
}

@media(max-width:1100px){
    .card-container {
        width: 90%!important;
    }
}
@media(max-width:450px){
    .card-message {
        font-size: 1rem!important;
    }
    .card-title-container {
        font-size: 1rem!important;

    }
    .quit-button {
        right: 20px!important;
    }
}

@media(max-width:800px)
{
    .card-container {
        top: 2%!important;
        transform: translate(-50%,0%)!important;
    }
}


.card-container {
    border-radius: 4px;
    max-width: 582px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    display: flex;
    max-width: 95%;
    opacity: 0;
    z-index: 11;
    align-items: center;
    transform: translate(-50%, -50%);
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: hidden;
    width: 50%;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.51);
}

.content-container {
    background-color: var(--white-break);
    width: 100%;
    justify-content: center;
    min-height: 200px;

    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.active {
    animation: enter 0.4s forwards;
}

.quit {
    animation: leave 0.4s forwards;
}



.card-message {
    text-align: center;
    font-size: 1.25rem;
    color: black;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: 70%;
}

.blur-background {
    position: absolute;
    display:none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.card-title-container {
    font-size: 1.75rem;
    width: 100%;
    text-align: center;
    color : white;
    background-color :var(--amaranth);
    border-top-left-radius: 4px;
    font-weight: bold;
    border-top-right-radius: 4px;
    position: relative;
    padding: 0.5rem 1rem;

}

.quit-button {
    position: absolute;
    top: 50%;
    cursor: pointer;
    background-size: contain;
    right: 40px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    filter: brightness(4);
    background-image: url('../assets/imgs/croix.svg');
}



.card-button {
    text-transform: uppercase;
    background-color: var(--amaranth);
    padding: 0.5rem 1rem;
    font-weight: bold;
    border-radius: 10px;
    display: inline;
    margin-bottom: 1rem;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.31);
    cursor: pointer;
    transition-duration: 0.2s;
    user-select: none;
}
.card-button:hover {
    transform: scale(1.1);
}

@keyframes enter {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
    
}
@keyframes leave {

    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
    
}

</style>