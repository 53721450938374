<template>
    <div class="pay-container">
        <div v-if="payment_intent_loaded && !errored">
            <div class="item-container mx-auto">
            <h2>Paiement</h2>
             <div>
                 <img src="../assets/imgs/lock.svg" alt="">
                 <span class="little-span">Tunnel de paiement SSL sécurisé par <b><a href="https://stripe.com/fr">Stripe</a></b></span>
             </div>
             <div class="total-container">
                 <span >Total : </span>
                 <span class="ml-auto" style="color:var(--amaranth)"> {{this.amount.toFixed(2)}}€</span>
             </div>
             <div class="d-flex align-items-center w-100 flex-column justify-content-center" v-if="saved_card_mode">
                 <form >
                     <span>Utiliser une carte enregistrée</span>

                    <div class="d-flex"> <select class="pay-select mb-0" v-model="method_index"  name="" id="">
                    <option v-for="(method, index) in payment_methods.data" :key="index" :value="index">*--- {{method.card.last4}}    {{method.card.exp_month}}/{{method.card.exp_year}}</option>
                    
                </select>
                <div class="saved-cvc-style" id="saved-cvc"></div></div>
                <button :class="{'pay-disabled' :  !cvc_ok}" @click="PayWithSavedCard">
                
                <span v-if="!paying" id="button-text">Procéder au paiement</span>
                <div v-else class="spinner" id="spinner"></div>
            </button>
                 </form>
                 <div class="spacer"></div>
                 <form >
                     <button :class="{'pay-disabled' : paying}" @click="ChangeCardMode">
                          <span  id="button-text">Utiliser une nouvelle carte</span>
                
            </button>
                 </form>
                
               
             </div>
              <div class="d-flex align-items-center w-100 flex-column justify-content-center" v-else>
            <form @submit="SubmitPay" id="payment-form" class="mt-2 position-relative">

                
                
                <div class="checkbox-container">
                <input type="checkbox" v-model="same_adresse"  name="" id="" checked>
                <span>Adresse de facturation identique à l'adresse de livraison</span>
                </div>
                <transition name="slide-fade" appear="">
                  <div v-if="!same_adresse" class="w-100 d-flex flex-column align-items-center justify-content-center mb-3">
                      <adresse-form ref="facturation" :customForm="true" title="Adresse de facturation" :save="false" :button="false" />
                  </div>
                    </transition>
                <input class="custom-input" v-if="same_adresse" v-model="titulaire" type="text" name="" id="" placeholder="Nom du titulaire de la carte">
            <div id="card-element"><!--Stripe.js injects the Card Element--></div>
            
                <div class="d-flex">
                    <div id="exp-element"><!--Stripe.js injects the Card Element--></div>
                <div id="cvc-element"><!--Stripe.js injects the Card Element--></div>
                </div>
            
            <button type="submit" :class="{'pay-disabled' : paying || !card_ok || !cvc_ok || !exp_ok || (titulaire == '' && same_adresse)}">
                
                <span v-if="!paying" id="button-text">Procéder au paiement</span>
                <div v-else class="spinner" id="spinner"></div>
            </button>
            <transition name="slide-fade">
            <div v-if="customer_id" class="checkbox-container">
                <input v-model="save_card" type="checkbox"  name="" id="">
                <span>Sauvegarder pour de futur achats</span>
            </div>
            </transition>
            <small style="color:gray;display:block!important;max-width:350px;margin:0 auto" v-if="customer_id">En enregistrant les renseignements relatifs à votre carte de paiement, vous autorisez Neon Bloom à débiter votre carte pour les paiements futurs conformément à ses conditions.</small>

            <p id="card-error" role="alert"></p>
            <p class="result-message hidden">
                Payment succeeded, see the result in your
                <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.
            </p>
            </form>
            <div v-if="hasSavedCard" class="spacer"></div>
                 <form v-if="hasSavedCard"  >
                     <button :class="{'pay-disabled' : paying}" @click="ChangeCardMode">
                          <span  id="button-text">Utiliser une carte enregistrée</span>
                
            </button>
                 </form>
                 </div>
             
        </div>
        </div>
        <div v-else class="d-flex flex-column align-items-center">
            <div v-if="!errored" class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>
                    <span  v-if="!errored">Chargement du tunnel de paiement sécurisé</span>
                    <span v-else>{{error_message}}</span>
        </div>
        <transition name="slide-fade" appear>
       <div v-if="show_modal" class="paymodal-background">
            <div class="paymodal-container">
            <div class="paymodal-title" :class="{'error-bg' : modal_error}">{{modal_title}}</div>
            <div class="paymodal-content">{{modal_content}}</div>
            <button class="paymodal-button"  :class="{'error-bg' : modal_error}" @click="CloseModal">C'est noté</button>
        </div>
       </div>
       </transition>
    </div>
</template>

<script>
import AdresseForm from '../components/AdresseForm.vue';
import {Adresse} from '../components/renderless/CheckoutHandler'
export default {
    components: {
       
        AdresseForm
    },

    props: {
        pay_id : String,
        custom_id : {type: String, default : null}
    },
    
    

    data()
    {
   
             return {
            stripeHandler : this.$stripeHandler,
            panierHandler : this.$panierHandler,
            choose_mode : true,
            saved_card_mode : false,
            method_index : 0,
            method_ok : false,
            new_card_mode : false, 
            stripe_elements : null,
            card_element : "<div>COUCOU</div>",
            current_cvc_mount : "",
            card_exp : "",
            customer_id : null,
            livraison_adresse : this.$checkoutHandler.GetAdresse().clone(),
            facturation_adresse : new Adresse("FR","Aubevoye","12 rue des lombards", 27940, "Antoine poirrier"),
            same_adresse : true,
            elements_mounted : false,
            saved_cvc_ok : false,
            country : "FR",
            card_cvc : "",
            mounted : false,
            card_ok : false,
            exp_ok : false,
            cvc_ok : false,
            save_card : false,
            payed : false,
            modal_title : "Echec de la transaction",
            modal_content : "Lorem ipsum dolor sit amet et mergitum dessat",
            modal_error : true,
            show_modal : false,
            payment_methods : null,
            titulaire : "",
            payment_intent_loaded : false,
            error_message : "",
            command_id : "",
            errored : false,
            amount : 10,
            client_secret : "",
            paying : false,
        };
    },

    created() {
        this.StripeWatcher();
        
    },
    metaInfo : {
        title : 'Paiement',
        meta : [
            {name:"robots" , content : "noindex"}
        ]
    },

    computed: {
        hasSavedCard()
        {
            return (this.payment_methods != null && this.payment_methods.data.length != 0)
        }
        
        },

    methods: {

          ValidateFacturationAdresseForm()
        {
            let validate = true;
            if(!this.$refs.addresse_name.ValidateInput())validate = false;
            if(!this.$refs.addresse_loc.ValidateInput())validate = false;
            if(!this.$refs.addresse_city_num.ValidateInput())validate = false;
            if(!this.$refs.addresse_city.ValidateInput())validate = false;

            return validate;
        },

        SaveFacturationAdresse()
        {
            this.facturation_adresse = this.$refs.facturation.getAdresse();
            this.titulaire = this.facturation_adresse.name;
        },

            SubmitPay(event)
            {
                 event.preventDefault();
                if(!this.same_adresse)
                {
                    if(!this.$refs.facturation.validateCountry())return;
                    //const validate = this.ValidateFacturationAdresseForm();
                    //if(!validate)return;
                    this.SaveFacturationAdresse();
                    
                }
                else {
                this.facturation_adresse = this.livraison_adresse;
                this.facturation_adresse.name = this.titulaire;}
                this.PayWithCard();
               
            },

           

            PayWithSavedCard()
            {
                event.preventDefault();
                this.paying = true;
                this.stripeHandler.PayWithSavedCard(this.cvc_element,this.client_secret, this.payment_methods.data[this.method_index]).then((intent) => {
                    if(intent.error)this.PaiementErrored(intent)
                    else this.PaiementSucceed(intent);
                    this.paying = false;
                })
            },

            PayWithCard()
            {
                this.paying = true;
                this.stripeHandler.PayWithCard(this.card_element,this.client_secret, this.facturation_adresse, this.save_card).then((intent) => {
                    if(intent.error)this.PaiementErrored(intent)
                    else this.PaiementSucceed(intent);
                    this.paying = false;
                })
            },

            CloseModal()
            {
                this.show_modal = false;
                if(!this.payment_intent_loaded)
                {
                    this.$router.push('/');
                }
                if(this.payed)
                {
                    this.payment_intent_loaded = false;
                    this.stripeHandler.payment_intent = null;
                    this.stripeHandler.payed = true;
                    this.panierHandler.ClearPanier();
                    this.$router.push('/Validate');
                }
                
            },

            PaiementSucceed()
            {
                this.modal_title = "Transaction réussie"
                this.modal_content = "Vous allez être redirigé vers le site. Merci pour votre achat !"
                this.payed = true;
                this.modal_error = false;
                this.show_modal = true;
                this.$checkoutHandler.SetCoupon("");
                

            },

           

            PaiementErrored(intent)
            {
                this.modal_title = "Echec de la transaction"
                this.modal_content = intent.error.message;
                this.modal_error = true;
                this.show_modal = true;
            },


            StripeWatcher()
            {
                if(this.stripeHandler.stripe == null)return;
                if(!this.stripeHandler.payment_intent)
                {
                    
                    this.modal_title = "Permission insuffisante"
                this.modal_content = "Vous n'avez pas la permission d'acceder a cette page, vous allez être redirigé";
                this.modal_error = true;
                this.show_modal = true;
                }
                else{
                    this.client_secret = this.stripeHandler.payment_intent.secret;
                    this.payment_methods = this.stripeHandler.payment_intent.payment_methods;
                    if(this.payment_methods != null && this.payment_methods.data.length != 0)this.saved_card_mode = true;
                    else this.saved_card_mode = false;
                  
                    this.customer_id = this.stripeHandler.payment_intent.customer;
                    this.command_id = this.stripeHandler.payment_intent.command_id;
                    this.amount = this.stripeHandler.payment_intent.amount/100;
                    this.payment_intent_loaded = true;
                     const style = {
                            base: {
                                color: "#32325d",
                                fontFamily: 'Arial, sans-serif',
                                fontSmoothing: "antialiased",
                                fontSize: "16px",
                                "::placeholder": {
                                color: "#32325d"
                                }
                            },
                            invalid: {
                                fontFamily: 'Arial, sans-serif',
                                color: "#fa755a",
                                iconColor: "#fa755a"
                            }
                                    };
                    this.stripe_elements = this.stripeHandler.stripe.elements();
                    this.stripe_elements.update({locale: 'fr'});
                    this.card_element = this.stripe_elements.create('cardNumber', {style : style, showIcon : true});
                    this.card_exp = this.stripe_elements.create('cardExpiry', {style : style});
                    this.card_cvc = this.stripe_elements.create('cardCvc', {style : style});
                    this.MountElements();
                }
                    
                
            },

            HandleCvcMount()
            {
                if(this.current_cvc_mount != "")this.card_cvc.unmount(this.current_cvc_mount);
                
                if(!this.saved_card_mode)
                {
                    this.current_cvc_mount = "#cvc-element"
                    this.card_cvc.mount('#cvc-element');
                }
                else {
                    this.current_cvc_mount = "#saved-cvc"
                    this.card_cvc.mount('#saved-cvc');
                }
            },

            ChangeCardMode(event)
            {
                event.preventDefault();
                this.saved_card_mode = !this.saved_card_mode;
                this.$nextTick(() => {
                    this.HandleCartMount();
                this.HandleCvcMount();
                })
            },

            HandleCartMount()
            {
                if(!this.saved_card_mode)
                {
                    this.card_element.mount('#card-element');
                    this.card_exp.mount('#exp-element');
                    
                }
                else {
                    if(this.elements_mounted)
                    {
                        this.card_element.unmount('#card-element');
                    this.card_exp.unmount('#exp-element');
                    }
                }
            },

          

            CardChange(event)
            {
                
                if(!event.complete)this.card_ok = false;
                else this.card_ok = true;
                
            },
            CvcChange(event)
            {
                if(!event.complete)this.cvc_ok = false;
                else this.cvc_ok = true;
            },
            ExpChange(event)
            {
                if(!event.complete)this.exp_ok = false;
                else this.exp_ok = true;
            },

            SavedCvcChange(event)
            {
                if(!event.complete)this.saved_cvc_ok = false;
                this.saved_cvc_ok = true;
            },

            MountElements()
            {
                if(!this.elements_mounted && this.stripe_elements  != null && this.payment_intent_loaded && this.mounted)
                {
                 
                    this.$nextTick(() => {
                    this.HandleCartMount()
                    this.HandleCvcMount()
                    this.card_element.on("change",this.CardChange);
                    this.card_exp.on("change",this.ExpChange);
                    this.card_cvc.on("change",this.CvcChange);
                    this.elements_mounted = true;
                    })
                    
                }
            }
        },

    mounted() {
        this.mounted = true;
        this.MountElements();
        
    

    },
    beforeRouteLeave (to, from , next) {
    
     let answer = true;
     if(!this.payed) answer = window.confirm('Voulez vous vraiment quitter ? La commande sera abandonnée!')
  if (answer) {
    next()
  } else {
    next(false)
  }
},
    watch: {
        'stripeHandler.stripe' : 'StripeWatcher'
    }
}
</script>

<style scoped>

/** 
<div class="paymodal-background">
            <div class="paymodal-container">
            <div class="paymodal-title">{{modal_title}}</div>
            <div class="paymodal-content"></div>
            <button class="paymodal-button">C'est noté</button>
        </div>
       </div>


*/

.spacer  {
    background-color : black;
    width : 100px;
    height : 2px;
    margin : 1rem 0;
}



.saved-cvc-style {
        border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    width: 100%;
    background: white;
    max-width: 100px;
}


.paymodal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
    z-index : 2;
}

.paymodal-content {
    padding: 1rem;
    font-family : 'Montserrat', sans-serif;
    color: white;
    max-width : 500px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymodal-container {
    background-color: #003049;
    border-radius : 4px;
    overflow: hidden;
    min-width : 310px;
}

.error-bg {
    background-color: #D62828!important;
}



.paymodal-title {
    background-color: #27AE60;
    padding: 0.5rem 1rem;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size : 1.5rem;
}

.paymodal-button {
    background-color: #27AE60;
    color : white;
    margin-left: auto;
    width: auto;
    margin-right : 1rem;
    margin-bottom : 1rem;
    border-radius : 4px;
}

.card-info-container {
    display: flex;
}


.pay-disabled {
    opacity: 0.8;
   
    pointer-events: none;
}

.form-container > span {
    font-size : 0.8rem;
    color : black;
}

.form-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: center;
    min-width: 250px;
    align-items: flex-start;
}


.total-container {
    padding : 0.75rem 0;
    display : flex;
    border-bottom: 1px solid black;
}
.total-container > span
{
    color: #32325d;
    font-family: Arial, sans-serif;
    font-weight : bold;
    font-size: 20px;
    margin : 0 0.25rem;
    -webkit-font-smoothing: antialiased;
}

.checkbox-container {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content : center;
    font-family : "Montserrat", sans-serif;
    font-size : 14px;
}

.checkbox-container > input {
    -webkit-appearance: checkbox!important;
    appearance: checkbox!important;
    width: 20px;
    margin-right : 0.5rem;
}

.pay-container {
    
    padding: 3rem 0;
    min-height : 100vh;
    display : flex;
    flex-direction: column;
    justify-content : center;
    align-items : center;
    background-color: var(--white-break);
}

.form-container-pay {
    width : 100%;
    margin-top: 1rem;
    
}


.item-container {
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius : 4px;
    padding : 1rem;
    max-width: 95%;
    position: relative;
    display: flex;
    flex-direction : column;
    align-items : center;
}

.item-container > h2 {
    font-size : 1.5rem;
    font-weight : bold;
    margin-bottom : 0;
}

.little-span {
    font-size : 14px;
}

@media (max-width:600px)
{
    .item-container {
       max-width: 100%!important;
       width:100%!important;
       padding : 1rem 0.25rem!important;
        
    }

    form {
        max-width:100%!important;
        padding: 40px 0.25rem!important;
        box-shadow: none!important;
    }
}

form {
  width : 100%;
  max-width: 95%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
input,.pay-select {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}


.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}
#card-element, #exp-element, #cvc-element {
  border-radius: 4px 4px 0 0 ;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}
#payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-input{
        color: #32325d;
    font-family: Arial, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    outline: none;
}


</style>