<template>
<div class="container">
    <div ref="dragzone" @mouseleave="DecrementCounter" @dragover="Prevent" @drop="HandleDrop"  @dragenter="IncrementCounter" @dragleave="DecrementCounter" id="dragzone" :class="{'highlight' : counter > 0}" class="drag-container d-flex justify-content-center flex-wrap">
       <div v-for="(url,index) in urls" :key="index">
           <div style="position:relative">
               <img v-show="counter == 0" :src="url.url" alt="" srcset="" style="object-fit:cover; border:1px solid #404040" width="100" height="100" class="m-2">
               <span @click="RemoveUrl(index)" class="cross">X</span>
           </div>
       </div>
        
        
        <span v-show="counter == 0 && urls.length == 0"  class="drag-title" id="title">Déposer ici</span>
    </div>
    <div :class="{'input-container' : true, 'hide' : !isEmpty}">
        <span class="bold">Ou</span>
        <input @change="HandleFileSelection" class="drag-input" type="file" name="file" id="fileInput">
    </div>
    <div>
        <button @click="SubmitUrls" class="btn btn-primary mt-2" >{{validate_text}}</button>
    </div>
</div>
    
</template>

<script>

import {Background} from './ChooseBackground.vue'

export default {
    name : 'ImagesAdder',

    data() {
        return {
            button : null,
            urls : [],
            names : [],
            isEmpty : true,
            fileInput : null,
            DragZone : null,
            counter : 0,
            imageTypes : ['image/png','image/webp', 'image/gif', 'image/bmp', 'image/jpg','image/jpeg'],
            
        }
    },
    computed : {

        
    },
    props : {
        parent : Object,
        callBack : Function,
        validate_text : String,
        persistant : {type : Boolean, default : false},
        justfile : {type : Boolean, default : false},
        
        maxsize : {type : Number, default : 8000000},
    },
    methods: {

        RemoveUrl(index)
        {
            this.urls.splice(index, 1);
        },

            SubmitUrls : function()
            {
                const u = [...this.urls];
               
                this.$emit('newurlsajout', u);
                this.Quit();
                
            
                
                
            },



            Quit : function()
        {
            this.counter = 0
            this.fileInput.value = "";
            if(!this.persistant)
            {
                this.urls = [];
            }
        },
        Prevent(e)
        {
            e.preventDefault();
        },

        IncrementCounter()
        {
            this.counter = 1;
        },
        DecrementCounter()
        {
            this.counter = 0;
        },
        HandleFileSelection(e)
        {
            this.Prevent(e);
            this.HandleFile(e.target.files);
        },

        HandleDrop(e) {
            this.Prevent(e);
            this.counter =0;
            let dt = e.dataTransfer
            let files = dt.files
            
            this.HandleFile(files);
        },
        async HandleFile(files)
        {
           
           for (let i = 0; i < files.length; i++) {
               const file = files[i];
              
               if (!this.imageTypes.includes(file.type)){
                window.alert("L'un des fichiers n'est pas une image !");
                this.fileInput.value = '';
                return;
                }
                if(file.size > this.maxsize){
                    alert("L'image est trop grande (max " + this.maxsize/1000000 + 'Mo)' )
                    return;
                    }
            
            this.counter = 0;
            if(this.justfile)
            {
                this.urls.push({name : file.name, file : file, url : URL.createObjectURL(file)});
            }
            else {
                this.urls.push(new Background(URL.createObjectURL(file)));
            }
           
           }
           
        }

    },
    mounted()
    {
       
      
        
        //on evite les evenements par defauts
       

        //gestion des fichiers input


        this.fileInput = document.getElementById('fileInput');
       
        //####################### gestion des fichiers dropés

    

        

        

        

         




    }
}
</script>


<style  scoped>

@media(max-width:450px)
{
    .drag-container {
        width: 100%!important;
    }
}

.cross {
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    background-color : black;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-around;
    align-items: center;
    font-family: 'Quicksand', sans-serif!important;
    margin-top : 0.5rem;
    color: black;

}
.hide {
    display: none!important;
}




.drag-container {
    height: 15rem;
    width: 80%;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    transition-duration: 0.2s;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 1rem;
}

.highlight {
    background-color:  rgba(0, 0, 0, 0.3);
}





.drag-title {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%,-50%);
    font-size: 2rem;
    color : rgba(0, 0, 0, 0.3);
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}

</style>