<script>
import '../assets/css/loader.css'
export const LittleImage = 
{
    props :{
        url : String,
        waiting : Boolean,
        type : {type: Number, default : 1},
        
    },
    data()
    {
        return{
            x : 0,
            y : 0,
            size: 0,
            save : [0,0,0],
            etalonSize : 1,
            ratio : 0.125,
        }
    },
    render()
    {
        if (!this.$props.waiting) return(
            
                <div  class="dragged-img-container">
            <img ref="img" class="dragged-img" src={this.$props.url} alt="coucou"/>
            
                </div>
        );
        else return(
            
                <div class="dragged-img-container">
            <div id="loader" style="display:block; width:100%; padding:0; " class="cs-loader-inner">
            <label>●</label>
            <label>●</label>
            <label>●</label>
            <label>●</label>
            <label>●</label>
            <label>●</label>
          </div>
            
                </div>
        );
    },
    methods: {

        SetPosition(x,y)
        {
            this.x = x 
            this.y = y;
        },
        GetX()
        {
            return this.x;
        },
        GetY()
        {
            return this.y;
        },
        Save()
        {
            this.save[0] = this.x;
            this.save[1] = this.y;
            this.save[2] = this.size;
        },
        Revert()
        {
            this.x = this.save[0];
            this.y = this.save[1];
            this.size = this.save[2];
           
        },

        SetSize()
        {

        },

        SetRatio(ratio, backgroundSize)
        {
             this.ratio = ratio;
             this.etalonSize = backgroundSize;

        }
    }
}
export default LittleImage;
</script>

<style>

.dragged-img-container {
    z-index: 0;
    background-color: aliceblue;
    cursor: pointer;
    border-radius: 10px;
    color: black;
    margin: 0.25rem 0.25rem;
    padding: 0.6rem;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.51);
    transition-duration: 0.2s;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
}

.dragged-img-container:hover {
    transform: scale(1.05);
}

.dragged-img-container > div {
    overflow: hidden;
    width : 100px;
    height: 40px;

}

.dragged-img {
    object-fit: cover;
    width: 100px;
    height: 100px;
}

</style>