
import Vue from "vue";

export class Calculateur {
    constructor (defautNeonCallBack)
    {
        this.polices = [];
        this.saved_fonts = [];
        this.policeRatios = [];
        this.prices = { supports : {rect : 0, arrondi : 0, base : 0, pied : 0}, support_colors : {miroir : 0, golden : 0, blanc : 0, noir : 0}};
        this.colors = [];
        this.supportColors = [{color : "rgba(0,0,0,0.1)", name:'Transparent'}
        ,{color : "linear-gradient(315deg, #e3efe8 0%, #96a7cf 74%)", name: 'Miroir'}
        ,{color : "radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #fff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%)", name:'Golden'}
        ,{color : "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(219,219,219,1) 100%)", name : 'Blanc'}
        ,{color : "radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(84,84,84,1) 100%)", name : 'Noir'}

        ]
        this.fils = ['Transparent', 'Noir', 'Blanc'];
        this.firestore = Vue.prototype.$firestore;
        this.OptionsPrices = 0;
        this.policesLoaded = false;
        this.pricesLoaded = false;
        this.colorsLoaded = false;
        this.defaultNeonLoaded = false;
        this.defautNeonCallBack = defautNeonCallBack;
        this.background_sim = "";
        //this.fontCallback = fontCallback;
        //this.supportsOptionsColorsCallBack = supportsOptionsColorsCallBack;




    }

    GetData()
    {
        return new Promise((resolve, reject) => {
            if(this.policesLoaded && this.pricesLoaded && this.colorsLoaded && this.defaultNeonLoaded){resolve(false);return}
        this._RetrieveAssembly_Support_Colors_Options_Livraison().then((prices) => {

            this.RetrieveAssembly_Support_Colors_Options_Livraison(prices);

            this._RetrieveCurrentFont().then((polices) => {

                this.RetrieveCurrentFont(polices);

                this._RetrieveDefaultNeon().then((data) => {

                    this.RetrieveDefaultNeon(data.defaultNeon);
                    this.backgroundCallback(data.background_url).then(() => {

                        resolve(true);
                    })


                }).catch((err) => {
                    reject(err);
                });

            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {reject(err)});


        })
    }


    SetBackgroundCallBack(callback)
    {
        this.backgroundCallback = callback;
    }


    BubbleSortPolices(array)


            {
                for(let i = 0; i < array.length; i++)
                {
                    if(!array[i].props.priority || array[i].props.priority == -1)
                    {
                        array[i].props.priority = Infinity;
                    }
                }

                array.sort((a,b) => {
                    if(a.props.priority > b.props.priority)return 1
                    if(a.props.priority == b.props.priority)return 0
                    return -1;
                })


    }

    RenderPolices()
    {
      let sheet = window.document.styleSheets[2];

      this.polices.forEach((police) => {

        sheet.insertRule(`@font-face {font-family: '${police.id}';font-style: normal;font-weight: normal;src:url('${police.props.link}') format('${police.props.type}');}`, sheet.cssRules.length);

      })
      this.policesLoaded = true;


    }



    _RetrieveCurrentFont()
    {

        const polices = [];
        //let Count = 0;
        const firestore = this.firestore;
        return new Promise((resolve, reject) => {
            firestore.collection("/Admin/Prices/Polices").get().then((docs) => {

                docs.forEach((doc) => {

                    polices.push({id : doc.id, props : doc.data()})

                })

                resolve(polices);
            }).catch((err) => {reject(err)});
        })






    }

    ParseFloatJSON(json)
    {
        for(const key in json)
        {
            json[key] = parseFloat(json[key])
        }
        return json;

    }

    _RetrieveAssembly_Support_Colors_Options_Livraison()
    {
        const prices = {};
        let i = 0;
        const firestore = this.firestore;
        return new Promise((resolve, reject) => {
            firestore.collection('Admin').doc('Prices').get().then((result) => {
                prices.assembly = result.data().assembly;
                prices.supports = this.ParseFloatJSON(result.data().supports);
                prices.support_colors = this.ParseFloatJSON(result.data().support_colors);
                prices.options = result.data().options;
                prices.livraisons = result.data().livraisons;
                i++;
                if(i == 2){
                    resolve(prices);
                }
            }).catch((err) => {reject(err)})
            firestore.collection('Admin').doc('Colors').get().then((result) => {
                let a = []
                let d = result.data();
                for(const key in d)
                {
                    a.push(d[key])
                }
                prices.colors = a;
                i++;
                if(i == 2){
                    resolve(prices);
                }
            }).catch((err) => {reject(err)})


        })
    }

    _RetrieveDefaultNeon()
    {
        const firestore = this.firestore;
         return new Promise((resolve, reject) => {
            firestore.collection("/Admin").doc('Default').get().then((doc) => {
                resolve(doc.data());


            }).catch((err) => {reject(err)});
         })

    }

    RetrieveDefaultNeon(d)
    {
        this.defautNeonCallBack(this.GetIdFromPolice(d.police), d.text, d.color);


    }

    SortSizes(polices)
    {
        for(let i = 0; i < polices.length;i++)
        {
            const p = polices[i];
            let permutation = 1;
            while(permutation != 0)
            {
                permutation = 0;
                for(let j = 0; j < 3; j++)
            {
                const size1 = p.props.sizes[j];
                const size2 = p.props.sizes[j+1];
                if(!size1.active && size2.active)
                {
                    permutation = 1;
                    p.props.sizes[j] = size2;
                    p.props.sizes[j+1] = size1;
                }
                else if(size1.active && !size2.active);
                else if(parseInt(size1.cm) > parseInt(size2.cm))
                {
                    permutation = 1;
                    p.props.sizes[j] = size2;
                    p.props.sizes[j+1] = size1;
                }


            }
            }

        }
    }

    RetrieveCurrentFont(polices)
    {
            this.polices = polices;
            this.SortSizes(this.polices);
            this.BubbleSortPolices(this.polices);
            this.RenderPolices();
            //this.fontCallback();
    }

    RetrieveAssembly_Support_Colors_Options_Livraison(prices)
    {

        this.prices = prices;
        this.colors = prices.colors;
        this.colorsLoaded = true;
        this.pricesLoaded = true;
        //this.supportsOptionsColorsCallBack();


    }

    DimensionAvailable(dimension_id, font_id)
    {
        return this.polices[font_id].props.sizes[dimension_id].active;
    }

    async CheckPriceForNeon(neonJSON)
    {


            let font = null;
            let found = false;
            for(let i = 0; i < this.saved_fonts.length; i++)
            {
                if(this.saved_fonts[i].font_name == neonJSON.font)
                {
                    font = this.saved_fonts[i].font_data;
                    found = true;

                    break;
                }
            }
            if(!found) {
                const font_doc = await  this.firestore.collection("/Admin/Prices/Polices").doc(neonJSON.font).get();
                font = font_doc.data();

                this.saved_fonts.push({font_name : neonJSON.font,font_data : font});
            }



            let prices = {};
            if(this.prices.supports.rect == 0)
            {
                const prices_doc = await  this.firestore.collection("/Admin").doc("Prices").get();
                prices = prices_doc.data();
                this.prices = prices;
            }
            else {

                prices = this.prices;
            }


            const prices_tab = font.sizes[neonJSON.dimension_id].prices_tab;


            let chars = neonJSON.text.replace(/\s+/g, '');

            let font_price = prices_tab[chars.length-1] * chars.length;
            let color_price = 0;
            if(neonJSON.color == "rainbow") color_price= 50;
                        /*let max = font.sizes[neonJSON.dimension_id].max;
            console.log(max);
            if(max == undefined)font_price = parseFloat(font.sizes[neonJSON.dimension_id].prix) * chars.length;

            else if(font.sizes[neonJSON.dimension_id].deg_check)
            {
                let min = parseFloat(font.sizes[neonJSON.dimension_id].min);

                const value = ((96.93*Math.pow(chars.length,-0.426))* max) / 89;
                console.log(value);
                font_price = Math.min(Math.max(value, min), max) * chars.length;

            }
            else {
                font_price = parseFloat(font.sizes[neonJSON.dimension_id].max) * chars.length;
            }*/




            switch(neonJSON.support_id)
            {
                case 0:
                    font_price += parseFloat(prices.supports.rect);
                    break;
                case 1 :
                    font_price += parseFloat(prices.supports.arrondi);
                    break;
                case 2 :
                    font_price += parseFloat(prices.supports.base);
                    break;
                case 3 :
                    font_price += parseFloat(prices.supports.pied);
                    break;
            }

            let support_color_price = 0 ;

            if(neonJSON.support_color_id != 'transparent')support_color_price += parseFloat(prices.support_colors[neonJSON.support_color_id]);
            if(isNaN(support_color_price) ||support_color_price == undefined){support_color_price = 0}

            let p = 0
        if(neonJSON.options.neon_type_id == 1)
        {
            p += parseFloat(prices.options.ext);
        }
        if(neonJSON.options.telecommande)
        {
            p += parseFloat(prices.options.telecommande);
        }
        return font_price + color_price + support_color_price + p ;



    }

    getNumberOfLine(parsedString)
    {
        return  parsedString.split(/\r\n|\r|\n/).length
    }

    splitLines(parsedString)
    {
        return parsedString.split(/\r\n|\r|\n/);
    }

    CalulateHeight(neon)
    {

        let font_id = this.GetIdFromPolice(neon.font);
        let font = this.polices[font_id];
        const props = font.props;

        const ratio = props.sizes[neon.dimension_id].cm/props.height_values.minuscule;
        if(props.height_values == null)throw "Pas d'étalon"
        const splitedLines = this.splitLines(neon.text);

        let heights = [];
        for(let i = 0; i < splitedLines.length; i++)
        {
            let chars = splitedLines[i].replace(/\s+/g, '');

            const ascendingReg = new RegExp('[' + props.height_values.ascending_char + ']', 'g');
            const capsReg = new RegExp('[A-Z]', 'g');
            const descendingReg = new RegExp('[' + props.height_values.descending_char + ']', 'g');
            const normalReg = new RegExp('[^A-Z' + props.height_values.ascending_char + props.height_values.descending_char + ']', 'g')
            const matchesA = [...chars.matchAll(ascendingReg)];
            const matchesC = [...chars.matchAll(capsReg)];
            const matchesD = [...chars.matchAll(descendingReg)];
            const matchesN = [...chars.matchAll(normalReg)];

            const A = matchesA.length > 0;
            const C = matchesC.length > 0;
            const D = matchesD.length > 0;
            const N = matchesN.length > 0;

            let top = Math.max(A * props.height_values.ascending, C * (props.height_values.caps - props.height_values.baseline_delta), N * props.height_values.minuscule);
            let bottom = Math.max(D * (props.height_values.descending - props.height_values.minuscule),C * props.height_values.baseline_delta);

            heights[i] = top + bottom;
        }

        const reducer = (previousValue, currentValue) => previousValue + currentValue;
// 1 + 2 + 3 + 4
        return (heights.reduce(reducer) * ratio + 6* (splitedLines.length-1));
         //(height*ratio);
    }


    UpdatePrice(neon)
    {
        let height = null;
        try {
             height = this.CalulateHeight(neon);
        }

        catch(e) {

            height = 0;
        }
        if(height != 0)neon.height = height;
        let font_id = this.GetIdFromPolice(neon.font);
        let font = this.polices[font_id];
        const prices_tab = font.props.sizes[neon.dimension_id].prices_tab;
        let chars = neon.text.replace(/\s+/g, '');
        let font_price = prices_tab[Math.max(0,chars.length-1)] * chars.length;
        let color_price = 0;
        if(neon.color == "rainbow") color_price+= 99;


        /*let font_price = 0;
        let chars = neon.text.replace(/\s+/g, '');
        let font_id = this.GetIdFromPolice(neon.font);
        let max = this.polices[font_id].props.sizes[neon.dimension_id].max;

        if(max == undefined)font_price = this.polices[font_id].props.sizes[neon.dimension_id].prix * chars.length;

        else if(this.polices[font_id].props.sizes[neon.dimension_id].deg_check)
        {
            let min = this.polices[font_id].props.sizes[neon.dimension_id].min;

            const value = ((96.93*Math.pow(chars.length,-0.426))* max) / 89;
            font_price = Math.min(Math.max(value, min), max) * chars.length;

        }
        else {
            font_price = this.polices[font_id].props.sizes[neon.dimension_id].max * chars.length;
        }*/




        switch(neon.support_id)
        {
            case 0:
                font_price += parseFloat(this.prices.supports.rect);
                break;
            case 1 :
                font_price += parseFloat(this.prices.supports.arrondi);
                break;
            case 2 :
                font_price += parseFloat(this.prices.supports.base);
                break;
            case 3 :
                font_price += parseFloat(this.prices.supports.pied);
                break;
        }
        let support_color_price = 0
        if(neon.support_color_id != 'transparent') support_color_price = this.prices.support_colors[neon.support_color_id];

        if(support_color_price == undefined ||isNaN(support_color_price)){support_color_price = 0}

        return font_price + color_price + support_color_price + this.OptionsPrice(neon.options) ;

    }

    GetColorDisplay(color)
    {
        for(let i = 0; i < this.colors.length;i++)
        {
            if(this.colors[i].hex == color)return this.colors[i].name;
        }
        return ""
    }

    GetSupportDisplay(support_id)
    {
        if(support_id == -1)return "Découpe"
        if(support_id == 0)return "Rectangle"
        if(support_id == 1)return "Arrondi"
        if(support_id == 2)return "Support plexi"
        if(support_id == 3)return "Pied plexi"

    }

    GetSupportColorDisplay(support_color_id)
    {

        return support_color_id;
    }
    GetFontDisplay(font_id)
    {
        return this.polices[font_id].props.display;
    }

    GetPoliceFromId(font_id)
    {

        return this.polices[font_id].id;
    }
    GetIdFromPolice(font)
    {
        for(let i = 0; i < this.polices.length;i++ )
        {
            if(this.polices[i].id == font)return i;
        }
        return 0;
    }

    GetPoliceRatioFromId(font_id)
    {
        let n = this.polices[font_id].props.ratio;
        if(n==undefined || n == 0)n=1;
        return n;

    }
    GetPoliceHratioFromId(font_id)
    {
        let n = this.polices[font_id].props.h_ratio;
        if(n==undefined || n == 0)n=1;
        return n;
    }

    GetSupportColorFromId(support_color_id)
    {
        for(let i = 0; i < this.supportColors.length; i++)
        {
            if(this.supportColors[i].name.toLowerCase() == support_color_id)return this.supportColors[i].color;
        }
        return "rgba(0,0,0,0.1)";
    }




    OptionsPrice(options)
    {

        let p = 0
        if(options.neon_type_id == 1)
        {
            p += parseFloat(this.prices.options.ext);
        }
        if(options.telecommande)
        {
            p += parseFloat(this.prices.options.telecommande);
        }


        return p;

    }


}


export default Calculateur;
