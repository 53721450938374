<template>
<transition name="slide-fade" mode="out-in" appear>
    <div class="product-item">
                    <img v-if="type == article" :src="panierItem.icon" style="object-fit:cover" width="100" height="100" alt="">
                    <div class="d-flex align-items-center justify-content-center" v-else-if="panierItem.item_data_neon.color != 'rainbow'"  style="width:100px;height:100px;object-fit:cover" :style="{'background-color' : panierItem.icon}">
                        <img src="../assets/imgs/custom.jpg" width="100" height="100" alt="" srcset="">
                    </div>
                    <div class=" rainbow-bg d-flex align-items-center justify-content-center" v-else style="width:100px;height:100px" >
                        <img src="../assets/imgs/custom.jpg" width="100" height="100" alt="" srcset="">
                    </div>
                    <div ref="desc" class="item-description" :style="DetailMenu">
                        <span style="white-space:nowrap">{{NeonText}}{{panierItem.item_name}}</span>
                        <span class="item-spec">Taille : {{Size}}</span>
                        <span class="item-spec underline item-selectable" @click="ToggleMenu()">{{Detail}}</span>
                        <div class="d-flex flex-column align-items-baseline">
                            <span v-for="(prop, key) in ItemData" :key="key" class="item-spec">{{key + " : " + prop}}</span>
                            
                        </div>
                    </div>
                    <span class="price unit">{{panierItem.unit_price.toFixed(2)}}€</span>
                     <select class="gray-select" name="" id="">
                            <option value="3">1</option>
                            <option value="3">2</option>
                            <option value="3">3</option>
                        </select>
                    <div class="qtt-container">
                        <button class="scaleanim" @click="Moins" >-</button>
                        <input type="number" min="1" @change="SetQuantity" :value="panierItem.quantity" />
                        <button class="scaleanim" @click="Plus" >+</button>
                       
                    </div>
                    <span class="price">{{panierItem.total_price.toFixed(2)}}€</span>
                    <button class="remove-button scaleanim" @click="Remove">x</button>
                    <div v-if="panierItem.item_disabled" class="error-container">
                        <span>Cet article n'est plus disponible</span>
                    </div>
    </div>
    </transition>
</template>


<script>
const article = 0;
const neon = 1;
export default {
    name:"PanierItemView",
    props :{
        panierItem : Object,
    },
    data() {
        return {
            see_det : false,
            type : article,
            article : 0,
            neon : 1,
        }
    },
    computed : {

        ItemData()
        {
            if(this.type != neon)return {
                "Bande 3M" :  this.panierItem.item_bande3M ? 'Oui' : 'Non',
                "Télécommmande" :  this.panierItem.item_telecommande ? 'Oui' : 'Non',
                "Variante" :  this.panierItem.item_variante,
            };
            else{return {
                Couleur : this.panierItem.item_data_neon.color_display,
                Police : this.panierItem.item_data_neon.font_display,
                Support : this.panierItem.item_data_neon.support_display,
                "Couleur support" : this.panierItem.item_data_neon.support_color_display,
                "Longueur*" : this.panierItem.item_data_neon.width.toFixed(0) + "cm",
                "Largeur*" : this.panierItem.item_data_neon.height.toFixed(0) + "cm",
                "*" : "Support inclus"
                }}
        },

        DetailMenu()
        {
            
            
            if(this.see_det)return "max-height:" + this.$refs.desc.scrollHeight + 'px';
            return "max-height:80px"
        },
        

        Size()
        {
            switch(this.panierItem.item_size)
            {
                case 0 : return 'XS'
                case 1 : return 'S'
                case 2 : return 'M'
                case 3 : return 'L'
            }
            return "XS";
        },
        Detail()
        {
            if(this.see_det)return "Voir moins"
            return "Voir le détail"
        },
        NeonText()
        {
            if(this.type == neon)return "Neon "
            return "";
        }
    },

    methods: {
        HandleDataValue(value)
        {
          
            if(value ===true)return "Oui";
            if(value ===false)return "Non";
            if(value === null)return "Par défaut";
            return value;
        },
        ToggleMenu() {
            this.see_det = !this.see_det;
        },

        Moins()
        {
            this.$panierHandler.UpdateQuantity(-1, this.panierItem);
        },
        SetQuantity(event)
        {
            
            this.$panierHandler.SetQuantity(event.target.value, this.panierItem);
            
        },
        Plus()
        {
            this.$panierHandler.UpdateQuantity(1, this.panierItem);
        },
        Remove()
        {
            this.$panierHandler.RemoveArticleFromPanier(this.panierItem);
        }
      
    

    },

    created() {
        if(this.panierItem.item_variante == null)this.type = neon;
    },

    
}
</script>


<style scoped>

.underline {
    text-decoration: underline;
}

.item-selectable {
    cursor: pointer;
}

.rainbow-bg {
    background-image: url('../assets/imgs/rainbow.jpg');
    background-size : cover;
}


.qtt-container > input::-webkit-outer-spin-button,
.qtt-container > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.qtt-container > input {
    width : 24px;
    height : 24px;
    font-weight: 400;
    -moz-appearance: textfield;
    border : 1px solid #FFB8B8;
    text-align : center;
    outline : none;
}

.item-spec {
    color : #707070;
    margin : 0.1rem 0;
    text-align: left;
    white-space: nowrap;
    font-size : 15px
}

.item-description {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: baseline;
    transition-duration : 0.2s;
}

.price {
    color: var(--amaranth);
    font-weight : 600;
}

@media (max-width:800px) {

    .unit {
        display : none!important;
    }

    .product-item {
        grid-template-columns : 25% 25% 25% 25%!important;
    }

}

.error-container {
    position : absolute;
    top : 0;
    z-index : 3;
    left : 0;
    width : 100%;
    height : 100%;
    display: flex;
    justify-content : center;
    align-items: center;
    background-color : rgba(160,160,160,0.8)
}

.error-container > span {
    color : var(--amaranth);
    font-family : 'Montserrat', sans-serif;
    font-size : 17px;
}

/*
@media (max-width:800px)
{

.remove-button {
    display: none!important;
}

.gray-select {
    display : flex!important;
    align-self: flex-start;
}

.qtt-container {
    display : none!important;
}

.no-display-min {
    display : none;
}

.product-item {
    
    grid-template-columns: 40% 60%!important;
    padding-left : 0.25rem;
    padding-right : 0.25rem;

}

}
*/

.gray-select {
    display: none;
    background-color : lightgray;
    border-radius : 7px;
    border: none;
    color:black;
    outline : none;
    padding : 0.75rem 1rem;
}

.remove-button {
    outline: none;
    position : absolute;
    z-index: 4;
    top : 18%;
    height: 40px;
    right :0;
    transform: translateY(-50%);
     width : 22px;
    background-color : #FFB8B8;
    height : 22px;
    font-size: 12px;
    font-weight : bold;
    border : none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qtt-container {
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
}

.qtt-container > button {
    margin: 0 0.4rem;
    outline: none;
    width : 24px;
    background-color : lightgray;
    height : 24px;
    border : none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qtt-container > span {
    width : 20px
}

.product-item {
    position : relative;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    width: 100%;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 0.75rem;
}

.product-item > span {
    height: 40px;
    display: flex;
    position : relative;
    z-index : 1;
    align-items: center;
    justify-content: center;
}

</style>