<template>
 <transition name="slide-fade" mode="out-in" appear>
    <div class="product-item" :class="{'mb-0' : last}">
        <img class="ml-1" v-if="type == article" :src="panierItem.icon" style="object-fit:cover" width="80" height="80" alt="">
         <div class="ml-1 d-flex align-items-center justify-content-center" v-else-if="panierItem.item_data_neon.color != 'rainbow'"  style="width:80px;height:80px;object-fit:cover" :style="{'background-color' : panierItem.icon}">
                        <img src="../assets/imgs/custom.jpg" width="80" height="80" alt="" srcset="">
                    </div>
                    <div class="ml-1 rainbow-bg d-flex align-items-center justify-content-center" v-else style="width:80px;height:80px" >
                        <img src="../assets/imgs/custom.jpg" width="80" height="80"  alt="" srcset="">
                    </div>
        <div class="product-data">
            <div class="title-container">
            <span style="white-space:nowrap">{{panierItem.item_name}}</span>
            <button style="z-index:4"  @click="Remove">x</button>
            </div>
            <div ref="desc" class="item-description" :style="DetailMenu">
                        <span class="item-spec">Taille : {{Size}}</span>
                        <span class="item-spec  item-selectable" @click="ToggleMenu()"><u>{{Detail}}</u></span>
                        <div class="d-flex flex-column align-items-baseline">
                            <span v-for="(prop, key) in ItemData" :key="key" class="item-spec">{{key + " : " + prop}}</span>
                            
                        </div>
                    </div>
            <span class="product-price">{{panierItem.total_price.toFixed(2)}}€</span>
        </div>
        <div class="qtt-container">
            <button class="scaleanim"  @click="Moins" >-</button>
            <input type="number" min="1" @change="SetQuantity" :value="panierItem.quantity" />
            <button class="scaleanim" @click="Plus" >+</button>
        </div>
    <div v-if="panierItem.item_disabled" class="error-container">
                        <span>Cet article n'est plus disponible</span>
                    </div>
    </div>
    </transition>
</template>

<script>

const article = 0;
const neon = 1;

export default {
    
    props: {
        panierItem : Object,
        last : {type : Boolean, default : false}
    },

    computed : {
        Size()
        {
            switch(this.panierItem.item_size)
            {
                case 0 : return 'XS'
                case 1 : return 'S'
                case 2 : return 'M'
                case 3 : return 'L'
            }
            return "XS";
        },
         ItemData()
        {
            if(this.type != neon)return {
                "Bande 3M" :  this.panierItem.item_bande3M ? 'Oui' : 'Non',
                "Télécommmande" :  this.panierItem.item_telecommande ? 'Oui' : 'Non',
                "Variante" :  this.panierItem.item_variante,
            };
            else{return {
                Couleur : this.panierItem.item_data_neon.color_display,
                Police : this.panierItem.item_data_neon.font_display,
                Support : this.panierItem.item_data_neon.support_display,
                "Couleur Support" : this.panierItem.item_data_neon.support_color_display,
                Longueur : this.panierItem.item_data_neon.width.toFixed(0) + "cm",
                Largeur : this.panierItem.item_data_neon.height.toFixed(0) + "cm",
                }}
        },
            DetailMenu()
        {
            
            if(this.see_det)return "max-height:" + this.$refs.desc.scrollHeight + 'px';
            return "max-height:40px"
        },
         Detail()
        {
            if(this.see_det)return "Voir moins"
            return "Voir le détail"
        },
         NeonText()
        {
            if(this.panierItem.neon != null)return "Neon "
            return "";
        }
    },
      data() {
        return {
            see_det : false,
            type : article,
            article : 0,
            neon : 1,
        }
    },
    methods: {
         HandleDataValue(value)
        {
           
            if(value ===true)return "Oui";
            if(value ===false)return "Non";
            if(value === null)return "Par défaut";
            return value;
        },
        ToggleMenu() {
            this.see_det = !this.see_det;
        },


        Moins()
        {
            this.$panierHandler.UpdateQuantity(-1, this.panierItem);
        },
        SetQuantity(event)
        {
            this.$panierHandler.SetQuantity(event.target.value, this.panierItem);
            
        },
        Plus()
        {
            this.$panierHandler.UpdateQuantity(1, this.panierItem);
        },
        Remove()
        {
            this.$panierHandler.RemoveArticleFromPanier(this.panierItem);
        }
      
    

    },
    created() {
        if(this.panierItem.item_variante == null)this.type = neon;
    },
    
}
</script>

<style scoped>
.item-selectable {
    cursor: pointer;
}

@media(min-width:472px)
{
    .product-item {
        grid-template-columns : 110px calc((100% - 110px)*0.6) calc((100% - 110px)*0.4)!important;
    }
}
.item-description {
    display: flex;
    align-items : baseline;
    overflow: hidden;
    flex-direction: column;
    justify-content :baseline;
    transition-duration : 0.2s;
}

.qtt-container {
    width : 27px;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    align-items : center;
    margin-right: 0.75rem;
    margin-left : auto;
}

.qtt-container > button {
    width : 24px;
    background-color : lightgray;
    height : 24px;
    border : none;
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;
}

.item-spec {
    color : #707070;
    margin : 0.1rem 0;
    font-size : 12px
}

.qtt-container > input {
    width : 24px;
    height : 24px;
    font-weight: 400;
    -moz-appearance: textfield;
    border : 1px solid #FFB8B8;
    text-align : center;
    outline : none;
}

.qtt-container > input::-webkit-outer-spin-button,
.qtt-container > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rainbow-bg {
    background-image: url('../assets/imgs/rainbow.jpg');
    background-size : cover;
}

.product-price {
    color:var(--amaranth);
    font-weight : bold;
    font-size : 17px;
}

.product-item {
    display: grid;
    position: relative;
    z-index: 1;
    grid-template-columns : 34% 51% 15%;
    font-family : 'Montserrat', sans-serif;
    width: 100%;
     padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 0.75rem;

}
.error-container {
    position : absolute;
    top : 0;
    z-index : 3;
    left : 0;
    width : 100%;
    height : 100%;
    display: flex;
    justify-content : center;
    align-items: center;
    background-color : rgba(160,160,160,0.8)
}

.error-container > span {
    color : var(--amaranth);
    font-family : 'Montserrat', sans-serif;
    font-size : 17px;
}

.product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content : space-between;
    }

.title-container {
    display: flex;
    align-items : center;
}

.title-container > span {
    font-size: 15px;
    text-align: left;
    font-weight: bold;
}

.title-container > button {
    background-color : #FFB8B8;
    position: relative;
    font-weight : 500;
    width: 22px;
    height: 22px;
    font-size: 12px;
    font-weight : bold;
    display : flex;
    outline : none;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    border: none;
}

</style>