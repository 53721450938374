<template>
    <div class="intent-container">
        <div class="intent-content">
            <div style="max-height:450px" id="panier" class="item-container">
                <h2>Mon Panier</h2>
                <div class="articles-container">
                    <div v-if="panierHandler.panier_loaded" class="products">

                   <PanierItemViewSmall v-for="(item,index) in panierHandler.panier_items" :key="item.item_storage_id" :panierItem="item" :last="index == panierHandler.panier_items.length-1" />

            </div>
            <div v-else class="loading-panier">
                <span class="loading-span">Chargement du panier</span>
            </div>
            <div v-if="panierHandler.panier_items.length == 0 && panierHandler.panier_loaded">
                <span class="empty-shop">Votre panier est vide</span>
            </div>
                </div>
                <div class="panier-prices">
                    <span>Sous-total : <span class="price-value">{{TotalPrice.toFixed(2)}}€</span></span>
                    <span v-if="coupon_used">Remise : <span class="price-value">-{{Discount.toFixed(2)}}€</span></span>
                    <span>Frais de port : <span class="price-value">{{LivraisonPrice == 0 ? "GRATUIT" : LivraisonPrice + "€"}}</span></span>
                    <span class="total">Total : <span class="price-value">{{TrueTotal.toFixed(2)}}€</span></span>
                </div>
            </div>
            <div class="other-items">
                <div class="item-container">
                    <h2>Adresse</h2>
                    <transition name="slide-fade" mode="out-in" appear>
                    <div v-if="accountsHandler.pending || !accountsHandler.currentUser.adresses_loaded" class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>
                    <div v-else class="w-100 d-flex align-items-center justify-content-center flex-column position-relative">
                        <img v-if="!NoActiveAdresse && (modif_adresse || new_adresse_mode)" src="../assets/imgs/back_arrow.svg" alt="" width="30px" height="30px" class="back-arrow scaleanim" @click="GoBack" srcset="">
                    <transition name="slide-fade" mode="out-in" appear>
                    f<adresse-form ref="adresse_form" @newAdresse="SubmitNewAdresse" :hidden_submit="true" :button="false" v-if="new_adresse_mode" :save="accountsHandler.currentUser != null && accountsHandler.currentUser.logged" />
                    </transition>
                    <div class="choose-container" v-if="modif_adresse && !new_adresse_mode">
                         <button  @click="EnterNewAdresseMode" class="account-button mt-2">Ajouter une nouvelle adresse</button>
                         <span class="mt-2" style="font-family:'DIN',sans-serif;font-size:1.2rem;">OU</span>
                         <button :class="{'button-disabled' : NoUserAdresses}" @click="ShowAdressesContainer" class="account-button mt-2">Choisir parmis les adresses enregistrées</button>
                    </div>


                    <div v-if="!new_adresse_mode && !modif_adresse" class=" first-item little-item px-5">
                        <h4 class="amar-title">{{checkoutHandler.current_adresse.name}}</h4>
                        <span class="little-span"><b>{{checkoutHandler.current_adresse.adresse}}</b></span>
                        <span  class="little-span text-uppercase"><b>{{checkoutHandler.current_adresse.zip_code}} {{checkoutHandler.current_adresse.city}}</b></span>
                        <span  class="little-span"><b>{{checkoutHandler.current_adresse.country}}</b></span>
                        <span v-if="checkoutHandler.current_adresse.num != ''" class="little-span mt-1">{{checkoutHandler.current_adresse.num}}</span>
                    </div>



                    <button v-if="!NoActiveAdresse && !modif_adresse" @click="EnterModifMode" class="account-button mt-3">Changer l'adresse de livraison</button>
                </div>
                    </transition>
                </div>
                <div class="item-container d-flex align-items-stretch w-100 flex-column mt-3">
                    <h2 class="mb-4">Email de contact</h2>
                    <input-view v-model="email" input_title="Email" :required="true"  />
                    <small class="w-100 mt-2">Adresse mail utilisée pour la commande conformément à nos <a href="">conditions d'utilisation</a></small>
                </div>
                <div class="item-container mt-3">
                    <h2>Mode de livraison
                        <img data-index="0" @click="HelpLivraison" src="../assets/imgs/help.svg" class="scaleanim" width="20" style="margin-left:0.5rem;opacity:0.5;pointer-events:all" />
                    </h2>
                    <span class="little-span">Nos colis sont livrés à domicile uniquement</span>
                    <div v-if="!livraison_loaded" class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>
                    <div v-else class="livraison-container first-item mb-3">
                        <div class="little-item livraison-item ">
                            <h4 class="amar-title mb-1" style="color:#2C3E50">NORMAL</h4>
                            <span class="amar-title" style="font-size:1.6rem">{{NormalPrice == 0 ? "GRATUIT" : NormalPrice + "€"}}</span>
                            <span class="little-span">3 semaines</span>
                            <div @click="SetLivraison(0)" :class="{'custom-checkbox-active' : livraison_mode == 0}" class="custom-checkbox  mt-2"><img src="../assets/imgs/check.svg" v-if="livraison_mode == 0" width="20" height="20" alt=""></div>
                        </div>
                        <div  class="little-item livraison-item " :class="{'disabled-livraison-item' : !express}">
                            <h4 class="amar-title mb-1" style="color:#2C3E50">Express</h4>
                            <span class="amar-title" style="font-size:1.6rem">INDISPONIBLE</span>
                            <span class="little-span">1 semaine</span>
                            <div @click="SetLivraison(1)" class="custom-checkbox  mt-2" :class="{'custom-checkbox-active' : livraison_mode == 1}">
                                <img  src="../assets/imgs/check.svg" v-if="livraison_mode == 1" width="20" height="20" alt="">
                            </div>
                        </div>
                    </div>
                </div>
              <div class="item-container d-flex align-items-stretch w-100 flex-column mt-3">
                <input-view v-model="promo_code" :disabled="coupon_used" input_title="Code promotionnel" :required="false"  />
                <button  :class="{'button-disabled' : promo_code == '' || coupon_used || checking_coupon}"  @click="TestPromoCode" class="account-button ">
                  <span v-if="!checking_coupon" >Appliquer le code</span>
                  <div v-else class="spinner"></div>
                </button>
                <small class="w-100 mt-2" style="color:var(--amaranth)">{{this.show_coupon_error ? coupon_error : ""}}</small>
              </div>
              <div class="w-100 flex-column d-flex mt-3 align-items-center justify-content-center">
                <check-view v-model="accept_cgv" :required="true" label="J'accepte les CGV et la politique de confidentialité" />
              </div>
                <span class="error-span mt-3 d-flex mx-auto" v-if="error">{{error_message}}</span>
                <button @click="LoadIntent" style="min-width:200px" :class="{'button-disabled' : email.length == 0 || loading_payment_intent || DisabledItemInPanier || !panierHandler.panier_loaded || !stripe_loaded || panierHandler.panier_items.length == 0 ||error }" class="article-panier-button">
                    <span v-if="panierHandler.panier_loaded && stripe_loaded" id="button-text">Accéder au paiement</span>
                <div v-else class="spinner" id="spinner"></div>
                    </button>
            </div>
        </div>
        <div class="panier-step">
            <div class="panier-step-line"></div>
            <div class="panier-step-item">
                <div>1</div>
                <span>Votre Panier</span>
                </div>
            <div class="panier-step-item">
                <div class="item-active">2</div>
                <span>Vos informations</span>
            </div>
            <div class="panier-step-item">
                <div>3</div>
                <span>Paiement</span>
            </div>
        </div>
        <account-modal ref="choose_add">
            <div class="modal-adresses-container">
                <div v-for="(adresse, index) in accountsHandler.currentUser.addresses" :key="index" @click="ExistingAdressePicked(adresse)"  class="little-item px-5 m-3">
                        <h4 class="amar-title">{{adresse.name}}</h4>
                        <span class="little-span"><b>{{adresse.adresse}}</b></span>
                        <span  class="little-span text-uppercase"><b>{{adresse.zip_code}} {{adresse.city}}</b></span>
                        <span  class="little-span"><b>{{adresse.country}}</b></span>
                        <span v-if="adresse.num != ''" class="little-span mt-1">{{adresse.addresse_num}}</span>
                    </div>
            </div>
        </account-modal>

        <account-modal ref="livraison_info">
            <p style="max-width:500px">
                Vous recevrez votre néon dans un délai de 3 semaines à compter de la date de réception de votre paiement. <br><br>
                Chaque création est <b>faite sur-mesure et entièrement à la main</b>. Il y a donc un temps de production. <br> <br>
                Si l’option est disponible, vous avez la possibilité de passer une commande <b>express</b> (7 jours).

            </p>
        </account-modal>
        <transition name="fade" appear>
         <div v-if="loading_payment_intent" class="loading-container d-flex flex-column align-items-center">
            <div  class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>
                    <span  >Chargement du tunnel de paiement sécurisé</span>

        </div>
        </transition>
         <transition name="slide-fade" appear>
       <div v-if="show_modal" class="paymodal-background">
            <div class="paymodal-container">
            <div class="paymodal-title" :class="{'error-bg' : modal_error}">{{modal_title}}</div>
            <div class="paymodal-content">{{modal_content}}</div>
            <button class="paymodal-button"  :class="{'error-bg' : modal_error}" @click="HideError">C'est noté</button>
        </div>
       </div>
       </transition>
    </div>
</template>

<script>
import AdresseForm from '../components/AdresseForm.vue';
import {Adresse} from '../components/renderless/CheckoutHandler'
import PanierItemViewSmall from '../components/PanierItemViewSmall.vue'
import AccountModal from '../components/AccountModal.vue'
import InputView from '../components/InputView.vue';
import CheckView from "../components/CheckView";
export default {

    components: {
      CheckView,
        PanierItemViewSmall,
        AdresseForm,
        AccountModal,
        InputView
    },


        watch: {
        'accountsHandler.currentUser.adresses_loaded' : 'HandleUser',
        'panierHandler.panier_loaded' : 'HandleLivraison',
        'stripeHandler.stripe' : 'HandleStripe',
          'promo_code' : 'ResetCouponError'
    },

    metaInfo : {
        title : 'Vos Informations',
        meta : [
            {name:"robots" , content : "noindex"}
        ]
    },

    data()
    {
        return {
            panierHandler : this.$panierHandler,
            accountsHandler : this.$accountsHandler,
            accept_cgv : false,
            stripeHandler : this.$stripeHandler,
            stripe_loaded : false,
            choosing_existing_add : false,
            new_adresse_mode : false,
            uploading_new_adresse : false,
            new_add_country : "FR",
            livraison_loaded : false,
            express : false,
            modif_adresse : false,
            loading_payment_intent : false,
            email : "",
            save : true,
            livraison_mode : 0,
            no_active : false,
            checkoutHandler : this.$checkoutHandler,
            error : false,
            error_message : "Erreur inconnue, veuillez vérifier votre connexion internet",
            promo_code : "",
            show_modal : false,
            modal_error : true,
            modal_title : "",
            modal_content : "",
          show_coupon_error : false,
          coupon_error : "",
          percent : 0,
          coupon_used : false,
          checking_coupon : false,
        }
    },
    computed: {


        NoUserAdresses()
        {
            return (!this.accountsHandler.currentUser.logged || this.accountsHandler.currentUser.addresses.length == 0)
        },

        NoActiveAdresse()
        {

            return (this.checkoutHandler.GetAdresse().country == "")
        },

        LivraisonPrice()
        {
            if(this.livraison_mode == 0 && this.TotalPrice < 49.99)return 7.99;
            else if(this.livraison_mode == 1)return 99.99;
            return 0.00;
        },

        NormalPrice()
        {
            if(this.livraison_mode == 0 && this.TotalPrice < 49.99)return 7.99;
            return 0.00;
        },

        DisabledItemInPanier()
        {
             for(let i = 0; i < this.panierHandler.panier_items.length; i++)
            {
                if(this.panierHandler.panier_items[i].disabled)return true;

            }
            return false;
        },
        TotalPrice()
        {
            let p = 0;
            for(let i = 0; i < this.panierHandler.panier_items.length; i++)
            {
                if(this.panierHandler.panier_items[i].disabled)return (0.0)
                p+= this.panierHandler.panier_items[i].total_price;
            }
            return parseFloat(p);
        },

        Discount()
        {
          return (this.TotalPrice*(parseFloat(this.percent)/100));
        },

        TrueTotal()
        {
            let p = this.TotalPrice;
            if(p<49.99 && this.livraison_mode == 0)p+=7.99;
            else if(this.livraison_mode == 1)p+= 99.99;
            p -= this.Discount;
            return p;
        }
    },

    methods : {


        async TestPromoCode()
      {
        this.checking_coupon = true;
        const id = this.promo_code.toUpperCase();
        const checkCoupon = this.$functions.httpsCallable('CheckCoupon');
        const result = await checkCoupon({coupon_id : id});
        console.log(result.data);
        if(result.data.error == undefined)
        {
          this.applyCoupon(result.data.percent, id);
        }
        else {
          this.coupon_error = result.data.error.message;
          this.show_coupon_error = true;
        }
        this.checking_coupon = false;
      },

      applyCoupon(percent, id)
      {
        this.coupon_used = true;
        this.percent = percent;
        this.checkoutHandler.SetCoupon(id);
        window.scrollTo(0,0);
      },

      HandleStoredCoupon()
      {
        if(this.checkoutHandler.GetCoupon() != "")
        {
          this.promo_code = this.checkoutHandler.GetCoupon();
          this.TestPromoCode();
        }
      },

      ResetCouponError()
      {
        this.show_coupon_error = false;
        this.promo_code = this.promo_code.toUpperCase();
      },

        HandleStripe()
        {
            if(this.stripeHandler.stripe)this.stripe_loaded = true;
        },

        CheckEmail(email)
        {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
        },

        ShowError(title, text)
        {
            this.modal_title = title;
            this.modal_error = true;
            this.modal_content = text;
            this.show_modal = true;
        },

        HideError()
        {
            this.show_modal = false;
        },

        LoadIntent()
        {

            if(this.new_adresse_mode)
            {
              const element  = this.$refs.adresse_form;
              console.log(element)
              element.$refs.hidden_button.click()
            }
            // click should pass this variable to true if correct
            if(this.new_adresse_mode)return;
            const email_ok = this.CheckEmail(this.email);
            if(!email_ok)
            {
                this.ShowError("Email invalide", "L'adresse email renseignée n'est pas valide");
                return;
            }
            if(!this.accept_cgv)
            {
              this.ShowError("Erreur", "Vous devez accepter les conditions générales de ventes");
              return;
            }
             this.loading_payment_intent = true;

            this.stripeHandler.GetPaymentIntent(this.panierHandler.panier_items, this.checkoutHandler.ToParams(), this.email).then((result) => {

                if(result.state == true)
                {
                    this.$router.push({name : 'pay'})
                }
                else {
                     this.ShowError(result.error.code, result.error.message)
                }
                this.loading_payment_intent = false;
            }).catch((err) => {
                this.ShowError(err.code, err.message)
                this.loading_payment_intent = false;
            })
        },

        SetLivraison(value)
        {

            if(value == 0)this.checkoutHandler.SetLivraison('normal');
            else this.checkoutHandler.SetLivraison('express');
            this.livraison_mode = value;
        },

        HelpLivraison()
        {
            this.$refs.livraison_info.Reveal("Livraison")
        },

        HandleLivraison()
        {

           if(!this.panierHandler.panier_loaded)return;
            this.panierHandler.CheckExpress().then((val) => {

                this.express = val;
                this.livraison_loaded = true;
            }).catch((err) => {
                console.log(err);
                this.error_message = "Impossible de charger les modes de livraison"
                this.error = true;
            })
        },

        HandleUser()
        {
            if(this.accountsHandler.currentUser.logged)
            {
                this.email = this.accountsHandler.currentUser.email
            }

          if(this.checkoutHandler.GetAdresse().country != ""){this.new_adresse_mode = false;return;}
            if(!this.accountsHandler.currentUser.logged || this.accountsHandler.currentUser.addresses.length == 0)this.new_adresse_mode = true;
            else {
                const adresse = this.accountsHandler.currentUser.addresses.filter((elem) => {return elem.active})[0];
                if(adresse == undefined){this.modif_adresse = true;this.new_adresse_mode=false}
               else {
            this.checkoutHandler.SetAdresse(adresse)
            this.new_adresse_mode = false;}
            }
        },

        ExistingAdressePicked(adresse)
        {
            this.checkoutHandler.SetAdresse(adresse)
            this.$refs.choose_add.Hide();
            this.modif_adresse = false;

        },

        ShowAdressesContainer()
        {
            this.$refs.choose_add.Reveal("Vos adresses")
        },

        SetAdresse(json_adresse)
        {
             const adresse = new Adresse(
            json_adresse.addresse_country,
            json_adresse.addresse_city,
            json_adresse.addresse,
             json_adresse.addresse_code,
            json_adresse.addresse_name,
            json_adresse.addresse_num,
            json_adresse.addresse_comp,


            )
            this.checkoutHandler.SetAdresse(adresse)
        },

          ValidateNewAddForm()
        {
            let validate = true;
            if(!this.$refs.addresse_name.ValidateInput())validate = false;
            if(!this.$refs.addresse_loc.ValidateInput())validate = false;
            if(!this.$refs.addresse_city_num.ValidateInput())validate = false;
            if(!this.$refs.addresse_city.ValidateInput())validate = false;

            return validate;
        },

        EnterNewAdresseMode()
        {
            this.new_adresse_mode = true;
        },
        ExitNewAdresseMode()
        {
            this.new_adresse_mode = false;
        },

        EnterModifMode()
        {
            this.modif_adresse = true;
        },
        ExitModifMode()
        {
            this.modif_adresse = false;
        },

        GoBack()
        {
            if(this.new_adresse_mode){this.new_adresse_mode = false;return}
            if(this.modif_adresse){this.modif_adresse = false;return}
        },

        SubmitNewAdresse(adresse, save)
        {
            this.uploading_new_adresse = true;
            if(!this.accountsHandler.currentUser.logged || !save)
            {this.checkoutHandler.SetAdresse(adresse)
            this.uploading_new_adresse = false;
             this.ExitModifMode();
            this.ExitNewAdresseMode();
            }
            else {
                this.accountsHandler.AddAdresse(adresse
            ).then(() => {
                this.checkoutHandler.SetAdresse(adresse)
                this.uploading_new_adresse = false;
                this.ExitModifMode();
                this.ExitNewAdresseMode();
            }).catch((err) => {
                console.log(err);
                this.checkoutHandler.SetAdresse(adresse)
                this.uploading_new_adresse = false;
                this.ExitModifMode();
                this.ExitNewAdresseMode();
            })
            }
        },
    },

      beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:var(--white-break)')
  },
  beforeDestroy () {
    document.querySelector('body').setAttribute('style', '')
  },
  created () {
      this.HandleUser();
      this.HandleLivraison();
      this.HandleStripe();
      this.HandleStoredCoupon();
  },
  mounted()
  {

  }

}
</script>

<style scoped>

.account-button {
    min-height: 48px;
    border: none;
    width: 100%;
    background-color: var(--pretty-green);
    color: white;
    border-radius: 4px;
    padding: 0 1rem;
    outline: none!important;
    transition-duration: 0.4s;
}


.adresse-content {
    display : flex;
    width : 100%;
    justify-content: space-between;
}

.adresse-content > label:first-child {
    width : 48%;
}

.adresse-content > label:last-child {
    width:50%
    }

@media (max-width:1200px)
{
    .intent-content {
        display: flex!important;
        flex-direction: column;
        align-items: center;
    }

    #panier {
        display: none!important;
    }
}

.paymodal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
    z-index : 2;
}

.paymodal-button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.paymodal-button:hover {
  filter: contrast(115%);
}

.paymodal-content {
    padding: 1rem;
    font-family : 'Montserrat', sans-serif;
    color: white;
    max-width : 500px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymodal-container {
    background-color: #003049;
    border-radius : 4px;
    overflow: hidden;
    min-width : 310px;
}

.error-bg {
    background-color: #D62828!important;
}



.paymodal-title {
    background-color: #27AE60;
    padding: 0.5rem 1rem;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size : 1.5rem;
}

.paymodal-button {
    background-color: #27AE60;
    color : white;
    margin-left: auto;
    width: auto;
    margin-right : 1rem;
    margin-bottom : 1rem;
    border-radius : 4px;
}

.card-info-container {
    display: flex;
}

.loading-container {
    position : fixed;
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    justify-content : center;
    background-color: lightgray;
    z-index : 11;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: gray;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: gray;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



.error-span {
    color : var(--amaranth);
    font-weight : bold;
}

.empty-shop {
    font-family: 'Montserrat', sans-serif;
    color : darkgray;
    width : 100%;
    height: 50px;
    margin-bottom : 1rem;
    display : flex;
    justify-content: center;
    align-items: center;
}

.checkbox-container {
    display: flex;
    align-self: flex-start;
    align-items: center;
    font-family : "Montserrat", sans-serif;
    font-size : 14px;
}

.checkbox-container > input {
     -webkit-appearance: checkbox!important;
    appearance: checkbox!important;
}

.back-arrow {
    position : absolute;
    top : 0;
    left : 1rem;
}

.modal-adresses-container {
    display: flex;
    max-width : 95%;
    width: 600px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

.button-disabled {
    background-color: grey!important;
    border: 1px solid gray!important;
    color: lightgray!important;
    pointer-events: none;
}

.choose-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
}



@keyframes ldio-r8h14oufgyb {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-r8h14oufgyb div {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 5px solid #6a6a6a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-r8h14oufgyb div {
  animation: ldio-r8h14oufgyb 1s ease infinite;
  top: 50px;
  left: 50px
}
.loadingio-spinner-rolling-l8pfz6cbtd {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-r8h14oufgyb {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-r8h14oufgyb div { box-sizing: content-box; }


.loading-panier {
   display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    color : gray;
    width: 100%;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 0.75rem;
    position: relative;
}


.form-container > span {
    font-size : 0.8rem;
    color : black;
}


.account-error {
    color: var(--amaranth)!important;
    text-align: left;
    font-size: 14px;
    align-self : flex-start;
    min-height : 21px;
    margin-top : 0.25rem;
}



.form-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: center;
    min-width: 250px;
    align-items: flex-start;
}


.loading-span:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}
@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #2c3e50;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 #2c3e50;}}


.loading-span {
    width : 100%;
    height : 100%;
    display : flex;
    position: absolute;
    font-weight: 500!important;
    top: 0;
    left:0;
    justify-content : center;
    align-items : center;
    text-align: center;
    font-size : 1.2rem;
    font-weight: bold;
}

.products {
    margin-top:0.75rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}


.article-panier-button {

    color : var(--amaranth);
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color: var(--white-break);
    text-transform: uppercase;
    border : 1px solid var(--amaranth);
    padding : 0.75rem 1rem;
    margin : 1rem auto;
    transition-duration: 0.2s;
    height : 50px;
    margin-top: 2rem;
}

.article-panier-button:hover {
    background-color : var(--amaranth);
    color : var(--white-break);


}

.livraison-item {
    min-width : 125px;
    margin : 0 0.75rem;
}

.custom-checkbox {
    width: 30px;
    height : 30px;
    border-radius : 30px;
    border : 1px solid var(--pretty-green);
    display : flex;
    justify-content: center;
    align-items: center;

}

.custom-checkbox-active {
    background-color : var(--pretty-green);
}

.livraison-container {
    display: flex;
    justify-content: center;
}

.first-item {
    margin-top : 1.5rem;
}


.little-span {
    font-size : 14px;
}


.amar-title {
    font-family: 'DIN', sans-serif ;
    font-size : 1.4rem;
    color : var(--amaranth);
    text-transform: uppercase;

    }

.total {
    font-weight : bold;
    font-size : 1.3rem;
    }


.little-item {
    background-color : var(--white-break)!important;
    border-radius : 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    display : flex;
    flex-direction: column;
    align-items: center;
    padding : 1rem 0.75rem;

    cursor : pointer;
    transition-duration: 0.2s;
    transition-timing-function: ease;
}
.little-item:hover {
 transform : scale(1.05);

}

.price-value {
    font-weight: bold;
    color : var(--amaranth);
}

.panier-prices {
    display: flex;
    margin-top : 1rem;

    align-self: flex-end;
    flex-direction : column;
}

.panier-prices > span {
    text-align: right;
    margin : 0.25rem 0;
}

.intent-container {
    margin-top : 5rem;
    position : relative;
    background-color :var(--white-break);
    display : flex;
    align-items : center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    width : 100%;
    justify-content : center;
}
.item-active {
    background-color : antiquewhite!important;
}


/* width */
.articles-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.articles-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.articles-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.articles-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.articles-container {
    min-height: 50px ;
    max-height: 230px;
    overflow-y:  auto;
    overflow-x: hidden;
    position : relative;
    width : 100%;
    border-top : 1px solid black;
    border-bottom : 1px solid black;
    padding-top : 0.25rem;
    margin-top : 1.5rem;
    display : flex;
    flex-direction : column;
}

.intent-content {
    width : 100%;
    max-width: 1100px;
    margin : 3rem 0;
    display: grid;
    grid-template-columns : 50% 50%;
    grid-gap : 2rem;
}

.item-container {
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius : 4px;
    padding : 1rem;
    display: flex;
    flex-direction : column;
    align-items : center;
}

.item-container > h2 {
    font-size : 1.5rem;
    font-weight : bold;
    margin-bottom : 0;
}

.disabled-livraison-item {
        color: lightgray!important;
    pointer-events: none;
    background-color: darkgray!important;
}

.custom-input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  max-width : 340px;
  outline : none!important;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}



</style>
