<template>
    <label ref="input_box" class="mdc-text-field mdc-text-field--filled" :class="{'dark-mode' : dark, 'mb-0' : noMargin, 'mdc-text-field--disabled' : disabled}">
  <span class="mdc-text-field__ripple"></span>
  <span class="mdc-floating-label" id="my-label-id">{{input_title}}</span>
  <input  @input="handleInput" :disabled="disabled" :minlength="minLength"  :pattern="pattern" :aria-required="required" :value="value" class="mdc-text-field__input" :type="input_type" :required="required" aria-labelledby="my-label-id">
  <span class="mdc-line-ripple"></span>
</label>
</template>

<script>

/**
 *  <div class="form-container">
        <span>{{input_title}}</span>
        <input @input="RemoveErrors" ref="input" v-model="value" class="form-control" :type="input_type" :placeholder="placeholder">
        <span v-for="(error, index) in errors_message" :key="index" v-show="local_errors[index]" class="account-error">{{error}}</span>
    </div>
 */

import {MDCTextField} from '@material/textfield';
export default {
    
    props:{
        disabled : {type : Boolean, default : false},
        input_type : {type : String, default : "text"},
        pattern : {type : String, default : ".*"},
        minLength : {type : Number, default : 0},
        input_title : String,
        required : {type : Boolean, default : false},
        noMargin : {type : Boolean, default : false},
        value : String,
        placeholder : String,
        dark : {type : Boolean, default : false},
        errors : {type : Array, default : function() {return [false]}},
    },
    data()
    {
        return {
            content : this.value,
            local_errors : [...this.errors],
            errors_message : ['Ce champ est obligatoire', "L'adresse mail est invalide.", "le mot de passe est trop faible.", "Les mots de passe ne correspondent pas.", "Veuillez taper un nombre valide"],
            textField : null,
        }
    },

    methods: {

       

        handleInput (e) {
            
            this.$emit('input', e.target.value)
            
        },
        
        
        },
        mounted()
        {
            this.textField = new MDCTextField(this.$refs.input_box);
        }
}
</script>

<style scoped lang="sass">

@use "@material/floating-label/mdc-floating-label"
@use "@material/line-ripple/mdc-line-ripple"
@use "@material/notched-outline/mdc-notched-outline"
@use "@material/textfield"

@include textfield.core-styles

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label 
   color : var(--pretty-green)





.form-container > span
    font-size : 0.8rem
    color : black



.account-error 
    color: var(--amaranth)!important
    text-align: left
    font-size: 14px
    align-self : flex-start
    min-height : 21px
    margin-top : 0.25rem




.form-container 
    display: flex
    flex-direction: column
    margin-bottom: 1rem
    justify-content: center
    min-width: 250px
    align-items: flex-start

.dark-mode
    background-color : #c9d1d9!important

</style>