<template>
    <div class="images-container">
        <span>Cliquez sur le fond pour le selectionner.</span>
        <div ref="container" :id="id" class="images-zone">
            <little-image v-for="(background, index) in backgrounds" :ref="index" :key="index"  @click.native="SetBackground(index)" :url="background.url"/>
        </div>
    </div>
</template>

<script>
import LittleImage from './LittleImage';




export default {
    name : 'ChooseBackground',
    props: {
        parent :Object,
        id : String,
        backgrounds : Array
    },
    components: {
        LittleImage,
    },
    methods : {

        


        getFile : function(root) {

            const storageRef = this.$storage.ref(root + '/');
            const t = this;
            storageRef.list().then(function (result) 
            {
                result.items.forEach((item) => {
                storageRef.child(item.name).getDownloadURL().then((url)=> {
                t.CreateLittleImage(url);  
                        })
                    })

                })
                
            
            
        },

        SetBackground : function(index)
        {
            
            this.parent.SetBackground(index);
        },
        ShowAddBackground : function()
        {
            this.parent.ShowImageAdder();
        }


    },
}
//TYPE 1 : avec un étalon
//TYPE 0 : un etalon a definir
//TYPE -1 : jamais d'etalon
//type color : une couleur
export class Background {
    constructor(url = "", ratio = 0.2, type = 0)
    {
        this.url = url;
        this.ratio = ratio;
        this.x = 0;
        this.y = 0;
        this.size = 1;
        this.save = [0,0,0];
        this.type = type;
    }

     SetPosition(x,y)
        {
            this.x = x 
            this.y = y;
        }
        GetX()
        {
            return this.x;
        }
        GetY()
        {
            return this.y;
        }
        Save()
        {
            this.save[0] = this.x;
            this.save[1] = this.y;
            this.save[2] = this.size;
        }
        Revert()
        {
            this.x = this.save[0];
            this.y = this.save[1];
            this.size = this.save[2];
           
        }
}

export class BackgroundColor
{
    constructor(color = "black", ratio = 0.15)
    {
        this.type = "color";
        this.color = color
        this.x = 0;
        this.ratio = ratio;
        this.y = 0;
    }
}
</script>

<style scoped>

.images-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.images-container > span {
    font-size : 1rem;
    color : black;
    margin : 0.75rem;
}

.images-zone {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.images-zone > div{
    transition-duration: 0.2s;
    
}

.images-zone > div:hover {
    transform: scale(1.1);
}

</style>