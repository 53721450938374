import {loadStripe} from '@stripe/stripe-js';

export default class StripeHandler
{
    constructor(functions)
    {
        this.stripe = null;
        this.error = null;
        this.functions = functions;
        this.payed = false;
        this.payment_intent = null;
        const instance = this;
        this.LoadStripe().then((stripe) => {
            instance.stripe = stripe;
        }).catch((err) => {
            instance.error = err;
        })
    }

    async LoadStripe()
    {
        const stripe = await loadStripe('pk_live_51JdD68I3I4VBw0ZGIMNjZ5kdetPxLUqlVsGDNc2fW59JmrujC8JeNnGDvnVBLr3UyAqQSFytNbJ3sK7f0Nw56CYW00nVsmY3An');
        return stripe;
    }

    GetPaymentIntent(panier_items, params, email)
    {
        let products = [];
        this.payed = false;
        
        for(let i = 0; i < panier_items.length; i++)
        {
            products.push(panier_items[i].ToJSONProduct())
        }
        const getPaymentIntent = this.functions.httpsCallable('CreatePaymentIntent');
        return new Promise((resolve, reject) => {
            getPaymentIntent({products : products, params : params, email : email}).then((result) => {
                const data = result.data;
                if(data.state)
                {
                    this.payment_intent = data;
                }
                resolve(data);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    /** "billing_details": {
    "address": {
      "city": "compienge",
      "country": "FR",
      "line1": "azd",
      "line2": "azd",
      "postal_code": "30200",
      "state": ""
    },
    "email": "jenny@example.com",
    "name": null,
    "phone": "+15555555555"
  }, */

  /**  this.country = country
        this.city = city;
        this.adresse = adresse;
        this.zip_code = zip_code;
        this.name = name ;
        this.num = num;
        this.options = options; */

    PayWithSavedCard(cvc, key, method)
    {

        return this.stripe.confirmCardPayment(key, {
            payment_method: method.id,
            payment_method_options: {
              card: {
                cvc: cvc
              }
            },})
    }

    PayWithCard(card, key, facturation_adresse, save_card = false)
    {
    
        if(save_card)save_card = 'on_session'
        const billing_details = {
            name : facturation_adresse.name,
            phone : facturation_adresse.num,
            address : {
                city : facturation_adresse.city,
                country : facturation_adresse.country,
                line1 : facturation_adresse.adresse,
                line2 : facturation_adresse.options,
                postal_code : facturation_adresse.zip_code,
            }
        }
        let json = {
        payment_method : {
            card : card,
            billing_details : billing_details
        },
        setup_future_usage  : save_card}
        if(json.setup_future_usage == false)delete json.setup_future_usage;
        if(billing_details.phone == "")delete billing_details.phone;
        if(billing_details.line2 == "")billing_details.line2 =  billing_details.line1;
        return this.stripe.confirmCardPayment(key, 
            
                json
            
            )
    }
}