export  class Neon {
    constructor(neon_id, dimension_id, font_id,font, font_ratio, support_id, text, text_aligment, color, support_color_id, unit_price)
    {
        this.width = 0;
        this.height = 0;
        this.neon_id = neon_id;
        this.neon_id_storage = Math.GenerateHash(Date.now());
        this.dimension_id = dimension_id;
        this.font_id = font_id;
        this.font = font;
        this.font_ratio = font_ratio;
        this.font_h_ratio = 0;
        this.support_id = support_id;
        this.text = text;
        this.text_aligment = text_aligment;
        this.color = color;
        this.unit_price = unit_price;
        this.support_color_id = support_color_id;
        this.options = new Options();
        this.message = "";
        this.support_display = "";
        this.color_display = "";
        this.font_display = "";
        this.support_color_display = "";

    }
    SetNeonId(id)
    {
        this.neon_id = id;

    }

    ExportToJSON()
    {
        return {
            neon_id_storage : this.neon_id_storage,
            dimension_id : this.dimension_id,
            font : this.font,
            font_display : this.font_display,
            support_id :this.support_id,
            support_display : this.support_display,
            text : this.text,
            alignment : this.text_aligment,
            color : this.color,
            color_display : this.color_display,
            support_color_id : this.support_color_id,
            support_color_display : this.support_color_display,
            options : this.options.ExportToJSON(),
            message : this.message,
            width : this.width,
            height : this.height,
        }

    }

}


export class Options {
    constructor(prise_id = 0, telecommande = false,neon_type_id = 0,fil_id = 0)
    {
        this.prise_id = prise_id;
        this.telecommande = telecommande
        this.neon_type_id = neon_type_id;
        this.neon_type_display = "";
        this.fil_id = fil_id;
    }

    ExportToJSON()
    {
        return {
            prise_id : this.prise_id,
            telecommande : this.telecommande,
            neon_type_id : this.neon_type_id,
            neon_type_display : this.neon_type_display,
            fil_id : this.fil_id,
        }
    }
}
