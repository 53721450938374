export class GalerieImage {
    constructor(firebase_url = "", bandeau = false, name = "")
    {
        this.firebase_url = firebase_url;
        this.name = name;
        this.bandeau = bandeau;
    }
    
}

export class GalerieItem {
    constructor(title = "", galerie_images = [], content = "", priority = 0)
    {
        this.title = title;
        this.galerie_images = galerie_images;
        this.content = content;
        this.priority = priority;
       

    }
}

const GalerieItemConverter = {
    toFirestore : function(galerie_item)
    {
        return galerie_item.ExportToJSON();
    },

    fromFirestore : function(snapshot, options)
    {
        const data = snapshot.data(options);
        const content = data.content;
        const title = data.title;
        const galerie_images_json = data.galerie_images;
        const priority = data.priority;
        let galerie_images = [];
        for(let i = 0; i < galerie_images_json.length; i++)
        {
            let t = new GalerieImage(galerie_images_json[i].firebase_url,galerie_images_json[i].bandeau, galerie_images_json[i].name)
            galerie_images.push(t);
        }
        return new GalerieItem(title, galerie_images, content, priority);
    }
}


export default class GalerieHandler {

    constructor(firestore)
    {
        this.firestore = firestore;
        this.galerie_items = [];
       
    }

    GetGalerieItems()
    {
        return new Promise((resolve, reject) => {
            this.firestore.collection("Galerie").withConverter(GalerieItemConverter).get().then((querySnapshot) => {
                let array = []
                querySnapshot.forEach((doc) => {
                    array.push(doc.data());
                })
                array.sort((a, b) => {
                    const p1 = parseInt(a.priority);
                    const p2 = parseInt(b.priority);
                   
                    if(p1 == 0)return 1;
                    if(p2 == 0)return 0;
                    return (p1 - p2)
                })
                this.galerie_items = array;
                resolve();
            }).catch((err) => {reject(err)})
        })
    }
}