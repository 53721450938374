<template>
    <div class="reset-login-container">
        <h2 class="mt-3 mb-2"><b>Réinitialisation du mot de passe</b></h2>
        <div class="reset-login-content">
            <span style="font-size:14px">Veuillez renseigner votre adresse e-mail afin de recevoir votre nouveau mot de passe.</span>
            <div class="input-container">
                <span>Email</span>
                <input v-model="email" type="email" name="" class="form-control" id="">
                <span v-for="(error,index) in errors" :key="index" class="login-error" v-show="errors_status[index]">{{error}}</span>
            </div>
            <div class="d-flex">
            <button :class="{'button-disabled' : submiting_email}" @click="SubmitReset" class="article-panier-button mr-2">Envoyer</button>
            <button :class="{'button-disabled' : submiting_email}" @click="goTo('/Login')" class="article-button-black article-panier-button " style="width:170px">Retour à la connexion</button></div>
        </div>
        <mini-modal @modalquit="ModalQuit" ref="info">
          <span class="login-info px-2">{{message}}
          </span> 
        

          
        </mini-modal>
    </div>
    
</template>

<script>
import MiniModal from '../components/MiniModal.vue';
export default {
    components : {
        MiniModal
    },
    data()
    {
   
             return {
            email : "",
            accountsHandler : this.$accountsHandler,
            errors : ['Veuillez remplir le champ', "Veuillez renseigner une adresse e-mail valide"],
            errors_status : [false, false],
            message : "",
            submiting_email : false,
        }
    },

    methods : {

          metaInfo : {
        title : 'Reinitialiser mot de passe',
        meta : [
            {name:"robots" , content : "noindex"}
        ]
    },
        
         goTo : function(path)
        {
         
            
            if(path == this.$route.path)return;
            window.scrollTo(0,0);
            this.$router.push(path);
            this.show = false;
        },
        
        ModalQuit()
        {
            if(this.submiting_email)
            {
                this.$router.push('/Login');
            }
        },

        ValidateReset()
        {
            this.errors_status = [false, false];
            if(this.email == ""){this.errors_status[0] = true;return false}
            if(!this.CheckEmail(this.email)){this.errors_status[1] = true;return false}
            return true;
        },
         CheckEmail(email)
        {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
        },

        SubmitReset()
        {
            const validate = this.ValidateReset();
            if(!validate)return;
            this.submiting_email = true;
            this.accountsHandler.ResetPassword(this.email).then(()=> {
                this.message = "Un email vous a été envoyé, vous allez être redirigé vers la page de connexion";
                this.$refs.info.Reveal("Connexion réussie", "", 460, "#16a085");
            }).catch((err) => {
               switch(err.code) {
                    default : 
                    this.message = "Erreur inconnue, veuillez réessayer dans quelques instants";this.sign_errors.password = true;
                    break;
                    case "auth/user-not-found" : 
                    this.message = "Ce compte n'existe pas";
                    break;
                }
                this.submiting_email = false;
                 this.$refs.info.Reveal("Erreur", "", 460, "var(--amaranth)");
            })
        }
    }
}
</script>

<style scoped>

.login-info {
    color : black;
}

.button-disabled {
    background-color : gray!important;
    color : lightgray!important;
    border : 1px solid gray!important;
    pointer-events: none;

}

.login-error {
    color: var(--amaranth);
    text-align: left;
    font-weight: 500!important;
    align-self : flex-start;
    
    margin-top : 0.25rem;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top : 2rem;
    min-width : 300px;
}

.input-container > span {
    font-size: 13px;
    font-weight: bold;
}

.reset-login-content {
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
}

.reset-login-container{
    margin-top : 5rem;
    position: relative;
    height : 70vh;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction : column;
    background-color : var(--white-break)
}



.article-panier-button {
    
    color : var(--amaranth);
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.1rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color: var(--white-break);;
    border : 1px solid var(--amaranth);
    width : 100px;
    transition-duration: 0.2s;
    height : 40px;
    margin-top: 2rem;
}

.article-panier-button:hover {
    background-color : var(--amaranth);
    color : var(--white-break);
    

}

.article-button-black {
    background-color: black;
    color :white;
    border : 1px solid black;
}


.article-button-black:hover {
    background-color: white;
    color : black;
}

</style>