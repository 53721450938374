<template>
    <div style="padding-top:2rem" class="panier-container">
        <div class="panier-title-container">
            <h2 class="panier-title">Mon Panier</h2>
             <transition name="slide-fade" mode="out-in">
            <span> <img src="../assets/imgs/heart_b.svg" @click="ShowAskName" width="25" height="25" class="mr-2 scaleanim" /> Sauvegarder pour plus tard</span>
            </transition>
        </div>
        <div class="products-container">
            <div class="products-title">
                <span style="text-align: left;">Produit</span>
                <span style="text-align: left;">Description</span>
                <span class="unit">Prix unitaire</span>
                <span>Quantité</span>
                <span>Prix total</span>
            </div>
            <div v-if="window_width > 560 && panierHandler.panier_loaded" class="products">
                
              <PanierItemView v-for="item in panierHandler.panier_items" :key="item.item_storage_id" :panierItem="item" />
               
                <div class="no-product" v-if="NoItemsInPanier">
                    <span>Aucun article ajouté</span>
                </div>
            </div>
            <div v-else-if="panierHandler.panier_loaded" class="products">
             
                  <PanierItemViewSmall v-for="(item,index) in panierHandler.panier_items" :key="item.item_storage_id" :panierItem="item" :last="index == panierHandler.panier_items.length-1" />
              
                <div class="no-product" v-if="NoItemsInPanier">
                    <span>Aucun article ajouté</span>
                </div>
            </div>
            <div v-else class="loading-panier">
                <div  class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>
            </div>

           <div  class="bottom-container">
                <transition name="slide-fade" mode="out-in">
               <div v-if="accountsHandler.currentUser.logged && accountsHandler.currentUser.saved_panier.length != 0" class="import-container">
                   <span>Vos paniers sauvegardés :</span>
                   <select @change="ImportPanier" v-model="saved_panier_index" class="form-control" name="" id="">
                        <option value="-1" selected disabled hidden>Choisir un panier</option>
                        <option v-for="(panier, index) in accountsHandler.currentUser.saved_panier" :key="index" :value="index" >{{panier.id}}</option>

                   </select>
               </div>
                </transition>
                <div class="total-container">
            <span>Total (TTC) :</span>
            <span class="ml-md-5 promo" style="min-width:100px">{{TotalPrice}}€</span>
        </div>
           </div>
        </div>

        <div class="info-container">
            <span v-if="!FreeLiv">plus que <span class="promo">{{BeforeFreeLiv.toFixed(2)}}€</span> pour bénéficier de la livraison gratuite !</span>
            <span v-else>Vous bénéficiez de la livraison <span class="promo">gratuite</span> !</span>
        </div>

        <div class="button-container">
            
            <button class="article-panier-button" @click="GoInfo" :class="{'button-disabled' : NoItemsInPanier || DisabledItemInPanier || !panierHandler.panier_loaded }">Valider le panier</button>

        </div>
        <transition name="slide-fade" mode="out-in" appear>
        <span v-if="ContainNeonSim" class="info-span">Toutes les dimensions indiquées par le simulateur le sont à titre provisoire, nos équipes ajusteront  si nécessaire la dimension de votre néon et reviendront vers vous le cas échéant</span>
        </transition>
        <div class="panier-step">
            <div class="panier-step-line"></div>
            <div class="panier-step-item">
                <div :class="{'item-active ' : panier}">1</div>
                <span>Votre Panier</span>
                </div>
            <div class="panier-step-item">
                <div>2</div>
                <span>Vos informations</span>
            </div>
            <div class="panier-step-item">
                <div>3</div>
                <span>Paiement</span>
            </div>
        </div>
        <mini-modal ref="ask">
           <div class="ask-panier-container">
               <span class="mb-2">Vous pourrez retrouver votre Panier dans votre compte client !</span>
                <input-view ref="panier_name" placeholder="Super panier" input_type="text" input_title="Nom du Panier" />
                
                <button @click="SavePanier" :class="{'button-disabled' : saving_panier }"  class="article-panier-button mt-1" >Enregistrer mon panier</button>
           </div>
        </mini-modal>
        <mini-modal ref="go_login">
           <div class="ask-panier-container">
               <span class="mb-2">Pour utiliser cette fonctionnalité, vous devez vous connecter ou créer un compte.</span>
                
                
                <button @click="GoLogin" class="article-panier-button mt-1" >J'y vais !</button>
           </div>
        </mini-modal>
        <mini-modal @modalquit="ModalQuit" ref="info"><span style="color:black;letter-spacing:normal;padding:1rem">{{message}}</span></mini-modal>

    </div>
</template>

<script>
import PanierItemViewSmall from '../components/PanierItemViewSmall.vue'
import PanierItemView from '../components/PanierItemView.vue'
import MiniModal from '../components/MiniModal.vue'
import InputView from '../components/InputView.vue'
export default {
    data() {
        return {
            panierHandler : this.$panierHandler,
            accountsHandler : this.$accountsHandler,
            saved_panier_index : -1,
            window_width : 2000,
            panier : true,
            info : false,
            pay : false,
            saving_panier : false,
            panier_saved : false,
            message : "",
            
        }
    }, 

    metaInfo : {
        title : 'Votre Panier',
        meta : [
            {name:"robots" , content : "noindex"}
        ]
    },
    components: {
        PanierItemViewSmall,
        PanierItemView,
        MiniModal,
        InputView,
    }, 
    computed: {

        ContainNeonSim()
        {
            for(let i = 0; i < this.panierHandler.panier_items.length; i++)
            {
                if(this.panierHandler.panier_items[i].item_data_neon != null)return true;
            }
            return false;
        },

        FreeLiv()
        {
            return this.TotalPrice > 49.99
        },
        BeforeFreeLiv()
        {
            return 49.99 - this.TotalPrice;
        },

        TotalPrice()
        {
            let p = 0;
            for(let i = 0; i < this.panierHandler.panier_items.length; i++)
            {
                if(this.panierHandler.panier_items[i].item_disabled)return "00.00"
                p+= this.panierHandler.panier_items[i].total_price;
            }
            return p.toFixed(2);
        }, 
        NoItemsInPanier()
        {
            return (this.panierHandler.panier_items.length == 0)
        },
        DisabledItemInPanier()
        {
             for(let i = 0; i < this.panierHandler.panier_items.length; i++)
            {
                if(this.panierHandler.panier_items[i].item_disabled)return true;
                
            }
            return false;
        }
    }, 
     created() {
        window.addEventListener("resize", this.ResizeHandler);
        this.window_width = window.innerWidth;
        
    },
    destroyed() {
        window.removeEventListener("resize", this.ResizeHandler);
    },

     beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:var(--white-break)')
  },
  beforeDestroy () {
    document.querySelector('body').setAttribute('style', '')
  },
    methods: {

        ModalQuit()
        {
            if(this.saving_panier)
            {
                this.saving_panier = false;
                this.HideAskName();
            }
        },

        GoLogin()
        {
            window.scrollTo(0,0)
            this.$router.push('/Login');
        },

        GoInfo()
        {
            window.scrollTo(0,0)
            this.$router.push('/Informations')
        },


        ResizeHandler()
        {
           
            this.window_width = window.innerWidth;
        },

        ValidateSavePanierForm()
        {
            return this.$refs.panier_name.ValidateInput();
        },

        SavePanier()
        {
            if(!this.ValidateSavePanierForm())return;
            this.saving_panier = true;
            this.panierHandler.SaveUserPanier(this.$refs.panier_name.GetValue()).then(() => {

                this.$refs.panier_name.Clear();
                this.message = "Attention, les modifications ultérieures ne seront pas enregistrées" 
                this.$refs.info.Reveal("Panier enregistré", "", "460", "var(--success-green)")
            }).catch((err) => {
              
                this.saving_panier = false;
                this.message = err;
                this.$refs.info.Reveal("Erreur");

            })
        },

        ImportPanier()
        {
            this.panierHandler.ImportUserPanier(this.accountsHandler.currentUser.saved_panier[this.saved_panier_index].data)
        },

        ShowAskName()
        {
            if(!this.accountsHandler.currentUser.logged)
            {
                this.$refs.go_login.Reveal("Connexion", "", "460", "var(--success-green)")
            }
            else {
                this.$refs.ask.Reveal("Un petit nom", "", "460", "var(--success-green)")
            }
        },
        HideAskName()
        {
            this.$refs.ask.Hide();

        }
    },
    
    
}
</script>

<style scoped>
body {
    background-color : var(--white-break);
}

.info-span {
    max-width : 900px;
    font-size : 14px;
   
    letter-spacing : normal;
    text-align : center;
}

.loading-panier {
   display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    color : gray;
    width: 100%;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 0.75rem;
    position: relative;
}



.loading-span:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}
@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #2c3e50;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 #2c3e50;}}


.loading-span {
    width : 100%;
    height : 100%;
    display : flex;
    position: absolute;
    font-weight: 500!important;
    top: 0;
    left:0;
    justify-content : center;
    align-items : center;
    text-align: center;
    font-size : 1.2rem;
    font-weight: bold;
}

.bottom-container{
    width: 100%;
    display: flex;    
}

.import-container {
    display: flex;
    align-items: center;
}

.import-container > span {
    white-space :nowrap;
    margin-right : 0.5rem;
}

.ask-panier-container {
    display: flex;
    flex-direction: column;
    color: black;
    padding: 1rem;
    
    letter-spacing: normal;
    align-items: center;
}

.ask-panier-container > span {
    font-size: 14px;
    padding : 0 1rem;
}


.item-active {
    background-color : antiquewhite!important;
}



@media(max-width:560px)
{
 .products-title > span {
     display : none!important;
 }   
}

@media (max-width:400px)
{
    .total-container {
        font-size : 1.1rem!important;
    }

    .import-container {
        flex-direction : column;
    }

    .import-container > span {
        margin-right : 0!important;
        margin-bottom : 0.75rem;
    }
}

@media(max-width:800px) {
    .unit {
        display : none!important;
    }

    .bottom-container {
        flex-direction:column-reverse;
        align-items : center;
    }

    .import-container {
        margin-top: 1rem;
    }

    .panier-container {
        padding-left: 0.2rem!important;
        padding-right:0.2rem!important;
    }

    .panier-title {
        font-size: 2rem!important;
        align-self: center;
    }

    .products-title {
        grid-template-columns: 25% 25% 25% 25%!important;
    }

    .total-container {
        justify-content: center!important;
        width : 100%;
    }
    

}

.no-product {
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    color : gray;
    width: 100%;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 0.75rem;
    position: relative;

}

.button-disabled {
    background-color: grey!important;
    border: 1px solid gray!important;
    color: lightgray!important;
    pointer-events: none;
}

.promo
{
    font-weight: 600;
    color:var(--amaranth);
}
.info-container {
    width: 100%;
    margin-top : 2rem;
    display : flex;
    align-items : center;
    justify-content: center;
}

.button-container {
    width: 100%;
    margin-top : 1rem;
    margin-bottom : 1rem;
    display : flex;
    align-items : center;
    justify-content: center;
}

.article-panier-button {
    
    color : var(--amaranth);
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color: var(--white-break);
    border : 1px solid var(--amaranth);
    width : 265px;
    transition-duration: 0.2s;
    height : 50px;
    margin-top: 2rem;
}

.article-panier-button:hover {
    background-color : var(--amaranth);
    color : var(--white-break);
    

}




.total-container {
    align-self: flex-end;
    display: flex;
    align-items : center;
    flex-direction: row;
    margin-left : auto;
    font-weight: bold;
    font-size : 1.3rem;
    margin-top : 0.5rem;
}

.panier-container {
  
    margin-top : 5rem;
    position : relative;
    background-color: var(--white-break);
    font-family: 'Montserrat', sans-serif;
    padding: 0rem 2rem;
    letter-spacing : -0.05rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.products-container {
    margin-top : 3rem;
    max-width : 900px;
    width : 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}







.products {
    margin-top:0.75rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}


.products-title {
    width: 100%;
    display: grid;
    font-weight: 500;
    grid-template-columns: 20% 20% 20% 20% 20%;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid rgba(0,0,0,0.6);

}





.panier-title-container {
   
    display: flex;
    flex-direction: column;
    align-items : flex-start;
    font-size : 1rem;
}

.panier-title-container > span {
    display: flex;
    align-items: center;
  
}

.panier-title {
    font-size : 2.5rem;
    font-weight : bold;
    margin-bottom : 0.2rem;
    
}

</style>