<template>

  <div id="app">
     <router-view name="topbar"/>
      <router-view name="sidebar"/>
      
        <transition
        name="fade"
        mode="out-in"
      >
       <router-view name="main"/>
        </transition>

         <router-view name="footer"/>
      
        
      
    
  </div>
</template>

<script>

export default {
  name : 'App',
  components: {
    
  },
  metaInfo : {
    title : 'Accueil',
    titleTemplate : '%s | Neon Bloom Paris',
    meta : [
      {charset : 'utf-8'},
      {property:"og:image", content : "https://firebasestorage.googleapis.com/v0/b/neon-bloom.appspot.com/o/logo_new.png?alt=media&token=7be1ee6d-95ea-497c-b91b-50b59360bbc3" },
      {property:"og:url", content:"https://neonbloom.fr/"}
    ]
  },


  mounted(){
    let themejs = document.createElement('script')
    themejs.setAttribute('src', 'https://kit.fontawesome.com/2457aa6323.js')
    document.head.appendChild(themejs)
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@600;800&display=swap');

.particles-js-canvas-el {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;

}

#app {
      flex-direction: column;
    min-height: 100vh;
    
    display: flex;
}



:root {

--pretty-green: #5F9B7C;
--amaranth: #df3b57;
--independence: #49475bff;
--lavender-blue: #cbc5eaff;
--iceberg: #81a4cdff;
--pretty-pink : #F5B3D1;
--white-break : #F6F6F6;
--pretty-blue : #342B59;
--success-green : #16a085;
--mdc-theme-primary : #16a085;
--mdc-theme-on-primary: #16a085;
}

.mdc-theme--primary {
  color : var(--pretty-green);
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
   color : var(--pretty-green);
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color : var(--pretty-green);
}

.scaleanim {
  transition: transform 0.2s;
  cursor: pointer;
}

.scaleanim:hover {
  transform: scale(1.1);
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800&display=swap');

@font-face {
  font-family: "DIN";
  src: local("DIN"),
   url('./assets/fonts/D-DINCondensed-Bold.ttf') format("truetype");
   font-weight: 800;
   font-style: normal;
}

.panier-step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;
    margin-top: 1rem;
    padding: 0 0.75rem;
    max-width : 417px;
    width: 95%;
    position: relative;
}

.panier-step-line {
    position: absolute;
    width: calc(100% - 3.5rem);
    top: 40%;
   
    z-index: 1;
    transform: translateY(-50%);
    left : 2rem;
    border: 1px solid gray;
}

.panier-step-item {
    margin: 0.25rem;
    display: flex;
    align-items: center;
    position: relative;
    z-index : 2;
    justify-content : center;
    flex-direction: column;
}


.panier-step-item > div {
    border-radius: 60px;
    background-color: var(--white-break);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border : 1px solid gray;
}

.panier-step-item > span {
    letter-spacing : normal;
    font-size : 17px;
    color : #707070;
    font-family: 'DIN', sans-serif
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Kaushan+Script&family=Parisienne&display=swap');



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}


@keyframes ldio-r8h14oufgyb {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-r8h14oufgyb div {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 5px solid #6a6a6a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-r8h14oufgyb div {
  animation: ldio-r8h14oufgyb 1s ease infinite;
  top: 50px;
  left: 50px
}
.loadingio-spinner-rolling-l8pfz6cbtd {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-r8h14oufgyb {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-r8h14oufgyb div { box-sizing: content-box; }


</style>
