<template>
     <div :style="{'width' : 100*ratio + 'px','height' : 100*ratio + 'px' }" class="loadingio-spinner-rolling-l8pfz6cbtd"><div :style="{'transform' : transform}" class="ldio-r8h14oufgyb">
                    <div ></div>
                    </div></div>
</template>



<script>
export default {
    
    props : {
        ratio : {type :Number,default : 1}
    },

    computed : {
        transform()
        {
            return `translateZ(0) scale(${this.ratio})`;
        }
    }


}
</script>

