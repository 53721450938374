<template>
    
    <div  class="login-container">
        <div class="login-side log-in">

            <form @submit="submitLogin" class="login-form">
                <h3 class="mb-4">Se connecter</h3>

                
                    <input-view input_type="email" :required="true" input_title="Email" v-model="loginEmail" />
                    <input-view input_type="password" :minLength="8" :required="true" input_title="Mot de Passe" v-model="loginPassword" />
                    <a href="/ResetPassword" class="align-self-end mb-2 little">Mot de passe oublié ?</a>
                    <button type="submit" :class="{'button-disabled' : !activeLogin}" class="address-button d-flex align-items-center justify-content-center">
                        <loading v-if="login" :ratio="0.5" />
                        <span v-else>Let's go !</span>
                        </button>
               

            </form>
        </div>
        <div class=" login-side sign-in">
            <form @submit="submitSignIn" class="login-form">
                <h3 class="mb-4">S'inscrire</h3>

                
                    <input-view :dark="true" :required="true" :noMargin="true" pattern="([a-zA-Z]+[ ]*)+"  input_type="text" input_title="Nom" v-model="signInName" />
                    <small class="align-self-end little mb-2"  style="color:gray">Lettres uniquement</small>
                    <input-view :dark="true" :required="true" pattern="([a-zA-Z]+[ ]*)+" input_type="text" input_title="Prénom" v-model="signInSurname" />
                    <input-view :dark="true" :required="true" ref="email" input_type="email" input_title="Email" v-model="signInEmail" />
                    <input-view :dark="true" :noMargin="true" ref="password" :required="true" :minLength="8" input_type="password" input_title="Mot de Passe" v-model="signInPassword" />
                    <small class="align-self-end little mb-2"  style="color:gray">8 caractères minimum</small>
                    <input-view :dark="true" :required="true" ref="confirm_password" input_type="password"  input_title="Confirmez le mot de passe" v-model="signInConfirmPassword" />
                    <check-view v-model="signInPolitic" :required="true" label="J'accepte les CGV et la politique de confidentialité" />
                    <check-view v-model="sigInNewsletter"  label="Je m'abonne à la newsletter" />
                    
                    
                      <button type="submit" :class="{'button-disabled' : !activeSignIn}" class="address-button d-flex align-items-center justify-content-center">
                        <loading v-if="signIn" :ratio="0.5" />
                        <span v-else>Je m'inscris !</span>
                        </button>
                    <transition name="slide-fade" mode="out-in" appear>
                        <small v-if="signInErrorMessage != ''" :key="1" class="little mb-5" style="color:var(--amaranth)">{{signInErrorMessage}}</small>
                        <small v-else class="little mb-5" :key="2"  style="color:var(--amaranth);height:20.44px"></small>
                        
                    </transition>

            </form>
        </div>
        <dialog-box :show_modal="showModal" @close="closeModal" :modal_content="modalContent" :modal_title="modalTitle" :modal_error="modalError" />
    </div>
    
</template>

<script>
import InputView from '../components/InputView.vue';
import CheckView from '../components/CheckView.vue';
import DialogBox from '../components/DialogBox.vue';
import Loading from '../components/Loading.vue';


export default {

    components :{
        InputView,
        CheckView,
        DialogBox,
        Loading
    },

         data()
    
    {
        return {
            signInName : "",
            signInSurname : "",
            signInEmail : "",
            signInPassword : "",
            signInConfirmPassword : "",
            signInPolitic : false,
            sigInNewsletter : true,

            loginEmail : "",
            loginPassword : "",
            signInErrorMessage : "",

            showModal : false,
            modalError : false,
            modalTitle : "",
            modalContent : "",

            signIn : false,
            login : false,
        }
    },

    computed: {

        activeSignIn()
        {
            return (!this.signIn && this.signInName != "" && this.signInSurname != "" && this.signInPassword != "" && this.signInEmail != "" && this.signInConfirmPassword != "" && this.signInPolitic != false)
        },

        activeLogin()
        {
            return (this.loginEmail != "" && this.loginPassword != "" && !this.login);
        }
    },
    metaInfo : {
        title : 'Connection',
        meta : [
            {name:"description" , content : "Connectez vous ou créez un compte pour suivre vos commandes en temps réel"}
        ]
    },
    
    methods : {



      async submitSignIn(e)
      {
          e.preventDefault();
          console.log(this);
          this.signInErrorMessage = "";
        if(this.signInPassword != this.signInConfirmPassword)
        {
            this.$refs.password.textField.valid = false;
            this.$refs.confirm_password.textField.valid = false;
            this.signInErrorMessage = "les mots de passes ne correspondent pas."
            
            return;
        }
        this.signIn = true;
        try {
            await this.$accountsHandler.CreateAccount(this.signInName, this.signInSurname, this.signInEmail, this.signInPassword, this.sigInNewsletter);
            this.modalError = false;
                this.modalContent = "Votre compte a bien été créé !";
                this.modalTitle = "Succès"
                this.showModal = true;
        } catch (err) {
            switch(err.code)
                {
                    case "auth/weak-password" : this.modalContent = "Votre mot de passe est trop faible";break;
                    case "auth/email-already-in-use" : this.modalContent = "Cette adresse mail est déja utilisée";break;
                    default : this.modalContent = "Erreur inconnue, veuillez reessayer dans quelques instants";break;
                }
                this.modalTitle = "Erreur"
                this.modalError = true;
                this.signIn = false
                this.showModal = true;
        }
        

      },


    closeModal()
    {
        this.showModal = false;
        if(this.login)
        {
            this.login = false
            this.$router.push('/Account')
        }
        if(this.signIn){
            this.signIn = false;
            this.$router.push('/Account')
        }
    },

    async submitLogin(e)
      {
          e.preventDefault();
            this.login = true;
            try {
                
                await this.$accountsHandler.SignIn(this.loginEmail, this.loginPassword);
                this.modalError = false;
                this.modalContent = "Vous êtes connecté !";
                this.modalTitle = "Succès"
                this.showModal = true;
               
                
            } catch (err) {
                switch(err.code) {
                    case "auth/wrong-password" : 
                   this.modalContent = "Le mot de passe est incorrect"
                    break;
                    case "auth/user-not-found" : 
                    this.modalContent = "Il n'y a pas de compte associé a cet adresse mail"
                    break;
                }
                 this.modalError = true;
                 this.login = false;
                 this.showModal = true;
                 this.modalTitle = "Erreur"
            }
            
    }

    }, 
    mounted()
    {
        if(this.$accountsHandler.has_sub_save)
        {
            const save = this.$accountsHandler.GetSaveSub();
            this.start_sub = false;
            this.name = save.name;
            this.surname = save.surname;
            this.email = save.email;
            this.password = save.password;
            this.password_2 = save.password2;
            this.newsletter = save.news;
            this.politic = save.pol;
            this.$accountsHandler.RemoveSaveSub()
        }
    }
}
</script>

<style scoped>

input[type="checkbox"]
{
    appearance: checkbox;
    
}

@media(max-width:1000px)
{
    .login-container {
        flex-direction: column!important;
    }

    .login-side {
        width: 100%!important;
        padding : 3rem 0.25rem!important;
        
    }
}

.little {
    font-size: 14px;
}

.address-button {
    min-height: 48px;
    border: none;
    background-color: var(--amaranth);
    color: white;
    border-radius: 4px;
    padding: 0 1rem;
    outline: none!important;
    transition-duration: 0.4s;
}

.button-disabled {
    pointer-events: none;
    background-color : gray;
    color : lightgray;
}

.login-container {
    display: flex;
    margin-top : 5rem;
    width: 100%;
    height : 100%;
    align-items: stretch;
    justify-content: stretch;
     flex: 1 1 auto;
     font-family : 'Montserrat', sans-serif;
     
}

h3 {
    font-weight: bold;
}

.login-form {
    width : 300px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.log-in {
    background-color: white;
    
}

.login-side {
    display: flex;
    width:50%;
    padding : 2rem 0.5rem;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    flex: 1 1 auto;
}

.sign-in {
     background-color : var(--white-break);
}

</style>