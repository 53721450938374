
export class Adresse {
    constructor(country="", city="", adresse="", zip_code="",name="", num = "", options = "",active = false)
    {
        
        this.country = country
        this.city = city;
        this.adresse = adresse;
        this.zip_code = zip_code;
        this.name = name ;
        this.num = num;
        this.options = options;
        this.active = active;
        this.id = Math.GenerateHash(this.ExportToJSON()) + "add";
    }

    clone()
    {
        return new Adresse(this.country, this.city, this.adresse, this.zip_code, this.name, this.num, this.options, this.active);
    }

    ExportToJSON()
    {
        return {
            country : this.country,
            city : this.city,
            adresse : this.adresse,
            zip_code : this.zip_code,
            fullname : this.name,
            num : this.num,
            options : this.options,
            active : this.active,
        }
    }
}

export default class CheckoutHandler 
{
    constructor(current_adresse = new Adresse(), current_liv = 'normal', coupon_id = "")
    {
        this.current_adresse = current_adresse;
        this.current_liv = current_liv;
        this.coupon_id = coupon_id;
    }

    SetAdresse(adresse)
    {
        this.current_adresse = adresse;
    }

    GetAdresse()
    {
        return this.current_adresse;
    }

    ToParams()
    {
        let value = 0;
        if(this.current_liv == 'express')value = 1;
        return {adresse : this.current_adresse.ExportToJSON(), livraison_mode : value, coupon_id : this.coupon_id};
    }

    GetCoupon()
    {
        return this.coupon_id;
    }
    SetCoupon(value)
    {
        this.coupon_id = value;
    }

    SetLivraison(liv)
    {
        this.current_liv = liv;
    }

    GetLivraison()
    {
        return this.current_liv;
    }
}