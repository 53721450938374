<template>
    <div class="pros-container">
        <div class="pros-grid">
            <div @click="GoTo('/installation')" class="pro-item">
                <div class="pro-item-bg">
                    <img src="../assets/imgs/exterieur.jpg" class="pro-item-content " alt="" srcset="">
                </div>
                <div class="pro-item-message">
                    <span>INSTALLATION D'enseignes
                    </span>
                </div>
            </div>
            <div  @click="GoTo('/Scenographie')" class="pro-item">
            <div class="pro-item-bg">
                    <img src="../assets/imgs/scenographie.jpg" class="pro-item-content " alt="" srcset="">
                </div>
                
                <div class="pro-item-message">
                    <span>décoration & scénographie</span>
                </div>
            </div>
            <div @click="GoTo('/Creation')" class="pro-item">
                <div class="pro-item-bg">
                    <img src="../assets/imgs/graphique.jpg" class="pro-item-content " alt="" srcset="">
                </div>
                <div class="pro-item-message">
                    <span>création graphique</span>
                </div>
            </div>
            <div class="pro-item">
                 <div class="pro-item-bg">
                    
                </div>
                <div class="pro-item-ask">
                   <h5 class="ask-title">ça vous tente ?</h5>

                   <span style="letter-spacing:-0.03rem">Discutons ensemble de votre projet</span>

                   <button class="article-panier-button" @click="GoTo('/Contact')">Contactez nous</button>
                </div>
            </div>
        </div>
    </div>    
</template>


<script>
export default {

    metaInfo : {
        title : 'Pros',
        meta : [
            {name:"description" , content : "Besoin d'un logo ? d'un néon évenementiel ? D'une réalisation graphique ? Neon Bloom vous accompagnera tout au long du processus pour vous satisfaire au mieux"}
            ,{ property: 'og:title', content: 'Pros'},
                { property: 'og:site_name', content: 'Neon Bloom Paris'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'},
        ]
    },

    methods : {
        GoTo(path)
        {
            this.$router.push(path);
        }
    }
    
}
</script>
<style scoped>

.little {
    font-size : 18px;
    text-transform: none;
}

.article-panier-button {
    color : white;
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color : var(--amaranth);
    border : 1px solid var(--amaranth);
    text-transform : uppercase;
    transition-duration: 0.2s;
    padding : 0.5rem 0.75rem;
    
    margin-top: 1rem;
}

.article-panier-button:hover {
    background-color: white;
    color : var(--amaranth);

}

.ask-title {
    font-family : 'DIN', sans-serif;
    text-transform : uppercase;
    font-size : 1.75rem;
    margin-bottom: 0.25rem;
    white-space: nowrap;
}

.pros-container {
    margin-top : 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pros-grid {
    padding : 3rem 1rem;
    max-width : 900px;
    width : 100%;
    display : grid;
    grid-template-columns : 50% 50%;
    grid-gap : 0.5rem;
}

.pro-item {
    width : 100%;
    display : block;
    position : relative;
     overflow : hidden;
}

.pro-item-message {
    position : absolute;
    line-height: 176%;
    top : 50%;
    cursor: pointer;
    left : 50%;
    transform : translate(-50%, -50%);
    background-color: rgba(255,255,255,0.9);
    display : flex;
    align-items : center;
    justify-content : center;
    width : 65%;
    height : 35%;
    transition-duration: 0.2s;
}

.pro-item-ask {
     position : absolute;
    top : 50%;
    left : 50%;
    transform : translate(-50%, -50%);
    
    display : flex;
    align-items : center;
    justify-content : center;
    padding : 2rem 0;
    font-family: "Montserrat", sans-serif;
    width : 80%;
    flex-direction: column;
}

.pro-item-message > span {
    font-family: 'DIN', sans-serif;
    color : black;
    font-size : 1.75rem;
    text-transform : uppercase;
    padding: 1rem;
    text-align: center;
}

@media (max-width:750px)
{
    .pro-item-message > span {
        font-size : 1.2rem!important;
    }
}

@media (max-width:640px)
{
    .pros-grid {
        grid-template-columns : 100%!important;
    }
}

.pro-item-bg {
    width : 100%;
    background-color : var(--white-break);
    position : relative;
    height : auto;
    object-fit : cover;
    aspect-ratio: 1/1;
    transition-duration : 0.2s;
}

.pro-item-content {
    position : absolute;
    top: 0;
    left: 0;
    width : 100%;
    height: 100%;
    object-fit: cover;
}

.pro-item-bg:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.pro-item:hover > .pro-item-bg {
    transform: scale(1.05);
    cursor: pointer;
} 

</style>