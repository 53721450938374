<template>
 <div style="margin-bottom:0.5rem" ref="mdc_select" :class="{'mdc-select--required' : required}" class="mdc-select mdc-select--filled w-100 demo-width-class">
  <input  ref="select_input" :value="value" required type="hidden" name="select-input">
  <div class="mdc-select__anchor"
       role="button"
       aria-haspopup="listbox"
       aria-expanded="false"
       aria-labelledby="demo-label demo-selected-text"
       :aria-required="required"
       >
    <span class="mdc-select__ripple"></span>
    <span id="demo-label" class="mdc-floating-label">Pays</span>
    <span class="mdc-select__selected-text-container">
      <span id="demo-selected-text" class="mdc-select__selected-text">{{currentText}}</span>
    </span>
    <span class="mdc-select__dropdown-icon">
      <svg
          class="mdc-select__dropdown-icon-graphic"
          viewBox="7 10 10 5" focusable="false">
        <polygon
            class="mdc-select__dropdown-icon-inactive"
            stroke="none"
            fill-rule="evenodd"
            points="7 10 12 15 17 10">
        </polygon>
        <polygon
            class="mdc-select__dropdown-icon-active"
            stroke="none"
            fill-rule="evenodd"
            points="7 15 12 10 17 15">
        </polygon>
      </svg>
    </span>
    <span class="mdc-line-ripple"></span>
  </div>

  <div class="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
    <ul class="mdc-list" role="listbox" aria-label="Food picker listbox">
      <li v-for="(choice, index) in choices" :key="index" class="mdc-list-item" :class="{'mdc-list-item--selected' : choice.value == content}" aria-selected="false" :data-value="choice.value" role="option">
        <span class="mdc-list-item__ripple"></span>
        <span class="mdc-list-item__text">
          {{choice.text}}
        </span>
      </li>
    </ul>
  </div>
</div>   
</template>


<script>
import {MDCSelect} from '@material/select';
export default {
    props: {
        choices : Array,
        required : {type : Boolean, default : false},
        default_selected : {type : Number, default : 0},
        value : String,
    },
    data() {
        return {
            select : null,
            content : this.value
        }
    },

    computed : {
      currentText()
      {
        if(this.select == null)return "";
        if(this.select.selectedIndex == -1)return ""
        return this.choices[this.select.selectedIndex].text;
      }
    },

    methods: {
        validate()
        {   
            return this.select.valid;
        },


        updateInput()
        {
            this.$emit('input', this.$refs.select_input.value)
        }
    },

    mounted() {
        this.select = new MDCSelect(this.$refs.mdc_select);
        this.select.listen('MDCSelect:change',this.updateInput)
        console.log(this.select);
    }
}
</script>


<style scoped lang="sass">
@use "@material/list/mdc-list"
@use "@material/menu-surface/mdc-menu-surface"
@use "@material/menu/mdc-menu"
@use "@material/select/styles"
@use '@material/select'

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label 
  color : var(--pretty-green)


.my-demo-select
    @include select.filled-density(-2)

.demo-width-class
    width: 100%

.mdc-list-item
    height:48px
    align-items: center


</style>