<template>
  <div class="featured">
    <img :src="imageUrl" class="image" alt="">
    <div class="content" >
      <h3>{{title}}</h3>
      <span>{{subtitle}}</span>
    </div>
    <span class="keyword" :style="{'background-color' : keywordColor}">{{keyword}}</span>
  </div>

</template>

<script>
export default {
  name: "FeaturedArticle",
  props : {
    title : String,
    subtitle : String,
    link : String,
    keyword : String,
    imageUrl : String,
    keywordColor : String,
  },

  mounted() {
    console.log(this.imageUrl);
  }
}
</script>



<style scoped>

.keyword {
  padding: 0.25rem 1rem;
  color: white;
  font-size: 12px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image
{
  position: absolute;
  transform: scale(1);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  transition-duration: 0.4s;
  object-fit: cover;
}
 .image:hover {
  transform: scale(1.1);
}

.featured {
  position: relative;
  margin: 1rem;
  max-width: 400px;
  overflow: hidden;
  cursor: pointer;
  height: 250px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  object-fit: cover;
  width: 95%;
  display: flex;
  font-family: 'Montserrat', sans-serif;
}

.content {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.content > h3 {
  font-weight: bold;
  text-align: left;
}
.content > span
{
  font-size: 16px;
}

</style>
