<template>
    <div class="d-flex justify-content-center align-items-center w-100">
        <div class="validate-container">
        <h2><b>Et la lumière fut !</b></h2>
        
        <span class="mt-3" style="text-align:center">Votre commande bien été passé, nous vous remercions pour votre confiance. Un e-mail de confirmation vous sera prochainement envoyé.
             Si vous disposez d'un compte néon bloom vour pourrez suivre son avancement directement dans la page client. <br>
             Pour tout renseignements n'hesitez pas a nous contacter avec l'adresse mail : <b>neonbloom.paris@gmail.com</b>
             
             </span><br>
        <span>L'équipe Néon Bloom.</span>

        <button class="article-panier-button" @click="goTo('/')">RETOURNER SUR LE SITE</button>
    </div>
    </div>
</template>

<script>
export default {

    data()
    {
        return{
            stripeHandler : this.$stripeHandler,
        }
    },

    metaInfo : {
        title : 'Validation Commande',
        meta : [
            {name:"robots" , content : "noindex"}
        ]
    },
    
    methods: {
        goTo(path)
        {
            this.stripeHandler.payed = false;
           this.$router.push(path) 
        }
    },
    created()
    {
        if(!this.stripeHandler ||!this.stripeHandler.payed)
        {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>

span {
    text-align: left;
}

.validate-container {
    max-width: 800px;
    margin-top : 5rem;
    display : flex;
    flex-direction : column;
    align-items: baseline;
    padding : 1rem;
    font-family : 'Montserrat', sans-serif;
}


.article-panier-button {
    color : white;
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color : var(--amaranth);
    border : 1px solid var(--amaranth);
    width : 210px;
    transition-duration: 0.2s;
    height : 50px;
    margin-top: 2rem;
}

.article-panier-button:hover {
    background-color: white;
    color : var(--amaranth);

}
</style>