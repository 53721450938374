<template>
    <div class="d-flex align-items-center" >
  <div ref="check" class="mdc-checkbox" :class="{'mdc-checkbox--selected' : checked}">
    <input @click="handleCheck" type="checkbox"
            :required="required"
            :checked="checked"
           class="mdc-checkbox__native-control"
           id="checkbox-1"/>
    <div class="mdc-checkbox__background">
      <svg class="mdc-checkbox__checkmark"
           viewBox="0 0 24 24">
        <path class="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
      </svg>
      <div class="mdc-checkbox__mixedmark"></div>
    </div>
    <div class="mdc-checkbox__ripple"></div>
  </div>
  <span v-html="label" class="helper ml-2"></span>
</div>
</template>

<script>
import {MDCCheckbox} from '@material/checkbox';
export default {
    
    props: {
        value : {type : Boolean, default : false},
        label : String,
        required : {type : Boolean ,default : false}
    },

    data() {
        return {
            checked : this.value,
            checkbox : null,
        };
    },

    methods: {
        handleCheck()
        {
          this.checked = !this.checked;
          this.$emit('input', this.checked)
        }
        
        },
    mounted() {
       
        this.checkbox = new MDCCheckbox(this.$refs.check);
    },
    
}
</script>

<style scoped lang="sass">
@use "@material/checkbox"

@include checkbox.core-styles

.helper
    text-align:(left)
    font-size:14px
    color:(gray)
    
    
  

</style>