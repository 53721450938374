<template>
    <div class="account-container">
        <h1 class="account-title">Mon compte client</h1>
        <div class="account-content">

            <div class="account-item">
                <h3 class="account-subtitle">Informations personnelles</h3>

                <div class="account-info-container">
                    <div class="account-info"><img src="../assets/imgs/account.svg" alt="" srcset=""></div>
                    <div class="account-info-content">
                        <span>Prénom : {{accountsHandler.currentUser.surname}}</span>
                        <span>Nom : {{accountsHandler.currentUser.name}}</span>
                        <span>Email : {{accountsHandler.currentUser.email}}</span>
                    </div>
                    <div class="account-info-content">
                        <span @click="ShowChangePassword" class="account-link">Changer le mot de passe</span>
                        <span @click="ShowNewEmail" class="account-link">Changer l'adresse mail</span>
                        <span @click="HandleNewsletter" :class="{'btn-shine' : changing_newsletter}" class="account-link">{{Newsletter}}</span>
                    </div>
                   <div style="border:none;padding-right:0" class="account-info-content">
                        <span @click="SubmitSignOut" class="account-link">Se déconnecter</span>
                        <span class="account-link" @click="goTo('/Politique')">Notre politique de confidentialité</span>
                        <span @click="DeleteAccount" class="account-link">Supprimer son compte</span>
                        
                    </div>
                </div>

            </div>
            <div class="account-item">
                <h3 class="account-subtitle">Vos paniers enregistrés</h3>

                <div class="account-item-content account-button-container">
                    <select class="account-button account-select mx-4" v-model="selected_panier" name="" id="">
                        <option value="-1" hidden selected disabled>Choisir un panier...</option>
                        <option v-for="(panier, index) in accountsHandler.currentUser.saved_panier" :key="index" :value="index" >{{panier.id}}</option>
                    </select>
                    <button class="account-button mx-4" @click="DeletePanier">Supprimer le panier</button>
                </div>
                

            </div>

            <div class="account-item">
                <h3 class="account-subtitle">Vos adresses de livraison</h3>

                <div class="account-item-content">
                    <div class="account-list">
                        <div class="account-list-title">
                            <span>Status</span>
                            <span>Adresse postale</span>
                        </div>

                        <div v-if="accountsHandler.currentUser.adresses_loaded" class="account-list-content">
                            <div v-for="(adresse, index) in accountsHandler.currentUser.addresses" :key="index" class="account-list-item">
                            <button  :class="{'button-disabled' : adresse.active}" @click="SubmitSelectAddresse(adresse.id)" class="account-button mt-2">{{adresse.active ? "Adresse sélectionnée" : "Selectionner l'adresse"}}</button>
                                <div class="little-title mr-1">Adresse : </div>
                                <span >{{adresse.adresse}}, {{adresse.zip_code}}, {{adresse.city}}, {{adresse.country}}</span>
                                <button class="remove-button scaleanim" @click="SubmitRemoveAdresse(adresse.id)">x</button>
                            </div>
                            <div @click="ShowNewAdresse" class="account-list-add">
                                <span>Ajouter une adresse +</span>
                            </div>
                        </div>
                        <div v-else  class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>

                    </div>
                </div>
                

            </div>
            <div class="account-item">
                <h3 class="account-subtitle">Vos Commandes</h3>

                <div class="account-item-content">
                    <div class="account-list">
                        <div class="account-list-title account-commandes">
                            <span>Numéro de commande</span>
                            <span>Détails</span>
                            <span>Date de livraison</span>
                            <span>Status</span>
                        </div>

                        <div v-if="accountsHandler.currentUser.commands_loaded" class="account-list-content">
                            <div v-for="command in accountsHandler.currentUser.commands" :key="command.id" class="account-list-item account-commandes ">
                                <span style="text-transform:uppercase"><div class="little-title mr-1">ID : </div>{{command.id}}</span>
                                <button class="account-button" @click="AskProducts(command)">Voir le détail</button>
                                <span><div class="little-title mr-1">Livraison : </div>{{TimestampToDate(command.expected_due_date)}}</span>
                                <span><div class="little-title mr-1">Status : </div>{{command.status}}</span>
                            </div>
                           
                        </div>
                        <div v-else  class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>
                    </div>
                </div>
                

            </div>
            

            
            

        </div>

    <account-modal ref="ChangePassword">
    <form @submit="SubmitNewPassword" style="max-width" class="d-flex w-100 flex-column justify-content-center align-items-stretch">
        
        
        <input-view ref="new_password_old" :required="true" v-model="old_password" input_type="password" input_title="Ancien mot de Passe" placeholder="Mot de passe" />
        <div class="my-3"></div>
        <input-view ref="new_password" :required="true" v-model="new_password" input_type="password" :minLength="8" :noMargin="true" input_title="Nouveau mot de Passe" placeholder="Mot de passe" />
        <small style="color:gray" class="little align-self-end mb-2">8 caractères miminum</small>
        <input-view ref="new_password_2" :required="true" v-model="new_password_2" input_type="password" input_title="Nouveau mot de Passe" placeholder="Mot de passe" />

    <button  type="submit" :class="{'button-disabled' : changing_password}" class="account-button d-flex align-items-center justify-content-center mt-2">
        <loading :ratio="0.5" v-if="changing_password" />
        <span v-else>Réinitialiser le mot de passe</span>
        </button>
    <transition name="slide-fade" mode="out-in" appear>
                        <small v-if="newPasswordError != ''" :key="1" class="little " style="color:var(--amaranth)">{{newPasswordError}}</small>
                        <small v-else class="little " :key="2"  style="color:var(--amaranth);height:20.44px"></small>
                        
                    </transition>
    </form>

    </account-modal>
    <account-modal ref="ChangeEmail">
    <form @submit="SubmitNewEmail" class="d-flex w-100 flex-column justify-content-center align-items-stretch">
        <input-view ref="new_email_password" input_type="password" v-model="old_password" :required="true" input_title="Mot de Passe" placeholder="Mot de passe" />
        <input-view ref="new_email" v-model="new_email" :required="true" input_type="email" input_title="Nouvelle adresse mail" placeholder="Adresse mail" />
        
   
    <button  type="submit" :class="{'button-disabled' : changing_email}" class="account-button d-flex align-items-center justify-content-center mt-2">
         <loading :ratio="0.5" v-if="changing_email" />
        <span v-else>Modifier l'adresse mail</span>
        
        </button>
    
    </form>

    </account-modal>

    <account-modal ref="new_add">
         <adresse-form :save="false" ref="adresse_form"  @newAdresse="SubmitNewAdresse" />

    </account-modal>
        <mini-modal @modalquit="ModalQuit" :NoHeight="true" ref="info"><span class="m-3" style="color:black">{{message}}</span></mini-modal>

    <div v-if="show_products" class="command-background">
        <div class="command-card">
            <h4>Vos produits</h4>
            <button @click="QuitButton" class="quit-button">X</button>
            <div v-if="products_loaded" class="products-container">
                <div  v-for="(product, index) in Products" :key="index" class="product-item">
                  
                    <div class="field">
                        <span>Identifiant :</span>
                        <span><b>{{product.id + "(" + product.variante + ")"}}</b></span>
                    </div>
                    <div class="field">
                        <span>Dimension :</span>
                        <span><b>{{getDimension(product.size)}}</b></span>
                    </div>
                    <div class="field">
                        <span>Quantité :</span>
                        <span><b>{{product.quantity}}</b></span>
                    </div>
                    <div  class="field">
                       <span> Prix TTC : </span>
                       <span><b>{{product.total_price}}€</b></span>
                
                        
                    </div>
                 
                    
                </div>
                <div  v-for="product in NeonsProduct" :key="product.id" class="product-item">
        
                    <div class="field">
                        <span>Texte :</span>
                        <span><b>{{product.neon_data.text}}</b></span>
                    </div>
                    <div class="field">
                        <span>Dimension :</span>
                        <span><b>{{getDimension(product.neon_data.dimension_id)}}</b></span>
                    </div>
                    <div class="field">
                        <span>Quantité :</span>
                        <span><b>{{product.quantity}}</b></span>
                    </div>
                    <div  class="field">
                       <span> Prix TTC : </span>
                       <span><b>{{product.total_price}}€</b></span>
                
                        
                    </div>
                    

    
                </div>
            </div>
            <div v-else  class="loadingio-spinner-rolling-l8pfz6cbtd"><div class="ldio-r8h14oufgyb">
                    <div></div>
                    </div></div>

        </div>
    </div>

    <dialog-box @close="HideModal" :modal_content="modal_content" :modal_error="modal_error" :modal_title="modal_title" :show_modal="show_modal" />
    </div>

</template>

<script>
import AccountModal from '../components/AccountModal.vue';
import InputView from '../components/InputView.vue';
import MiniModal from '../components/MiniModal.vue'
import DialogBox from '../components/DialogBox.vue'
import AdresseForm from '../components/AdresseForm.vue';
import Loading from '../components/Loading.vue';
export default {
  components: { MiniModal, AccountModal, InputView,DialogBox, AdresseForm, Loading },

    metaInfo : {
        title : 'Compte client',
        meta : [
            {name : 'description', content : 'Gérez votre compte client NEON BLOOM en toute simplicité et suivez vos commandes en temps réel'}
        ]
    },

    data()
    {
        return{
            accountsHandler : this.$accountsHandler,
            message : "",
            old_password : "",
            new_password : "",
            new_password_2 : "",

            newPasswordError : "",


            new_email : "",
            new_add_country : "FR",
            modal_title : "",
            modal_content :"",
            show_modal : false,
            modal_error : false,
            products_loaded : false,
            products_err : false,
            show_products : false,
            current_command_id : "",
            errors : {password : false, new_password : false, small : false, email : false},
            changing_password : false,
            changing_email : false,
            changing_newsletter : false,
            adding_adresse : false,
            selected_panier : -1,
        }
    },

    computed: {
        Newsletter()
        {
            if(this.accountsHandler.currentUser.newsletter)return "Se désabonner de la newsletter";
            return "S'abonner à la newsletter"
        },
        NeonsProduct()
        {
            if(!this.accountsHandler.currentUser.logged)return [];
            const command = this.accountsHandler.currentUser.getCommande(this.current_command_id)
            if(!command)return []
             const c = command.products.filter((elem) => {return elem.type == 1});
             return c;
        },

        Products()
        {
            if(!this.accountsHandler.currentUser.logged)return [];
             const command = this.accountsHandler.currentUser.getCommande(this.current_command_id)
            if(!command)return []
             const c = command.products.filter((elem) => {return elem.type == 0});
             return c;
        },
        
    },

    methods : {

        DeleteCard(card_id)
        {
            this.accountsHandler.currentUser.DeleteCard(card_id).then((result) => {
                console.log(result);
                if(result.data.state)
                {
                    this.modal_error = false;
                    this.modal_title = "Carte supprimée"
                    this.modal_content = "Votre carte a bien été supprimé "
                    this.show_modal = true;
                }
                else {
                    this.modal_error = true;
                    this.modal_title = result.data.error.code
                    this.modal_content = result.data.error.message
                    this.show_modal = true;
                }
            }).catch((err) => {
                 this.modal_title = "Erreur"
                    this.modal_content = err.message
                    this.modal_error = true;
                    this.show_modal = true;
            })
        },

        HideModal()
        {
            this.show_modal = false;
            if(this.changing_password)
            {
                this.old_password = "";
                this.new_password = "";
                this.new_password_2 = "";
                this.changing_password = false;
                this.HideChangePassword();
            }
            if(this.changing_email)
            {
                location.reload();
            }
            if(!this.accountsHandler.currentUser.logged)
            {
                this.$router.push("/Login");
            }
        },

        DeleteAccount()
        {
            this.ShowDialog("Comment supprimer votre compte", "Merci de nous envoyer un mail a l'adresse <b>neonbloom.paris@gmail.com</b>, nous supprimerons votre compte dans les plus brefs délais")
        },

        getDimension(index)
        {   
            switch(index)
            {
                case 0 : return "XS"
                case 1 : return "S"
                case 2 : return "M"
                case 3 : return "L"
            }
        },

         goTo : function(path)
        {
         
            
            if(path == this.$route.path)return;
            window.scrollTo(0,0);
            this.$router.push(path);
            this.show = false;
        },

        QuitButton()
        {
            this.show_products = false;
        },

        AskProducts(command)
        {
            console.log(command);
            this.current_command_id = command.id;
            this.products_err = false;
            this.products_loaded = false;
            this.show_products = true;
            this.accountsHandler.currentUser.GetProductsFromCommand(command.id).then((ok) => {
                this.products_err = !ok;
                this.products_loaded = true;
            })
            
        },


        TimestampToDate(timestamp)
        {
            const date = new Date(timestamp);
            const day = date.getDate();
            const month = date.getMonth()+1;
            const year = date.getFullYear();
            return (day + "/" + month + "/" + year)
        },

        ModalQuit()
        {
            if(!this.accountsHandler.currentUser.logged)
            {
                this.$router.push("/Login");
            }
            if(this.changing_password)
            {
                this.changing_password = false;
                this.$refs.new_password_old.Clear();
                this.$refs.new_password.Clear();
                this.$refs.new_password_2.Clear();
                this.HideChangePassword();
            }
            if(this.changing_email)
            {
                this.changing_email = false;
                this.$refs.new_email_password.Clear();
                this.$refs.new_email.Clear();
                this.HideNewEmail();    
            }
            if(this.adding_adresse)
            {
            this.HideNewAdresse();
            this.adding_adresse = false;
            this.$refs.adresse_form.Clear();
            }
        },

        DeletePanier()
        {
            if(this.selected_panier == -1)return;
            this.accountsHandler.DeletePanier(this.selected_panier).then((result) => {
                 if(result == "panier-deleted")
                {
                    this.selected_panier = -1;
                    this.message = "Votre panier à bien été supprimé";
                    this.$refs.info.Reveal("Panier supprimé", "", 460, "var(--success-green)"); 
                }
            }).catch((error) => {
                 switch (error.code)
                {
                    case "auth/no-user" : this.message = "Vous n'êtes pas authentifié";break;
                    default : this.message = error;
                }
            
                this.$refs.info.Reveal("Erreur", "", 460);
                this.adding_adresse = false;
            });
        },


        /**CHANGEMENT INFOS */
        ShowChangePassword()
        {
            this.$refs.ChangePassword.Reveal("Reinitialisation du mot de passe");
        },
        HideChangePassword()
        {
            this.$refs.ChangePassword.Hide();
        },
        ShowNewEmail()
        {
            this.$refs.ChangeEmail.Reveal("Modifier l'adresse mail");

        },
        HideNewEmail()
        {
            this.$refs.ChangeEmail.Hide();
        },
        ShowNewAdresse()
        {
            this.$refs.new_add.Reveal("Ajouter une adresse");

        },
        HideNewAdresse()
        {
            this.$refs.new_add.Hide();
        },

        ValidateNewAddForm()
        {
            let validate = true;
            if(!this.$refs.addresse_name.ValidateInput())validate = false;
            if(!this.$refs.addresse_loc.ValidateInput())validate = false;
            if(!this.$refs.addresse_city_num.ValidateInput())validate = false;
            if(!this.$refs.addresse_city.ValidateInput())validate = false;

            return validate;
        },

        SubmitNewAdresse(adresse)
        {
            
            this.adding_adresse = true;
            this.accountsHandler.AddAdresse(
            adresse
            ).then((result) => {
                 if(result == "addresse-added")
                {
                    this.message = "Cette adresse est maintenant votre adresse par défaut de livraison";
                    this.$refs.info.Reveal("Adresse ajoutée", "", 460, "var(--success-green)"); 
                }
            }).catch((error) => {
                 switch (error.code)
                {
                    case "auth/no-user" : this.message = "Vous n'êtes pas authentifié";break;
                    default : this.message = error;
                }
            
                this.$refs.info.Reveal("Erreur", "", 460);
                this.adding_adresse = false;
            })
        },

        SubmitRemoveAdresse(id)
        {
            this.accountsHandler.RemoveAdresse(id).then((result) => {
                 if(result == "addresse-deleted")
                {
                    this.message = "Cette adresse à bien été supprimée";
                    this.$refs.info.Reveal("Succès", "", 460, "var(--success-green)"); 
                }
            }).catch((error) => {
                  switch (error.code)
                {
                    case "auth/no-user" : this.message = "Vous n'êtes pas authentifié";break;
                    default : this.message = "Une erreur inconnue a été occasionée, veuillez reessayer dans quelques instants"
                }
                console.log(error);
                this.$refs.info.Reveal("Erreur", "", 460);
               
            })
        },

        SubmitSelectAddresse(adresse_id)
        {
            this.accountsHandler.SelectAdresse(adresse_id).then((result) => {
                 if(result == "addresse-selected")
                {
                    this.message = "L'adresse a bien été selectionnée";
                    this.$refs.info.Reveal("Succès", "", 460, "var(--success-green)"); 
                }
            }).catch((error) => {
                  switch (error.code)
                {
                    case "auth/no-user" : this.message = "Vous n'êtes pas authentifié";break;
                    default : this.message = "Une erreur inconnue a été occasionée, veuillez reessayer dans quelques instants"
                }
                console.log(error);
                this.$refs.info.Reveal("Erreur", "", 460);
               
            });
        },



        /*CHANGE PASSWORD*/
        ValidateNewPasswordForm()
        {
            let validate = true;
            if(!this.$refs.new_password_old.ValidateInput())validate = false;
            if(!this.$refs.new_password.ValidateInput())validate = false;
            if(!this.$refs.new_password_2.ValidateInput(this.$refs.new_password.GetValue()))validate = false;

            return validate;
        },
        async SubmitNewPassword(e)
        {
            e.preventDefault();
            if(this.new_password != this.new_password_2)
            {
                this.$refs.new_password.textField.valid = false;
                this.$refs.new_password_2.textField.valid = false;
                this.newPasswordError = "Les nouveaux mots de passe ne correspondent pas";
                return;
            }
            this.changing_password = true;

            try {
                await this.accountsHandler.ChangeUserPassword(this.old_password, this.new_password);
                this.modal_content = "Le mot de passe a été modifié avec succès";
                this.modal_title = "Mot de passe modifié";
                this.modal_error = false;
                this.show_modal = true;
            } catch (error) {
                switch (error.code)
                {
                    case "auth/wrong-password" : this.modal_content = "L'ancien mot de passe est invalide.";break;
                    case "auth/no-user" : this.modal_content = "Vous n'êtes pas authentifié";break;
                    default : this.modal_content = "Une erreur inconnue a été occasionée, veuillez reessayer dans quelques instants"
                }
                this.modal_title = "Erreur";
                this.modal_error = true;
                this.changing_password = false;
                this.show_modal = true;
            }

        },

        /*CHANGE EMAIL */
        ValidateNewEmailForm()
        {
            let validate = true;
            if(!this.$refs.new_email_password.ValidateInput())validate = false;
            if(!this.$refs.new_email.ValidateInput())validate = false;
            return validate;

        },
        async SubmitNewEmail(e)
        {
            e.preventDefault();
            this.changing_email = true;
            
            try {
                await   this.accountsHandler.ChangeUserEmail(this.old_password, this.new_email);
                this.modal_content = "L'adresse mail a été modifié avec succès'";
                this.modal_title = "Email modifié";
                this.modal_error = false;
                this.show_modal = true;
            } catch (error) {
                switch (error.code)
                {
                    case "auth/wrong-password" : this.modal_content = "Le mot de passe est invalide.";break;
                    case "auth/no-user" : this.modal_content = "Vous n'êtes pas authentifié";break;
                    case "auth/email-already-in-use" : this.modal_content = "Cette adresse mail est déja utilisée";break;
                    default : this.modal_content = "Une erreur inconnue a été occasionée, veuillez reessayer dans quelques instants"
                }
                 this.modal_title = "Erreur";
                this.modal_error = true;
                this.changing_email = false;
                this.show_modal = true;
            }
        },

        ShowDialog(title, content, error = false)
        {
            this.modal_title = title;
            this.modal_content = content;
            this.modal_error = error;
            this.show_modal = true;
        },

        /*CHANGE NEWSLETTER */
        async HandleNewsletter()
        {
            this.changing_newsletter = true;
            try {
                await this.accountsHandler.HandleUserNewsletter(!this.accountsHandler.currentUser.newsletter);
                this.ShowDialog("Changement effectué", "Vous avez été désabonné(e)/abonné(e) avec succès !");
            } catch (error) {
                this.ShowDialog("Erreur", error, true);
            }
            this.changing_newsletter = false;
        },

        /*DECONNEXION */
         async SubmitSignOut()
        {   
            try {
                await this.accountsHandler.SignUserOut();
                this.ShowDialog("Déconnexion réussie", "Vous allez être redirigé vers la page de connexion");
            } catch (error) {
                this.ShowDialog("Erreur", "Une Erreur a été occasionnée, veuillez reessayer dans quelques instants");
            }
        },

    }
    ,
    mounted()
    {
      
    }
    
}
</script>

<style scoped>

.little {
    font-size: 14px;
}

@media (max-width:650px)
{
    .account-list-content {
        padding : 0!important;
    }
    .account-container {
        padding : 0!important;
    }
    .product-item {
        flex-direction: column!important;
        align-items: center!important;
    }
    .field {
        margin : 0.5rem 0!important;
        align-items: center!important;
    }
    .account-list-item {
        display: flex!important;
        flex-direction: column!important;
        height : auto!important;
    }
    .account-list-item > * {
        margin : 0.5rem 0;
    }

    .little-title {
        display: block!important;
    }

    .account-list-title > span {
        display: none;
    }
    .account-list-content {
        border-bottom : 1px solid black
    }

    .remove-button {
        top : 85%!important;
    }
}



.command-card {
    background-color: white;
    padding : 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius : 5px;
    width  : 100%;
    max-width : 95%;
}
.quit-button {
    position: absolute;
    top : 0.25rem;
    left : 0.25rem;
    font-weight: bold;
    display: flex;
    align-items : center;
    justify-content : center;
}

.field {
    display: flex;
    flex-direction: column;
    align-items : baseline;
    width: 150px;
}

img {background-color: gray;}

.product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding : 0.75rem 0;
    border-bottom : 1px solid lightgray;
}

.products-container{
    display: flex;
    width: 100%;
    padding: 0 1rem;
    flex-direction: column;
    align-items : center;
    max-height : 95vw;
    overflow-y: auto;
    overflow-x: hidden ;
}

.command-background {
    position: fixed;
    z-index : 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.6);
}

.adresse-content {
    display: grid;
    grid-template-columns : 35% 2% 63%;
    align-items: baseline;
}

.adresse-content > div {
    min-width : unset!important;
}

.form-container > span {
    font-size : 0.8rem;
    color : black;
}


.account-error {
    color: var(--amaranth)!important;
    text-align: left;
    font-size: 14px;
    align-self : flex-start;
    min-height : 21px;
    margin-top : 0.25rem;
}



.form-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: center;
    min-width: 250px;
    align-items: flex-start;
}

.btn-shine {
  color: #fff;
  background: linear-gradient(to right, #4d4d4d 0, #fff 10%, #4d4d4d 20%);
  background-position: 0;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  white-space: nowrap;
  pointer-events: none;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  
  100% {
   background-position: 160px; 
  }
}


.button-disabled {
    background-color : lightgray!important;
    color : gray!important;
    pointer-events : none;
}

.account-error {
    color: var(--amaranth)!important;
    text-align: left;
    font-size: 14px;
    align-self : flex-start;
    min-height : 21px;
    margin-top : 0.25rem;
}




@media(max-width:615px)
{
    .change-password-container {
        flex-direction: column!important;
        align-items: center!important;
        width: 100%!important;
    }

    .account-button {
        font-size: 15px!important;
    }

    .change-password-content {
        margin:0.5rem 0!important;
    }
}

.change-password-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 75%;
}

.change-password-content {
    display: flex;
    margin : 0 1rem;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
}


.form-container > span {
    font-size : 0.8rem;
    color : black;
}

.form-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: center;
    min-width: 250px;
    align-items: flex-start;
}

.account-link {
    color: #0d6efd;
}

.account-link:hover {
         color: #0056b3;

    cursor: pointer;
    text-decoration: underline;
}

@media(max-width:460px)
{
    .account-title {
        font-size : 2rem!important;
    }
}

@media(max-width:600px)
{
    .account-button-container {
    flex-direction: column-reverse;
    display: flex!important;
    align-items: center;
    height: 100px;
    justify-content: space-between!important;
    }
}

.account-button-container {
        align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;

}

@media (max-width : 860px)
{
    .account-info-container {
        height: auto!important;
    flex-direction: column!important;
    justify-content: center;
    align-items: center;
    }

    .account-info-content
    {
        border-bottom: 1px solid gray!important;
        padding-bottom: 1rem!important;
        margin: 0.75rem 0!important;
        padding-left: 0!important;
        padding-right : 0!important;
        align-items: center!important;
        border-right: none!important;
        width: 100%;
        min-height: 90px;
       
    }
}

.account-pay {
    grid-template-columns: 50% 25% 25%!important;
}

.account-commandes {
    grid-template-columns : 30% 20% 25% 25%!important;
}
.account-list-item > span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-button {
    outline: none;
    position : absolute;
    top : 50%;
    height: 40px;
    
    right :0;
    transform: translateY(-50%);
     width : 22px;
    background-color : #A3A3A3;
    height : 22px;
    font-size: 12px;
    font-weight : bold;
    border : none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-list-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 270px;
    overflow-y: auto;
    width: 100%;
    padding: 0 1rem;
}

.account-list-content::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.account-list-content::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.account-list-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.account-list-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.account-list-add {
    font-weight: 600;
    color : var(--amaranth);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width : 100%;
    min-height : 74px;
    padding : 1rem 0;
    font-size : 0.9rem;
    direction: ltr;
    border-bottom : 1px solid gray;
    transition-duration: 0.2s;
    background-color: rgba(248,56,113,0.23);
}

.account-list-add:hover {
    cursor: pointer;
    
}


.account-list-item {
    font-weight: 400;
    font-size : 0.9rem;
    height: 40px;
    display : grid;
    position: relative;
    grid-template-columns : 20% 80%;
    width : 100%;
    box-sizing: content-box;
    padding : 1.5rem 0;
    direction: ltr;
    border-bottom : 1px solid gray;
}

.account-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;
    width: 100%;
}

.account-list-title {
    font-weight: bold;
    font-size : 0.9rem;
    display: grid;
    width: 97%;
    margin-right: 1rem;
    margin-left: 1rem;
    grid-template-columns : 20% 80%;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid black;
}

.account-select {
        background-color: var(--amaranth)!important;
    padding: 1px 1.25rem;
    text-align: left;
    
    min-width: 222px;
}

.account-button {
    min-height: 48px;
    border: none;
    background-color: var(--pretty-green);
    color: white;
    border-radius: 4px;
    padding: 0 1rem;
    outline: none!important;
}

.account-info-content{
    display: flex;
    height : 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content : space-between;
    padding-right : 1.3rem;
    padding-left : 0.75rem;
    padding-top : 0.25rem;
    padding-bottom : 0.25rem;
    font-size : 0.8rem;
    border-right : 1px solid gray;
}

.account-item-content {
    width: 100%;
}

body {
    background-color: var(--white-break);
}

.account-info-container{
    display: inherit;
    align-items : center;
    height : 80px;
}

.account-info {
    background-color: #E7E7E7;
    width: 80px;
    height: 80px;
    display : flex;
    justify-content: center;
    align-items: center;
}

.account-info > img {
    width : 70%;
    height:70%;
    filter: invert(0.5);
}

.account-container {
    margin-top : 7rem;
    font-family: 'Montserrat', sans-serif;
    width : 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.account-item {
    display: flex;
    width : 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;

}

.account-content
{
    margin-top: 2rem;
    display: inherit;
    max-width : 1000px;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.account-subtitle {
    font-size : 1.1rem;
    margin-bottom: 1.5rem;
    font-weight : 500;
    font-family  :'Montserrat', sans-serif;
    
}


.account-title {
    font-size : 2.5rem;
    font-weight : bold;
    margin-bottom : 0.2rem;
    
    
}
.little-title {
    display: none;
    font-weight: bold;
}

</style>