<template>
    <ul class="pagination-container">
        <li @click="PageChanged(1)" class="start-li">&#x2039;&#x2039;</li>
        <li @click="PageChanged(current_page -1)">&#x2039;</li>
        <li v-for="page in Pages" @click="PageChanged(page)" :class="{'active' : page == current_page}" :key="page">{{page}}</li>
        <li @click="PageChanged(current_page +1)">&#8250;</li>
        <li @click="PageChanged(page_count)" class="end-li">&#8250;&#8250;</li>

    </ul>
</template>


<script>
export default {
    props: {
        current_page : Number,
        page_count : Number,
    },
    computed : {
        Pages()
        {
            const total = this.page_count;
            const current = this.current_page;
            const min = 1;
            let length = 3
             if (length > total) length = total;

            let start = current - Math.floor(length / 2);
            start = Math.max(start, min);
            start = Math.min(start, min + total - length);
            
            return Array.from({length: length}, (el, i) => start + i);
                    }
    
    },

    methods : {
        PageChanged(page)
        {
            if(page < 1 || page > this.page_count)return;
            this.$emit("pagechanged", page)
        }
    }
}
</script>

<style scoped>

.pagination-container {
    display: flex;
    list-style: none;
    border-radius : 0.25rem;
    margin : 0;
    padding : 0;
    background-color : var(--white-break)
}

.pagination-container > li {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
    cursor: pointer;
    background-color : var(--white-break);
    transition-duration: 0.2s;
}

.pagination-container > li:hover {
     background-color: #E1F6FF;
}

.pagination-container > .active {
    color: #fff;
    background-color: #007bff!important;
    border-color: #007bff;
}

.start-li {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.end-li {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
</style>