var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv"},[_c('div',{staticClass:"cgv-container"},[_c('span',{staticClass:"prec",on:{"click":_vm.PreviousGo}},[_vm._v("‹- Revenir a la page précedente")]),_c('h1',[_vm._v("Mentions légales")]),_c('p',[_vm._v(" Date de dernière mise à jour: 24/11/2021 ")]),_c('p',[_vm._v(" Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site https://neonbloom.fr/ l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi. ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("Neon Bloom™ ")]),_c('p',[_vm._v(" Marque déposée: Numéro National 4501890 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("Edition du site ")]),_c('p',[_vm._v(" Le présent site, accessible à l’URL https://neonbloom.fr (le « Site »), est édité par Antoine Poirrier ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("Hebergement ")]),_c('p',[_vm._v(" Le Site est hébergé par la société 1&1 IONOS SARL 7, place de la Gare BP 70109 57201 Sarreguemines Cedex Numéro de téléphone : 0970 808 911 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("Directeur de publication")]),_c('p',[_vm._v(" Le Directeur de la publication du Site est Pierrick Noir. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("Nous contacter ")]),_c('p',[_vm._v(" L’Exploitant peut être joint à l’adresse mail suivante neonbloom.paris@gmail.com ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cgv-article"},[_c('h5',[_vm._v("Données personnelles ")]),_c('p',[_vm._v(" Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD »). ")])])
}]

export { render, staticRenderFns }