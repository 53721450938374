<template>
    <div class="d-flex align-items-center">
        <button @click="handleCheck"  ref="switch" class="mdc-switch mdc-switch--unselected" :class="{'mdc-switch--selected' : checked,'mdc-switch--unselected' : !checked }" type="button" role="switch" :aria-checked="checked">
  <div class="mdc-switch__track"></div>
  <div class="mdc-switch__handle-track">
    <div class="mdc-switch__handle">
      <div class="mdc-switch__shadow">
        <div class="mdc-elevation-overlay"></div>
      </div>
      <div class="mdc-switch__ripple"></div>
      <div class="mdc-switch__icons">
        <svg class="mdc-switch__icon mdc-switch__icon--on" viewBox="0 0 24 24">
          <path d="M19.69,5.23L8.96,15.96l-4.23-4.23L2.96,13.5l6,6L21.46,7L19.69,5.23z" />
        </svg>
        <svg class="mdc-switch__icon mdc-switch__icon--off" viewBox="0 0 24 24">
          <path d="M20 13H4v-2h16v2z" />
        </svg>
      </div>
    </div>
  </div>
</button>
<label style="font-size:14px" class="ml-2 mb-0" for="basic-switch">{{label}}</label>
    </div>
</template>

<script>
import {MDCSwitch} from '@material/switch';

export default {
    
    props: {
        value : {type : Boolean, default : false},
        label : String
    },

    data() {
        return {
            checked : this.value,
            switch : null,
        };
    },

    methods: {
        handleCheck()
        {
          this.checked = !this.checked;
          this.$emit('input', this.checked)
        }
        
        },
    mounted() {
        this.switch = new MDCSwitch(this.$refs.switch);
    },
    
}
</script>

<style scoped lang="sass">
@use '@material/switch/styles'

        
        
    
    
  

</style>